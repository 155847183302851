
.floating-label-textarea {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
}

textarea:focus {
  outline: none;
  border-color: #007bff;
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 12px;
  color: #999;
  font-size: 14px;
  pointer-events: none;
  /* transition: 0.2s ease all; */
  transition: top 0.3s, font-size 0.3s;
}

textarea:focus + .floating-label,
.floating-label.floating {
  top: -10px;
  left: 10px;
  font-size: 14px;
  color: #007bff;
  background: #fff;
  padding: 0 5px;
}
