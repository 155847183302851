.button-container {
  grid-column: span 3;
  display: flex;
  justify-content: center;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  /* color: white; */
  background-color: lavender;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #975cb3;
}
