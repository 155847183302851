/* *{
    margin: 0px;
    padding: 0px;
} */

html {
  height: 100%;
  background-color: white;
}
/* .main-div-shop{
    width: 100%;
    height: 100vh;
    min-height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;    
    padding: 40px;
} */

.h1-main-div {
  width: 100%;
  height: auto;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.label1 {
  width: auto;
  height: auto;
  font-size: 1.2rem;
  position: relative;
  top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  left: 20px;
  /* background-color: aqua; */
  color: rgb(204, 204, 204);
  transition: all 200ms;
  pointer-events: none;
}

.label-before {
  /* position: absolute; */
  top: -20px;
  background-color: white;
  left: 20px;
  font-size: 1rem;
}

.input-shop {
  height: 40px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 1rem;
  /* border: 1px solid black; */
  /* z-index: 20; */
}

.section-extra {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section-shopbanner {
  width: 100%;
  height: 60px;
  padding: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.change-border {
  border: 1px solid blue;
}

button:hover {
  /* background-color: #975cb3; */
}

table {
  width: 100%;
}

.button-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .table-div{
    margin:0px 12px 12px 12px;
} */
