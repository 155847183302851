body {
    background: #fff;
    font-family: 'Calibri', sans-serif !important;
    color: #4e4e4e;
    line-height: 22px;
    overflow-y: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}


@media print {
    body {
        visibility: hidden;
    }

    #pdfContent {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
    }

    .CentreSide {
        visibility: hidden;
    }
}

@media pdf {
    body {
        visibility: hidden;
    }

    #pdfContent {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
    }

    .CentreSide {
        visibility: hidden;
    }
}


.inconsolata {
    font-family: 'Inconsolata', monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-variation-settings: "wdth" 100;
}


.flex {
    display: flex;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.panel-heading {
    justify-content: space-between;
    min-height: 52px;
    align-items: center;
    display: flex;
    background-color: rgb(33, 46, 79);
    position: sticky;
    top: 0px;
    z-index: 5;
    padding: 3px 0px;
    /* box-shadow: 0px 0px 15px #b7b7b7; */
}

.panel-head-title {
    width: 30%;
    display: flex;
    align-items: center;
}

.panel-head-button {
    width: 70%;
    padding-right: 5px;
}

.listDiv {
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 10px;
}

.nameTitle {
    font-size: 13px !important;
    color: #6a6161;
    height: 28px;
    margin: 1%;
    padding: 0px 10px;
}

.inDays {
    font-size: 15px !important;
}

.inTime {
    font-size: 12px !important;
}

.tablePlusIcon {
    font-size: 12px !important;
    margin-top: -2px;
    position: absolute;
    margin-left: 7px;
    display: none !important;
    color: darkblue;
}

.onSerialNoHover:hover i {
    display: block !important;
}

.ItemGroup-modal {
    width: 120%;
    height: 110%;
    overflow-y: scroll;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.ItemGroup-modal::-webkit-scrollbar {
    display: none;
}

.ItemGroup-modal {
    scrollbar-width: none;
}

.w-130 {
    width: 130%;
}

.w-100 {
    width: 100%;
}

.w-100-48 {
    width: 100%;
}

.w-20 {
    width: 20%;
}

.w-28 {
    width: 28%;
}


.w-35 {
    width: 35%;
}

.w-30 {
    width: 30%;
}


.addItemTable {
    max-height: 513px;
    height: auto;
    overflow-y: scroll;
}

.m-r-2 {
    margin-right: 2px;
}

.panel-title {
    font-size: 20px;
    align-items: center;
    padding-left: 20px;
    color: white;
    font-weight: 600;
    margin-bottom: 0;
}

.panel-bulk-upload {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-around;
    background-color: rgb(101, 58, 121);
    /* border-radius: 5px; */
    align-items: center;
    position: relative;
    margin: auto;
    /* border-bottom-left-radius: 5px; */
    /* border-bottom-right-radius: 5px; */
}

.bulk-upload-m1 {
    display: flex;
    align-items: baseline;
    justify-items: flex-end;
}

.button-bulk-1 {
    padding-right: 35px;
}

.stock-label {
    display: flex;
    justify-content: space-between;
    width: 35.5%;
}

.bulk-upload-p {
    color: white;
    display: flex;
    margin-bottom: 0px
}

.item_upload {
    border: none;
    color: #080808;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 15px;
    padding: 3px 5px 4px;
    width: 116px;
    height: 35px;
}

.close-btn {
    position: absolute;
    color: white;
    font-size: 30px;
    float: right;
    font-weight: bold;
    cursor: pointer;
    right: 9px;
    margin: 0px;
    top: 12px;
}

.group {
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* margin-bottom: 10px; */
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
}

.col-btn-1 {
    display: flex;
    padding-left: 5px;
}

.rotated-text {
    transform: rotate(-90deg);
    transform-origin: right bottom;
    white-space: nowrap;
    width: fit-content;
    position: relative;
    right: 22px;
    bottom: -3px;
}

.couponLeftSide {
    background-color: darkorange;
    border-radius: 0px;
    border: 1px solid darkorange;
    width: 50px;
}

.image-input-container {
    position: relative;
    display: inline-block;
}

.input-image {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 30px;
    height: auto;
}

.text-input {
    padding-left: 2px;
    font-size: 14px;
}

.apply-button {
    padding: 0px 5px;
    background-color: #397abf;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    width: 25%;
    margin-right: -8px;
    /* margin-top: -3px; */
}

.couponBox {
    background-color: white;
    border-radius: 5px;
    border: 1px solid grey;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.couponName {
    color: darkblue;
    font-weight: 600;
}

/* input {
font-size: 13px;
height: 35px;
border: 1px solid #757575;
border-radius: 7px;
font-family: inherit;
} */
.input_table {
    font-size: 8px;
    height: 30px;
    border: 1px solid #757575;
    border-radius: 7px;
    font-family: inherit;
}

.input_table1 {
    font-size: 13px;
}

/* .wrapper{
display: none;
} */
.inputMaterial {
    width: 20%;
    /* padding-left: 20px; */
    margin-left: 10px;
}

.table_th {
    font-size: 10px;
}

.group-1 {
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* margin-bottom: 10px; */
    width: 90%;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
}

.table-tr {
    font-size: 12px;
}

.table-tb {
    font-size: 14px;
}

.table {
    vertical-align: baseline;
    text-align: inherit;
}

.col-sm-1 {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    /* min-height: 1px; */
    /* padding-right: 5px; */
    padding-left: 8px;
    width: 355px;
}

.col-sm-2 {
    padding-right: 12px;
    text-align: center;
    margin-bottom: 13px;
    display: flex;
    justify-content: space-between;
    width: 11%;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
}

.col-sm-3 {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    /* min-height: 1px; */
    /* padding-right: 5px; */
    padding-left: 8px;
    width: 243px;
    position: relative;
}

.btn:first-child:active {
    color: white;
}

.inputMaterial2 {
    width: 150px;
    /* padding-left: 20px; */
    margin-left: 10px;
    font-size: 14px;
}

.inputMaterial3 {
    width: 96px;
    /* padding-left: 20px; */
    margin-left: 21px;
    font-size: 13px;
}

.inputMaterial4 {
    width: 96px;
    /* padding-left: 20px; */
    margin-left: 21px;
    font-size: 13px;
}

.col-sm-3 {
    padding-right: 12px;
    /* text-align: center; */
    margin-bottom: 13px;
    display: flex;
    justify-content: space-between;
    width: 12%;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
}

.btn {
    display: inline-block;
    padding: 4px 8px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-image: none;
    border: 1px solid transparent;
    user-select: none;
    margin-right: 10px !important;
    color: white;
}

.save_btn {
    border: none;
    color: #f0eaea;
    background-color: #4141ff;
    border-radius: 5px;
    font-size: 15px;
    padding: 5px;
    width: 110px;
    height: 35px;
}

.save_btn:hover {
    background-color: #6c6cee;
    transition: all 0.2s ease-out;
}

.print_im:hover {
    color: white;
    box-shadow: 0 3px 3px 8px rgba(28, 175, 74, 0.178);
    /* box-shadow: 3px 3px 10px rgba(114, 184, 114, 0.473); */
    transition: all 0.2s ease-out;
}

.reset_btn {
    border: 1px solid gray;
    color: black;
    border-radius: 5px;
    font-size: 15px;
    width: 110px;
    height: 35px;
    font-weight: 600;
}

.reset_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.im-top-1 {
    padding-top: 5px;
}

.col-ag1 {
    display: flex;
    padding-left: 15px;
}

.content_box {
    /* padding-bottom: 50px; */
    width: 100%;
    height: 100%;
    /* border: 1px solid #ccc; */
    margin: auto;
    margin-top: 0px;
    border-radius: 8px;
    /* box-shadow: 0 0 5px 3px rgb(175 170 170 / 38%); */
}

.m-box1 {
    height: 30px !important;
}

.m-logo {
    display: flex;
}

.modify-input-box-part-1 {
    width: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.thead-r1 {
    BACKGROUND-COLOR: #9bc0df !IMPORTANT;
}

.modify-input-box-part-2 {
    width: 35%;
    display: flex;
    height: 41px;
    /* padding-left: 20px; */
    margin-top: 2px;
}

.modify-input-box-part-3 {
    width: 30%;
}

.col-1 {
    height: 67% !important;
    width: 68% !important;
    padding-left: 0px !important;
    border: none;
}

.company_info-text {
    font-size: 15px;
    color: black;
    font-weight: 600;
    color: #4e4e4e;
    padding: 15px;
    padding-bottom: 5px;
}

.company_info-text {
    font-size: 15px;
    color: black;
    font-weight: 600;
    color: #494848;
    padding: 5px;
    padding-bottom: 10px;
}

.radio-btm-m3 {
    width: 38%;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}

.company-info-top {
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #ccc; */
    /* padding-bottom: 10px; */

    margin-top: 7px;
    flex-direction: column;
}

.company-info-top1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    flex-direction: row;
}

.company-info-top-left1 {
    display: initial;
    width: 50%;
}

.company-info-top-right {
    display: initial;
    width: 50%;
}

.panel-time {
    display: none;
}

.buttom-box {
    display: none;
}

.buttom-box button {
    margin: 0;
    border-radius: 0;
}

.input-box {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 20px;
    height: 40px;
    position: relative;
}

.company-info-p {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
    font-size: 15px;
    font-weight: 600;
}

.input-box1 {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 34px;
    margin-bottom: 20px;
    height: 25px;
    flex-direction: row-reverse;
}

.input-boxupi {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 34px;
    margin-bottom: 20px;
    height: 15px;
    flex-direction: row-reverse;
}

.modify_p_text {
    font-size: 14px;
    color: black;
    color: #4e4e4e;
    margin-bottom: 0px;
}

/* .modify_product_input {
border: 1.5px solid #ccc;
border-radius: 5px;
color: #555;
box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
font-size: 20px;
height: 36px;
width: 80%;
outline: none;
word-wrap: normal;
text-transform: none;
} */
.modify_product_input {
    border: 1.5px solid #ccc;
    border-radius: 5px;
    color: #555;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    font-size: 14px;
    /* font-weight: 600; */
    height: 33px;
    width: 55%;
    outline: none;
    word-wrap: normal;
    text-transform: none;
    padding-left: 8px;
}

/* .modify_product_input:focus {
border-color: #007bff;
box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */

.input-container {
    display: none;
    position: relative;
    width: 100%;
}

.input {
    font-size: 14px;
    padding: 0.6em 1em;
    border: none;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(190, 190, 190, 0.925);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    color: #333;
    height: 50px;
}

.m1 {
    border-radius: 5px;
    color: #555;
    font-size: 13px;
    height: 26px;
    display: flex;
    width: 55%;
}

.m3 {
    justify-content: space-between;
    display: flex;
    border-radius: 5px;
    color: #555;
    font-size: 13px;
    height: 13px;
    width: 100%;
}

.m2 {
    width: 30%;
    height: 25px;
}

.input:hover {
    background-color: #f2f2f2;
}

.input:focus {
    outline: none;
    background-color: #ffffff;
    box-shadow: 0 1px 5px rgba(89, 132, 143, 0.76);
}

.input::placeholder {
    color: #999;
}

.highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #6c63ff;
    transition: width 0.3s ease;
}

.m6 {
    display: block;
}

.m7 {
    display: none !important;
}

.input:focus+.highlight {
    width: 100%;
}

.upi-pay-side {
    display: flex;
    justify-content: space-between;
    height: 28px;
    flex-direction: row-reverse;
}

.upi-pay-text {
    font-size: 13px;
    height: 25px;
    border-radius: 7px;
    font-family: inherit;
    border: 1px solid #ccc;
}

.size_box {
    display: block;
    padding-top: 5px;
    height: 137px;
}

.user-label07 {
    position: absolute;
    left: 3%;
    color: #030303;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.user-label08 {
    position: absolute;
    left: 4%;
    margin-top: 0%;
    color: #ada6a6;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 14px;
}

.user-label09 {
    position: absolute;
    left: 4%;
    margin-top: 0%;
    color: #ada6a6;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 14px;
}

.textarea:focus~label,
textarea:valid~label {
    /* transform: translateY(-60%) scale(0.9); */
    background-color: #ffffff;
    padding: 0 .2em;
    color: #7e7c7c;
}

.upi-pay-text:focus-visible {
    border-color: #007bff !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    outline: none;
}

.display_block {
    display: block !important;
}

#display_down {
    padding-left: 28%;
    height: 167px;
    /* background-color: #fff0dd; */
    width: 335px;
    display: none;
}

#upidrop {
    border-radius: 2px;
    color: white;
    background-color: #ff0000;
    padding: 3px 10px;
    border-radius: 8px;
}

.payment-row {
    width: 335px;
    display: flex;
    justify-content: space-between;
}

.upi-payment-main {
    width: 100%;
}

/* Optional: Animation on focus */
@keyframes input-focus {
    from {
        transform: scale(1);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    }

    to {
        transform: scale(1.02);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.double_input {
    width: 58%;
}

.modify_product_checkbox {
    width: 15px;
    display: flex;
    align-items: center;
}

.btn-sucess {
    width: 55px;
    background-color: #5cb85c;
    display: flex;
    margin: auto;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    border: none;
    background-color: #1a5d1a !important;
    color: white;
    border-radius: 5px;
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

.btn-info {
    width: 55px;
    margin: auto;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    border: none;
    background-color: #068fff !important;
    color: white;
    border-radius: 5px;
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

#btnSearch {
    display: flex;
    /* margin-left: 700px; */
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

#btnSearch:hover {
    color: white;
    box-shadow: 0 0 5px 5px #2c63494a;
    transition: all 0.2s ease-out;
}

.btn-info:hover {
    color: white;
    box-shadow: 0 0 5px 5px #2c63494a;
    transition: all 0.2s ease-out;
}

.btn-primary {
    padding: 4px 8px;
    background-color: #fe0000;
    color: #fff;
    border: none;
    width: 55px;
    margin: auto;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    border-radius: 5px;
    text-decoration: none;
    transition: all;
    transition-duration: 600ms;
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

.btn-primary:hover {
    color: white;
    box-shadow: 0 0 5px 5px rgba(153, 13, 20, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

#btnadd {
    display: flex;
    /* margin-left: 765px; */
}

.col-sm-12 {
    width: 100%;
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 8px;
    font-size: 13px;
}

.table-bordered1 {
    border: 1px solid #ddd;
}

.i-1 {
    padding-top: 5px;
    color: red;
}

/* table {
width: 100%;
background-color: transparent;
border-collapse: collapse;
border-spacing: 0;
}
*/

.table {
    width: 96%;
    margin-bottom: 2px;
}

/* thead {
background-color: #e5f2ff;
} */

/* th {
color: #646464;
} */

.edit_btn {
    margin: auto;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
}

.delete_btn {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.hover-text1 {
    position: absolute;
    z-index: 1;
    background-color: white;
    color: black;
    padding: 10px;
    font-weight: 600;
    width: max-content;
    right: 0%;
    top: -49px;
    border: 2px solid black;
    border-radius: 5px;
}

.hover-text-black {
    position: absolute;
    z-index: 1;
    background-color: #000000;
    color: white;
    padding: 5px;
    font-weight: 600;
    left: 2%;
    top: 5%;
    /* border: 2px solid black; */
    /* border-radius: 5px;  */
}

.print_img {
    position: absolute;
    right: 140px;
    top: 80px;
}

.print_img:hover {
    color: white;
    box-shadow: 0 0 5px 2px rgba(48, 37, 37, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.print_img1 {
    position: absolute;
    right: 100px;
    top: 80px;
}

.print_img1:hover {
    color: white;
    box-shadow: 0 0 5px 2px rgba(48, 37, 37, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.col_1 {
    display: flex;
    width: 100%;
    padding-top: 5px;
    justify-content: flex-end;
}

.navbar-right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    min-height: 50px;
}

.col_3 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 5px;
}

.button-m1 {
    display: inline-grid;
    padding-right: 10px;
    justify-content: center;
}

.button-m2 {
    padding-left: 5px;
    padding-right: 5px;
}

.add_button {
    width: 10%;
}

.add_button:focus {
    border-color: #007bff;
    border: 2.9px solid #007bff;
}

.modify_product_input1 {
    border: 1.5px solid #ccc;
    border-radius: 5px;
    color: #555;
    box-shadow: 0 !important;
    font-size: 14px;
    height: 40px;
    width: 100%;
    outline: none;
    word-wrap: normal;
    text-transform: none;
}

.top1 {
    width: 98%;
}

.company-info-top1 {
    width: 98%;
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    flex-direction: row;
}

.company-info-top-left2 {
    justify-items: initial;
    width: 90%;
    background: #ffffff;
    padding-bottom: 40px;
    /* box-shadow: 2px 2px 15px rgb(212, 212, 212); */
}

.user-label22 {
    left: 8%;
    margin-top: -5%;
}

.company-info-top-right {
    width: 50%;
    padding: 22px;
}

.col_2 {
    display: flex;
    justify-content: center;
    padding-right: 30%;
}

.member_btn {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 125px;
    font-weight: 600;
    height: 35px;
    border: 1px solid gray;
}

.member_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.optional_btn {
    border: 1px solid gray;
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 125px;
    font-weight: 600;
    height: 35px;
}

.optional_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.lens_btn {
    border: 1px solid gray;
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 85px;
    font-weight: 600;
    height: 35px;
    background-color: #4194fa;
}

.lens_btn:hover {
    background-color: #157efb;
    transition: all 0.2s ease-out;
}

.order_list_btn {
    border: 1px solid gray;
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 0px;
    width: 115px;
    font-weight: 600;
    height: 25px;
}

.order_list_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.challan_list_btn {
    border: 1px solid gray;
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 0px;
    width: 115px;
    font-weight: 600;
    height: 25px;
}

.challan_list_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.setting_btn {
    border: 1px solid gray;
    color: #0c0c0c;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 100px;
    font-weight: 600;
    height: 35px;
}

.setting_btn:hover {
    background-color: #ffffff;
    transition: all 0.2s ease-out;
    box-shadow: 3px 4px 7px rgb(197, 197, 197);
}

.modify_p_text1 {
    position: absolute;
    left: 3%;
    color: #686868;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -5px;
}

.modify_product_input1:focus~label {
    transform: translateY(-50%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 .2em !important;
    color: #2196f3 !important;
}

.modify_product_input1:valid~label {
    transform: translateY(-50%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 0.2em !important;
    /* color: #2196f3 !important; */
}


/* .modify_p_text2 {
position: absolute;
left: 3%;
color: #686868;
pointer-events: none;
transform: translateY(1rem);
transition: 150ms cubic-bezier(0.4,0,0.2,1);
left: 31px;
top: -1px;
} */
.modify_p_text3 {
    position: absolute;
    left: 3%;
    color: #000000;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -1px;
}

.modify_p_text4 {
    position: absolute;
    left: 3%;
    color: #000000;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -1px;
}

.modify_p_text5 {
    position: absolute;
    left: 3%;
    color: #000000;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -1px;
}

.sub-input-box {
    position: relative;
}

.button-m3 {
    padding-left: 5px;
    justify-items: flex-end;
    display: inline-grid;
}

.button-m4 {
    display: flex;
    padding-left: 5px;
}

.button-m5 {
    /* display: flex; */
    padding-left: 15px;
    /* padding-right: 15px; */
    text-decoration: none;
}

.input-box-am-1 {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 20px;
    height: 40px;
    position: relative;
}

.company-info-top-right2 {
    width: 95%;
    padding-bottom: 85px;
}

.label-box-fix {
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -16px;
    background-color: white;
}

.label-box-fix-1 {
    font-size: 14px;
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 15px;
    top: -26px !important;
    background-color: white;
}

.user-label-am3 {
    position: absolute;
    color: #686868;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 33px;
    top: -9px;
    font-size: 14px;
}

.user-label-am2 {
    position: absolute;
    left: 3%;
    color: #686868;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -5px;
}

.modify_product_input1:focus~label {
    transform: translateY(-50%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 .2em !important;
    color: #2196f3 !important;
}

.inputMaterial2:focus~label {
    transform: translateY(-50%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 .2em !important;
    color: #2196f3 !important;
}

.inputMaterial4:focus~label {
    transform: translateY(-50%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 .2em !important;
    color: #2196f3 !important;
}

.inputMaterial2:valid~label {
    transform: translateY(-35%) scale(0.9) !important;
    background-color: #ffffff00 !important;
    padding: 0 0.2em !important;
    color: #393a3b !important;
}

.inputMaterial3:valid~label {
    transform: translateY(-35%) scale(0.9) !important;
    background-color: #ffffff00 !important;
    padding: 0 0.2em !important;
    color: #393a3b !important;
}

.inputMaterial4:valid~label {
    transform: translateY(-35%) scale(0.9) !important;
    background-color: #ffffff00 !important;
    padding: 0 0.2em !important;
    color: #393a3b !important;
}


.item-info-top {
    padding-top: 12px;
    width: 98%;
    display: flex;
    justify-content: center;
    /* border-bottom: 1px solid #ccc; */
    /* padding-bottom: 10px; */
    margin-top: 7px;
    flex-direction: inherit;
}

input[type="checkbox"] {
    box-shadow: none !important;
}

/* input:valid {
    outline: none !important;
    border: 1.5px solid #e7dede;
    box-shadow: 1px 1px 1px #aba69c;
 
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

/* .input_text:focus ~ label, input:valid ~ label {
transform: translateY(-50%) scale(0.8) !important;
background-color: #f5f5f5 !important;
padding: 0 0.2em;
color: #696969 !important;
font-size: 15px;
border-radius: 2px;
} */
.table-t {
    width: 100%;
    margin: auto;
    position: relative;
    display: block;
}

.table-th {
    text-align: center;
    color: #646464;
    font-size: 13px;
    /* min-width: 70px; */
    padding-right: 10px;
}

.table-thIG1 {
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    min-width: 45px;

}

.table-thIM1 {
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    min-width: 206px;
}

.table-th-3 {
    width: 1% !important;
}

.table-sn {
    width: 1%;
}

.table-btn {
    width: 2%;
}

.table-name {
    width: 20%;
}

.table-mrp {
    width: 4%;
}

.col-s5 {
    padding-top: 5px;
}

.col-s6 {
    padding-top: 5px;
}

.input-box-head-1 {
    /* padding: 5px; */
    /* padding-bottom: 20px; */
    padding: 4px 10px 0px 0px;
}

.size-a1 {
    width: 175px;
}

.modal-colum-head {
    display: flex;
    justify-content: space-between;
}

.gridview2 {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;
    flex-wrap: wrap;
}

.tableGridView {
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;
    flex-wrap: wrap;
}

.input-box-SI-1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
}

.table-tr1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-tr1 td {
    margin: 1px 0px;
}

.table-input-12 {
    width: 99%;
    margin: auto;
    height: 28px;
    border-radius: 1px !important;
}

.table-input-13 {
    box-shadow: none !important;
    width: 100%;
    margin: auto;
    height: 26px;
    border-radius: 3px !important;
    border: 1px solid lightgray;
    padding: 5px;
    font-size: 13px;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.table-overflow {
    box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, 0.1) !important;
    position: relative !important;
    /* display: block; */
    overflow: auto;
    overflow-y: hidden;
    width: 98% !important;
    height: 100px;
}

.tableOverflowItemGroup {
    /* box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, 0.1) !important; */
    position: relative !important;
    /* display: block; */
    overflow: auto;
    overflow-y: hidden;
    width: 98% !important;
    max-height: 250px;
    border: 1px solid #e9e9e9;
}

.tableOverflowItemMaster {
    /* box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, 0.1) !important; */
    position: relative !important;
    /* display: block; */
    overflow: auto;
    overflow-y: hidden;
    width: 98% !important;
    max-height: 227px;
    border: 1px solid #e9e9e9;
}

.himanhsu:hover {
    scale: 2.2;
    transform: translateY(5px);
}

.table-overflow1 {
    height: max-content;
    overflow-y: scroll;
    margin-top: 0px;
    max-height: 30vh;
}

.label-red {
    color: darkred;
    font-weight: 600;
    font-size: 14px;
}

.table-container {
    max-height: 150px;
    overflow-y: auto;
}

/* .table-container-s1 {
    max-height: 130px;
    overflow: scroll;
    max-height: 210px;
} */

.table-container-s1 {
    scrollbar-width: none;
}

.table-container-BatchWise {
    max-height: 260px;
}

.table-container-s2 {
    max-height: 130px;
    overflow-y: auto;
}

/* .body_hidden{
    overflow: hidden;
} */

.delete-btn {
    color: rgb(128, 124, 124);
}

.delete-btn:hover {
    color: red;
}

.btn-table1 {
    border: 0px;
    text-align: center;
}

.btn-table2 {
    border: 0px;
    margin-left: 00px;
    background-color: white;
    padding: 3px 25px 0px 7px;
}

.input-box2 {
    display: flex;
    justify-content: flex-start;
    padding-left: 0px;
    padding-right: 2px;
    margin-bottom: 20px;
    height: 40px;
    position: relative;
}

.input-box3 {
    display: flex;
    justify-content: flex-start;
    /* padding-left: 15px; */
    padding-right: 2px;
    /* margin-bottom: 20px; */
    height: 25px;
    position: relative;
}

.modify_p_text_checkbox {
    padding-left: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    width: 140px;
}

.txt_black {
    color: rgb(77, 77, 77) !important;
}

.LeftSetting {
    left: 160px !important;
}

.TableAllocation_checkbox {
    padding-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #686868;
}

.input-height3 {
    height: 40px;
}

.img-ig-1 {
    margin-top: 20px;
    margin-right: 67px;
    width: 114px;
    height: 116px;
}

.modify-iml-input {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 35px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
}

.modify-iml-input:focus {
    border-color: #007bff !important;
    border: 2.9px solid #007bff;
    box-shadow: 0 !important;
}

.modify_p_text2 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 26px;
    top: -8px;
    font-size: 14px;
}

.input-box-iml {
    display: flex;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 2px;
    margin-bottom: 15px;
    height: 40px;
    width: 30%;
    position: relative;
}

.col-iml-1 {
    width: 64%;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    min-height: 1px;
    padding-right: 1%;
    justify-content: flex-end;
}

#btnSearch01 {
    display: flex;
    /* margin-left: 700px; */
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

#btnReset01:hover {
    color: #000000 !important;
}

#btnSearch01:hover {
    color: black;
}

.btn-sucess-iml {
    background-color: #5cb85c;
    display: flex;
    /* width: 80%; */
    margin: auto;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    border: none;
    background-color: #5cb85c !important;
    color: white;
    border-radius: 4px;
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

.btn-info-iml {
    /* width: 11%; */
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    border: none;
    background-color: #09a9cd !important;
    color: white;
    border-radius: 4px;
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

.col-iml-2 {
    display: flex;
    justify-content: space-evenly;
    padding-right: 9%;
}

.report_button01 {
    height: 30px;
    background-color: black;
    margin-top: 3px;
    margin-right: 2%;
}

.modify_p_text2 {
    top: -9px;
}

.print_img-1 {
    position: absolute;
    right: 15%;
    top: 13px;
}

.print_img-1:hover {
    color: white;
    box-shadow: 0 0 5px 2px rgba(48, 37, 37, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.print_img1-1 {
    position: absolute;
    right: 12%;
    top: 14px;
}

.ss2 {
    width: 49% !important;
}

.print_img1-1:hover {
    color: white;
    box-shadow: 0 0 5px 2px rgba(48, 37, 37, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.btn-primary-iml {
    padding: 4px 8px;
    background-color: #087e79;
    color: #fff;
    border: none;
    margin-top: 0px;
    width: 42%;
    /* margin: auto; */
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    border-radius: 4px;
    text-decoration: none;
    transition: all;
    /* margin-right: 60%; */
    transition-duration: 600ms;
    box-shadow: 2px 3px 4px rgb(114 102 102);
}

.btn-primary-iml:hover {
    color: white;
    box-shadow: 0 0 5px 5px rgba(153, 13, 20, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

#btnadd01 {
    display: flex;
    margin-top: 2px;
    width: 95px;
}

.group-im-1 {
    position: relative;
    margin-bottom: 0px;
    width: 100%;
    /* padding-left: 20px; */
    display: flex;
    padding-top: 10px;
}

.edit_btn1 {
    width: 50px;
    margin: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    border: none;
    background-color: #fb8306ba !important;
    color: white;
    border-radius: 4px;
}

.table_edit {
    width: 0%;
}

.table_edit2 {
    width: 11%;
}

.table_edit3 {
    width: 7%;
}

.table_delete {
    width: 1%;
}

.table_revoke {
    width: 7%;
}

.modify1 {
    width: 65%;
}

.mod2 {
    top: -13px;
}

.not_allow_btn {
    background-color: red;
    color: white;
}

.allow_btn {
    background-color: darkgreen;
    color: white;
}

.delete_btn1 {
    width: 50px;
    margin: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    border: none;
    background-color: #dc3545 !important;
    color: white;
    border-radius: 4px;
}

.ecom_btn1 {
    width: 80%;
    margin: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    border: none;
    background-color: #edebeaba !important;
    color: white;
    border-radius: 4px;
}

.print_img1-2 {
    position: absolute;
    padding-left: 3px;
    margin-left: 230px;
    margin-top: 4px;
}

.dropdown-menu {
    position: absolute;
    transform: translate3d(1195px, 43px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}

.tbody-im {
    vertical-align: baseline;
    text-align: center;
}

.input-box-Image {
    display: grid;
    justify-content: center;
    padding-right: 20%;
    margin-bottom: 10px;
    height: 40px;
    width: 100%;
    position: absolute;
}

.modify_pic_text {
    font-size: 14px;
    color: black;
    color: #4e4e4e;
    padding-left: 10px;
}

.picture__input {
    display: none;
}

#picture__inputOne {
    display: none;
}

#picture__inputtwo {
    display: none;
}

#picture__input3 {
    display: none;
}

#picture__input4 {
    display: none;
}

.picture {
    width: 55px;
    height: 60px;
    aspect-ratio: 16/9;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    border: 2px dashed currentcolor;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
}

.picture:hover {
    color: #777;
    background: #efd9d93d;
}

.picture:active {
    border-color: turquoise;
    color: turquoise;
    background: #eee;
}

.picture:focus {
    color: #777;
    background: #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modify_im_input {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 35px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
    background-color: none !important;
    background: none;
}

.modify_im_input:focus {
    border-color: #007bff !important;
    border: 1.9px solid #007bff;
    box-shadow: none !important;
}


.input-box-Im {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 2px;
    margin-bottom: 13px;
    /* height: 40px; */
    width: 100%;
    position: relative;
}

.modify_lbl_text6 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 54%;
    top: -8px;
    font-size: 14px;
}

.add_button01 {
    width: 10%;
    margin-left: 1%;
    background-color: white;
}

.add_button01:focus {
    border-color: #007bff;
    border: 2.9px solid #007bff;
}

.input-box-Im-add {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 15px;
    height: 40px;
    width: 100%;
    position: relative;
}

.modify_lbl_text3 {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 3%;
    top: -9px;
    font-size: 14px;
}

.modify_lbl_text4 {
    position: absolute;
    background-color: #ffffff !important;
    color: #0a5009fa;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 5%;
    top: -11px;
    font-size: 14px;
}

.col-sm-13 {
    width: 100%;
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    font-size: 14px;
}

.modify_ptd_text {
    font-size: 14px;
    color: black;
    color: #4e4e4e;
    margin-bottom: 0px;
    margin-left: 20px;
}

/* .table-resposive{
overflow: auto;

} */
.tableheader {
    background-color: white;
}

.modify_im_td_input {
    border: 1.5px solid #ccc0;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 27px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 0px;
    margin-left: 0px;
}

.select_im_input {
    border: 1.5px solid #ccc;
    width: 9%;
    color: #555;
    font-size: 14px;
    height: 35px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
    margin-left: 10px;
}

.company-info-top-left {
    /* border-right: 1px solid #d1cdcd; */
    justify-items: initial;
    width: 50%;
    /* background: #ffffff; */
    padding: 22px;
    /* box-shadow: 2px 2px 15px rgb(212, 212, 212); */
}

.tab1 {
    /* Define styles for non-active tabs here */
    background-color: #e0e0e0;
    /* Default background color */
    padding: 10px;
    cursor: pointer;
}

.tab1.active {
    background-color: #007bff;
    color: #fff;
}

.CheckboxOptionOnHover:hover {
    border: 1px solid lightblue;
    border-radius: 20px;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-131 {
    font-size: 13px;
    color: grey;
}

.font-s-12 {
    font-size: 12px !important;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

.height98vh {
    height: 98vh;
}

.width42 {
    width: 42%;
}

.ord-btn {
    background-color: #004462;
    color: whitesmoke;
    border: 1px solid #004462;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
    font-size: 13px;
    min-width: 70px;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    /* padding-top: 5px; */
}

.orderGridView {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-evenly;
}

.handleScrollReport {
    scrollbar-width: thin;
    scrollbar-color: #adadb9 #e4e6e9;
    height: 88vh;
    overflow-y: scroll;
}

.labelFieldGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
}

.orderBoxDiv {
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
    margin: 10px;
    width: 399px;
    box-shadow: 3px 3px 3px 3px #d9cece;
}

.pendingBtn {
    border-radius: 5px;
    border: 1px solid darkgreen;
    background-color: green;
    color: white;
    font-size: 13px;
}

.prevOrderLeftContainer {
    width: 70%;
}

.prevOrderRightContainer {
    width: 30%;
    border-left: 2px solid black;
}

.btn-lavender {
    background-color: lavender;
    width: 30px;
    height: 28px;
    font-size: 12px;
    padding: 0px;
    border: 1px solid #c8c8f7;
    border-radius: 5px;
    color: #09097b;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-lavender i {
    font-size: 14px;
}

/* .errorMessage{
    font-size: 12px;
    color:red;
    z-index: 1;
    padding:5px;
    background-color: white;
} */

.errorMessage {
    font-size: 12px;
    color: red;
    position: absolute;
    width: max-content;
    top: -16px;
    background: #ffffff;
    left: 0;
}

.slider-container {
    position: fixed;
    top: 0;
    right: -70%;
    height: 100%;
    width: 70%;
    background-color: #f8f8ff;
    border: 2px solid grey;
    border-radius: 20px;
    transition: right 0.3s ease-in-out;
    z-index: 5;
}

.slider-container.open {
    right: 0;
}

.slider-container100 {
    position: fixed;
    top: 0;
    right: -97%;
    height: 100%;
    width: 97%;
    /* background-color: #f8f8ff; */
    background-color: white;
    border: 2px solid grey;
    border-radius: 20px;
    transition: right 0.3s ease-in-out;
    z-index: 5;
}

.slider-container100.open {
    right: 0;
}

.slider-content {
    padding: 20px;
}

.pie-chart {
    border: 1px solid #ddd;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bold {
    font-weight: 600;
}

.fs-121 {
    font-size: 12px;
    color: #757575;
}

.active1 {
    background-color: rgb(72 87 124);
    color: white;
}

.active label {
    color: black !important;
}

.active1 h6 {
    color: white;
}

.active1 i {
    color: white;
}

/* .active1 div {} */

.active-sundry {
    background-color: rgb(72 87 124);
    border-radius: 3px;
    /* box-shadow: 1px 1px 1px 1px #cecece; */
}

.c-w {
    min-width: 50px;
    color: white;
    font-size: 13px;
    height: 22px;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
}

.c-b {
    color: black;
    font-size: 14px;
    font-weight: bold;
}

.tab1.active:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.company-info-top-im {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    margin: auto;
    flex-direction: row;
    /* height: 93vh; */
}

.modify_lbl_text5 {
    position: absolute;
    color: #00000059;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 54%;
    top: -8px;
    font-size: 14px;
}

.company_info-text-im {
    font-size: 15px;
    font-weight: 600;
    color: #494848;
    padding: 8px;
    padding-bottom: 8px;
    background-color: #e1effd;
    margin-bottom: 13px;
    border-radius: 5px;
    align-items: baseline;
}

.modify_lbl_text2 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 53.9%;
    top: -9px;
    font-size: 14px;
}

.modify_lbl_text11 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 89.5%;
    top: -10px;
    font-size: 14px;
}

.mode_alt {
    width: 42%;
}

.mode_category {
    width: 98%;
    padding-left: 8px;
}

.mode_cat1 {
    width: 86%;
    padding-left: 8px;
}

.mode_grp {
    width: 49%;
}

.mode_limit {
    width: 98%;
    padding-left: 7px;
}

.input-box-m1 {
    width: 48%;
    /* display: flex;
    justify-content: center; */
}

.input-box_tag {
    width: 49%;
    padding: 7px;
}

.input-box-m8 {
    width: 100%;
}

.input-box-m4 {
    width: 40%;
}

.input-box-m5 {
    width: 60%;
}

.input-box-m6 {
    width: 100%;
}

.greenBtn {
    background-color: #2a9f79;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    color: white;
    height: 50px;
    font-weight: 600;
    border: 3px solid white;
}

.greenBtn:hover {
    background-color: #1f7559;
    color: white;
}

.redBtn {
    background-color: #e50000;
    padding: 10px;
    height: 50px;
    border-radius: 10px;
    width: 100%;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

.redBtn:hover {
    background-color: #a42b40;
    color: white;
}


.modify_lbl_text7 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    left: 66%;
    top: -10px;
    transform: translateY(1%) scale(0.9) !important;
    background-color: #ffffffee !important;
    padding: 0 0.2em !important;
    color: #7e7c7c !important;
    font-size: 14px;
}

.input-box-m2 {
    width: 100%;
}

.time-date-flex {
    display: flex;
    justify-content: space-between;
}

.modify_lbl_text4 {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 54%;
    top: -9px;
    font-size: 15px;
}

.modify_lbl_text5 {
    position: absolute;
    background-color: #ffffff !important;
    color: #0a5009fa;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 85%;
    top: -11px;
    font-size: 15px;
}

.set_btn {
    border: none;
    background: #10a0d7;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    width: 70px;
    height: 32px;
}

.modify_im_input:valid~label {
    transform: translateY(-8%) scale(0.9);
    background-color: #ffffffee !important;
    /* padding: 0 0.2em !important; */
    color: #7e7c7c !important;
    /* font-size: 14px; */
}

.select_im01_input {
    border: 1.5px solid #ccc;
    width: 17%;
    color: #555;
    font-size: 14px;
    height: 35px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
    margin-left: 39%;
}

.modify_pw_text {
    font-size: 13px;
    color: #e90808;
    margin-left: 5px;
    margin-top: 10px;
    padding-right: 70px;

}

.save_btn01 {
    border: none;
    background: #065702;
    color: white;
    border-radius: 50px;
    font-size: 14px;
    padding: 5px;
    width: 95px;
    height: 40px;
}

.category-overflow {
    overflow: scroll;
    height: 50px;
}

.save_btn01:hover {
    color: white;
    box-shadow: 0 3px 3px 8px rgba(28, 175, 74, 0.178);
    /* box-shadow: 3px 3px 10px rgba(114, 184, 114, 0.473); */
    transition: all 0.2s ease-out;
}

.reset_btn01 {
    border: none;
    background: red;
    color: white;
    border-radius: 50px;
    font-size: 14px;
    width: 95px;
    height: 40px;
}

.reset_btn01:hover {
    color: white;
    box-shadow: 0 3px 3px 8px rgba(240, 88, 95, 0.226);
    transition: all 0.2s ease-out;
}

.im-icon-1 {
    padding-left: 7px;
    font-size: 12px !important;
    margin-bottom: 3px;
    cursor: pointer;
    color: #212e4f;
}

.im-icon-2 {
    padding-left: 3px;
    font-size: 12px;
    margin-bottom: 6px;
    /* color: black; */
}

.im-icon-white {
    padding-left: 7px;
    font-size: 12px;
    margin-bottom: 3px;
    color: rgb(255, 255, 255);
}

.im-icon-3 {
    /* padding-left: 7px; */
    font-size: 18px !important;
    margin-bottom: 3px;
    cursor: pointer;
    color: #212e4f;
}


.im-icon-5 {
    padding-left: 7px;
    font-size: 12px !important;
    margin-bottom: 3px;
    cursor: pointer;
    color: #dfdfdf;
}

.im-icon-6 {
    font-size: 18px !important;
    cursor: pointer;
    color: white;
}

.im-icon-8 {
    padding-left: 7px;
    font-size: 12px !important;
    margin-bottom: 3px;
    cursor: pointer;
    color: #f0eaea;
}

/* .icon_hover3:hover {
    color: #212e4f !important;
    background: white;
    display: flex;
    text-align: center;
} */

.ml-10 {
    padding-left: 7px;
}

.button-mp2 {
    padding-left: 10px;
}

.button-mp3 {
    padding-left: 10px;
    display: inline-grid;
    justify-content: center;
}

.button-mp4 {
    padding-left: 10px;
}

.button-mp5 {
    padding-left: 10px;
}

.serial_btn1 {
    border: none;
    background: #dd0404;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    width: 100px;
    height: 40px;
}

.serial_btn1:hover {
    color: white;
    box-shadow: 0 3px 3px 8px rgba(28, 175, 74, 0.178);
    /* box-shadow: 3px 3px 10px rgba(114, 184, 114, 0.473); */
    transition: all 0.2s ease-out;
}

.optional_btn1 {
    border: none;
    background: #dd0404;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    width: 100px;
    height: 40px;
}

.optional_btn1:hover {
    color: white;
    box-shadow: 0 3px 3px 8px rgba(28, 175, 74, 0.178);
    /* box-shadow: 3px 3px 10px rgba(114, 184, 114, 0.473); */
    transition: all 0.2s ease-out;
}

.setting_btn1 {
    border: none;
    background: #dd0404;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    width: 60px;
    height: 40px;
}

.setting_btn1:hover {
    color: white;
    box-shadow: 0 3px 3px 8px rgba(28, 175, 74, 0.178);
    /* box-shadow: 3px 3px 10px rgba(114, 184, 114, 0.473); */
    transition: all 0.2s ease-out;
}

.company-info1-top-left {
    margin-top: 2%;
    width: 50%;
}

.input-box-station {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 5px;
    height: 40px;
    width: 100%;
    position: relative;
}

.modify-station-input {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 30px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
}

.modify-station-input:focus {
    border-color: #007bff !important;
    border: 1.9px solid #007bff;
    box-shadow: 0 !important;
}

.modify_lbl_text07 {
    position: absolute;
    color: #00000059;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 6%;
    top: -10px;
    font-size: 14px;
}

.radio-buttons-container {
    display: flex;
    align-items: center;
    gap: 24px;
}

.radio-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.radio-button__input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.radio-button__label {
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;
    font-size: 13px;
    color: #645e5e;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
}

.radio-button__custom {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #555;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.radio-button__input:checked+.radio-button__label .radio-button__custom {
    transform: translateY(-50%) scale(0.9);
    border: 5px solid #4c8bf5;
    color: #4c8bf5;
}

.radio-button__input:checked+.radio-button__label {
    color: #4c8bf5;
}


.radio-button__label:hover .radio-button__custom {
    transform: translateY(-50%) scale(1.2);
    border-color: #4c8bf5;
    box-shadow: 0 0 10px #4c8bf580;
}

.radio-buttons-container1 {
    display: flex;
    align-items: center;
    gap: 15px;
}

.radio-button1 {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.radio-button__input1 {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.radio-button__input1:checked+.radio-button__label1 .radio-button__custom1 {
    transform: translateY(-50%) scale(0.9);
    border: 5px solid #4c8bf5;
    color: #4c8bf5;
}

.radio-button__label1 {
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;
    font-size: 13px;
    color: #645e5e;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.radio-button__custom1 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #555;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.radio-button__input1:checked+.radio-button__label1 {
    color: #4c8bf5 !important;
}

.radio-button__label1:hover .radio-button__custom1 {
    transform: translateY(-50%) scale(1.2);
    border-color: #4c8bf5;
    box-shadow: 0 0 10px #4c8bf580;
}

.double_input1 {
    display: flow;
    text-align: center;
    width: 70%;
}

.input-box3 {
    display: flex;
}

.input-box-Im1 {
    height: 152px;
    width: 50%;
    padding-left: 2px;
}

.modify_p_2 {
    display: flex;
    width: 60%;
    padding-left: 20px;
    color: #8d8a8a;
    height: 40px;
    font-size: 14px;
}

.m2 {
    padding-left: 30px;
}

.input-box-Im2 {
    height: 34px;
    width: 50%;
}

.company-info-top-left-f1 {
    justify-items: initial;
    width: 100%;
    background: #ffffff;
    padding: 20px;
}

.input-box-m2 {
    width: 100%;
}

.input-box-tab-head {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 2px;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
}

.modify_im_textarea {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    /* height: 35px; */
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
}

.modify_p_text-fix {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 6%;
    top: -12px;
    font-size: 14px;
}

.mode_category2 {
    padding: 8px;
    width: 98% !important;
}

.pb-15 {
    padding-bottom: 15px;
}

.stock_set {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 125px;
    font-weight: 600;
    height: 35px;
    border: 1px solid gray;
}

.stock_search {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 125px;
    font-weight: 600;
    height: 35px;
    border: 1px solid gray;
    margin-right: 5px;
}

.stock_search1 {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 90px;
    font-weight: 600;
    height: 35px;
    border: 1px solid gray;
    /* margin-left: 60px; */
}

.company-info-top-left-tab5 {
    border-right: 1px solid #d1cdcd;
    justify-items: initial;
    width: 80%;
    background: #ffffff;
    padding-bottom: 0px;
}

.company-info-top-left-tab6 {
    border-right: 1px solid #d1cdcd;
    justify-items: initial;
    width: 100%;
    background: #ffffff;
    padding-bottom: 40px;
}

.tab6_col {
    background-color: rgb(33 46 79);
    height: 33px;
    font-size: 14px;
    color: white;
}

.ProductAttributeTabHead {
    background-color: rgb(33 46 79);
    color: white;
}

.tab6_tr6 {
    height: 35px;
    border-bottom: 1px solid #dfd7d7;
    font-size: 15px;
}

.tab6_search_btn {
    background-color: #007bff;
    border: 1px solid gray;
    height: 25px;
    cursor: pointer;
    width: 80px;
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.Tab6DeleteBtn {
    position: absolute;
    right: 14px;
    top: 10px;
}

.TabDelIcon {
    font-size: 15px;
    cursor: pointer;
}

.TabDelIcon:hover {
    color: red;
}

/* .Tab6inputBox{
padding-left: 5px;
width: 120px;
} */
.company-info-top-right-tab5 {
    width: 50%;
    padding-bottom:
        10px;
}

.modify_lbl_text22 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 36%;
    top: -8px;
    font-size: 14px;
}

.tabs-col-sm-1 {
    width: 100%;
    position: relative;
    min-height: 1px;
    font-size: 13px;
}

.tabs-table {
    width: 100%;
}

.tabs-table-col16 {
    width: 98%;
    margin-left: 15px;
}

.tabs-table1 {
    width: 92%;
}

.category-add-btn {
    width: 98%;
    border: none;
    background-color: #5672f2;
    color: white;
    border-radius: 3px;
}

.company-info-top-left-tab3 {
    border-right: 1px solid #d1cdcd;
    justify-items: initial;
    width: 100%;
    background: #ffffff;
    padding-bottom: 0px;
}

.product-attribute-btn {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px;
    width: 150px;
    font-weight: 600;
    height: 35px;
    border: 1px solid gray;
    float: right;
}

.product-attribute-btn:hover {
    background-color: #dad9d9;
}

.input-box-mp1 {
    width: 98%;
}

.modify_lbl_text-si1 {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 56%;
    top: -14px;
    /* z-index: 9; */
    font-size: 13px;
}

.col_4 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 5px;
}

.input-box-SI {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 13px !important;
    /* height: 40px; */
    width: 50%;
    position: relative;
}

.input-box-top-head {
    width: 100%;
    display: flex;
    justify-content: center;
}

.input-box-head {
    display: flex;
    justify-content: space-between;
}

.modify_lbl_text6 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 57.2%;
    top: -14px;
    font-size: 14px;
}

.modify_lbl_text12 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 55.3%;
    top: -13px;
    font-size: 14px;
}

.col-sm-3 {
    display: flex;
    justify-content: space-between;
    width: 37px;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    font-size: 11px;
    font-weight: 500;
    font-family: system-ui;
}

.input-box-head-left {
    width: 49%;
}

.input-box-head-right {
    width: 49%;
}

.input-box-SI-1 {
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 2px;
    position: relative;
}

.m12 {
    height: 22px;
}

.tab-b1 {
    height: 25px !important;
}

.tab-b2 {
    height: 25px !important;
}

.input-s1 {
    height: 27px;
}

.input-s2 {
    height: 28px !important;
}

.m-22 {
    top: -13px;
}

.m25 {
    top: 6px;
}

.tabs-main {
    display: flex;
    width: 100%;
}

.radio-btn-1 {
    align-items: baseline;
    padding-right: 5px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radio-btn-2 {
    width: 20%;
    display: flex;
    justify-content: space-between;
}

.si-size-1 {
    width: 50%;
}

.si-size-2 {
    width: 50%;
}

.dis-flex {
    display: flex;
}

.radio-box-1:valid {
    box-shadow: none !important;
}

.p2 {
    padding-left: 10px !important;
    justify-content: center;
}

.input-box-s11 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;
}

.bottm-01 {
    margin-bottom: -6px !important;
}

.label-box-si-12 {
    font-size: 12px;
}

.table-left-padding {
    width: 40%;
    padding-bottom: 0px !important;
    padding-top: 17px !important;
}

.table-right-padding {
    width: 60%;
    /* padding-right: 5px; */
}

.box-size1 {
    padding-left: 3px;
    padding-right: 1px !important;
}

.input-box-m9 {
    width: 100%;
}

.sol1 {
    width: 40px;
}

.sol2 {
    width: 190px;
}

.sol3 {
    width: 66px;
    padding-left: 5px;
}

.sol4 {
    width: 125px;
}


.label-input-sa1 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 10px;
    top: -15px;
    font-size: 11px;
}

.input-box-v1 {
    position: relative;
}

.input-s2 {
    width: 38% !important;
    border: 1.5px solid #ccc;
    color: #555;
    font-size: 14px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.tab-1 {
    width: 20%;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.addedGridView {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-evenly;
}

.modal-back {
    position: absolute;
    width: 100%;
    height: 1500vh;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 14%);
    z-index: 101;
    animation: modalback 400ms;
    /*overflow:unset !important;*/
}

.partyDetail-modal {
    width: 30%;
    height: 65%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: #2b2725c9;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.partyDetailPosModal {
    width: 30%;
    height: 65%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.rightSide-modal {
    width: 85%;
    height: 100%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 0%;
    right: 0%;
    transform: translate(0%, 0%);
    animation: ease-in 1000ms;
}

.BatchWise-modal {
    max-width: 90%;
    width: max-content;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.MultiGOdownModal {
    width: 23%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.large-radio {
    width: 20px;
    height: 20px;

}

.radio-label {
    font-size: 16px;
    margin-right: 10px;
    display: flex;
    color: white;
}

.closeBtnhover {
    color: white;
}

.closeBtnhover:hover {
    color: rgb(255, 59, 59);
    font-weight: 600;
}

/***** Switch ****/

.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 25px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: green;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.table-down-s1 {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
}

.table-down-s2 {
    justify-content: space-between;
    /* width: 140px; */
    padding-left: 8px;
    display: flex;
    /* justify-content: right; */
    padding-right: 5px;
    border-radius: 3px;
    color: red;
    height: 25px;
    border: 1px solid #d5d5d5;
}

.table-down-l2 {
    display: flex;
    padding-right: 5px;
    color: #262626;
    align-items: center;
}

.tableAttributBtn {
    width: max-content;
    display: flex;
    padding-right: 5px;
    color: #ffffff;
    font-weight: 600;
    align-items: center;
    background: #007bffde;
    border: 1px solid gray;
    border-radius: 2px;
}

.button-col-s1 {
    padding-right: 8px;
}

.button-col-s2 {
    color: #080808;
    border-radius: 4px;
    font-size: 11px;
    padding: 0px;
    width: 55px;
    font-weight: 600;
    height: 25px;
    border: 1px solid gray;
}

.color-col-s1 {
    padding: 8px;
    background-color: #8ec4f4;
    border-radius: 50px;
    display: inline-block;
    margin: -5px 0px;
    height: 20px;
    width: 20px;
}

.table-row2 {
    width: 30%;
}

.tbody-col-3 {
    background-color: #d5d5d5 !important;
}

.table-ax-1 {
    background-color: #d5d5d5;
}

.col-xl-2 {
    padding-right: 20px !important;
}

.color-col-s2 {
    padding: 8px;
    background-color: #8ec4f4;
    border-radius: 50px;
    display: inline-block;
    margin: -5px 0px;
}

.color-col-s3 {
    /* padding: 8px; */
    background-color: #8fe4cf;
    border-radius: 20px;
    display: inline-block;
    margin: -5px 0px;
    height: 20px;
    width: 20px;
}

.table-down-main {
    display: flex;
    width: max-content;
    justify-content: flex-end;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
}

.tab-2 {
    width: 15%;
    color: #4f4c4c;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-items: center;

}

.input-box-m7 {
    width: 100%;
    display: flex;
}

.tabs-main1 {
    width: 100%;
    display: flex;
}

.tab_a1 {
    /*width: 45%;*/
    width: 40%;
}

.table-row1 {
    width: 35%;
}

.table-row2 {
    width: 25%;
}

.padding-20 {
    padding: 8px 25px;
}

.col-xl-1 {
    padding-left: 0px !important;
    margin-bottom: 5px !important;
}

.tbody-col-2 {
    padding-bottom: 5px;
    padding-top: 20px;
    background-color: #d5d5d5;
}

.td-col2 {
    width: 250px;
    text-align: center;
}

.input-box-td2 {
    width: 99%;
}

.table-col12 {
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.tab-b3 {
    height: 25px !important;
    align-items: center;
}

.table-input-col-1 {
    color: #555;
    font-size: 13px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
}

.input-box-SI-2 {
    display: flex;
    justify-content: space-between;
    position: relative;
    font-size: 13px;
    margin-bottom: 1.5px;
    height: 27px;
}

.search-panel-col-1 {
    display: flex;
    padding-bottom: 2px;
}

.pwrInput {
    font-size: 12px;
    padding: 3px;
}

.color-col-s4 {
    /* padding: 8px; */
    background-color: #f0ec98;
    border-radius: 20px;
    display: inline-block;
    margin: -5px 0px;
    height: 20px;
    width: 20px;
}

.panel-all-btn {
    display: flex;
    justify-items: baseline;
}

.panel-search-btn {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 1px;
    width: 65px;
    height: 25px;
    border: 1px solid gray;
}

.panel-search-btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.panel-reset-btn {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 1px;
    width: 65px;
    height: 25px;
    border: 1px solid gray;
}

.panel-reset-btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.panel-buy-btn {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 1px;
    width: 170px;
    height: 25px;
    border: 1px solid gray;
}

.panel-buy-btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.close-btn1 {
    color: black;
    background-color: lightgray;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
}

.color-col-s3 {
    /* padding: 8px; */
    background-color: #8fe4cf;
    border-radius: 20px;
    display: inline-block;
    margin: -5px 0px;
    height: 20px;
    width: 20px;
}

.modify_im_input12 {
    background: white;
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 26px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
}

.col-d-11 {
    display: flex;
    justify-content: center;
}

.col-d-1 {
    display: flex;
}

.col-xl-1 {
    padding-left: 10px !important;
}

.col-in-1 {
    padding-left: 10px !important;
    font-size: 12px;
}

.input-box-head-left2 {
    width: 98%;
}

.panel-col-1 {
    padding-right: 5px;
}

.panel-col-2 {
    padding-right: 5px;
}

.input-box-m3 {
    width: 80%;
}

.mid-size-01 {
    width: 100%;
}

.padding-1 {
    padding-top: 0px;
}

.padding-02 {
    padding-right: 5px;
}

.padding-03 {
    padding-left: 20px;
    padding-right: 20px;
    align-items: baseline;
}

.radio-btn-02 {
    padding-left: 10px;
    font-size: 12px;
    padding-right: 10px;
    width: 135%;
    display: flex;
    justify-content: space-between;
}

.input-remark-01 {
    display: flex;
    width: 100%;
    padding-top: 5px;
}

.input-remark-left {
    width: 43%;
}

.input-remark-001 {
    width: 100%;
    display: flex;
}

.input-remark-m1 {
    width: 100%;
}

.input-remark-right {
    width: 60%;
}

.input-remark-box-m1 {
    width: 80%;
}

.input-remark-box-m12 {
    width: 50%;
}

.input-remark-box-m13 {
    width: 56%;
}

.input-size-01 {
    width: 73%;
}

.settingIcon {
    display: block;
}

.feedback-dropDown {
    background-color: white;
    padding: 10px;
    z-index: 2;
    margin: 0px;
    border-radius: 5px;
    border: 1px solid lightgray;
    height: 550px;
    overflow-y: scroll;
}

.table-input {
    border: 1.5px solid #ccc;
    color: #555;
    font-size: 12px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 3px;
    width: 10.5%;
    /* padding-top: 1px; */
    /* padding-bottom: 1px; */
    padding: 0px 5px;
}

.col-sm-4 {
    display: flex;
    justify-content: center;
    width: 10%;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    /* padding-right: 7px; */
}

.row-m1 {
    display: flex;
    justify-content: flex-start;
}

.input-j1 {
    margin-left: 112px;
}

.radio-btn-n1 {
    box-shadow: none !important;
    margin-right: 5px;
}

.radio-btm-m1 {
    width: 66%;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.radio-btm-m2 {
    width: 69%;
    display: flex;
    /* justify-content: space-between;
    padding-right: 128px; */
    padding-left: 25px;
}

.radio-button-label-m1 {
    padding-left: 5px;
}

.radio-btn-n2 {
    color: #7e7d7d;
    font-weight: 600;
}

.radio-btn-n3 {
    color: #545252;
    font-weight: 600;
}

.radio-size-m1 {
    height: 25px;
}

.col-sm-5 {
    display: flex;
    justify-content: center;
    width: 43% !important;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    padding-left: 4px;
}

.mj-m1 {
    padding-left: 10px;
}

.col-sm-3-w1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
}

.table-input-w1 {
    border: 1.5px solid #ccc;
    color: #555;
    font-size: 14px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 2px;
    width: 12% !important;
}

.img-bm {
    width: 95px;
    box-shadow: none !important;
}

.td-col3 {
    width: 35%;
    color: white;

}

.input-box-time-1 {
    width: 60%;
}

/* .stock-im-1 {
display: flex;
align-items: baseline;
} */
.search-btn-m1 {
    color: #080808;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px;
    width: 80px;
    font-weight: 600;
    height: 32px;
    border: 1px solid gray;
    text-align: center;
}

.modify_im_textarea:focus {
    border-color: #007bff !important;
    border: 1.9px solid #007bff;
    box-shadow: 0 !important;
}

.modify_lbl_text10 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 54%;
    top: -8px;
    font-size: 14px;
}

.modify_label_text2 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 37px;
    top: -9px;
    font-size: 14px;
}

.modify_label_text3 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -8px;
    font-size: 14px;
}

.modify_label_text4 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -8px;
    font-size: 14px;
}

.modify_label_text5 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 24px;
    top: -8px;
    font-size: 14px;
}

.modify_label_text6 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -13px;
    font-size: 14px;
}

.stock-im-2 {
    display: flex;
    align-items: baseline;
}

.gd-1 {
    display: flex;
    justify-content: space-between;
}

.td-1 {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 6px;
}

.td-2 {
    padding-left: 120px;
    display: flex;
}

.td-3 {
    padding-top: 0px !important;
}

.new-category-add1 {
    border: 3px dashed rgb(89, 207, 253);
    background-color: #b9b7b7;
    height: 35px;
}

.tbody_body_m1 {
    overflow-y: auto;
    height: calc(100% - 42px);
}

.table_head_mmb {
    display: flex;
}

.table_head_mmb1 {
    padding-right: 5px;
    font-size: 14px;
}

.table_head_mmb2 {
    padding-top: 3px;
    padding-left: 1px;
    display: grid;
}

.t_icon1 {
    height: 0px;
}

.t_icon1:hover {
    color: red;
}

.t_icon2:hover {
    color: red;
}

.table_tbody_tr1 {
    border-top: 1px solid #e3e1e1;
    height: 32px;
}

.table_tbody_tr1:hover {
    background-color: #88c3b454;
}

.status_content_input_m1 {
    padding-top: 10px;
    width: 60%;
}

.input_box_double {
    width: 49%;
    display: flex;
}

.input-box-m1_1 {
    width: 75%;
}

.input-box-m1_2 {
    width: 25%;
}

.modal-content_Attribute_Fine {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 72%;
    border-radius: 12px;
}

.table-down-s1 {
    width: 75%;
    display: flex;
    justify-content: center;
    padding-right: 72px;
    align-items: center;
}

.modal-title {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.overflowY {
    overflow-y: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    border: 1px solid gray;
}

.css-3w2yfm-ValueContainer {
    width: 200px;
}

.tableSelectBox {
    border: 1px solid gray;
}

.overflowY::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar {
    display: none;
}

.modal_span_bbn {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 25px;
    padding-bottom: 6px;
}

.modal_span_bbn_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 5px;
}

.lens_bbm_4 {
    width: 26%;
    padding-left: 13px;
}

.lens_bbm_1 {
    width: 25%;
}

.lens_bbm_5 {
    width: 50%;
}

.lens_bbm_2 {
    width: 25%;
    padding-left: 8px;
}

.lens_bbm_3 {
    width: 25%;
    padding-left: 8px;
}

.bbm_h1 {
    height: 30px;
    padding-left: 7px;
}

.modai_container {
    padding: 12px;
    border: 1px solid #ccc6c6;
    border-radius: 5px;
}

.lens_bbme {
    font-size: 12px;
    padding-left: 9px;
    color: #e03b3b;
    display: flex;
    height: 28px;
}

.check_bbn {
    display: flex;
    justify-content: space-between;
    padding-right: 2px;
}

.check_mmb {
    box-shadow: none !important;
    margin-right: 5px;
}

.time_bnn {
    display: flex;
    width: 48%;
}

.mmd {
    display: flex;
}

.btn-table1 {
    font-size: 13px;
    background-color: white;
    height: 26px;
    margin: 0px;
    width: 100%;
}

.mb_1 {
    width: 200px;
    text-align: center;
}

.mb_2 {
    width: 200px;
    text-align: center;
}

.mb_3 {
    width: 100%;
    text-align: center;
}


.mb_4 {
    width: 200px;
    text-align: center;
}

.mb_5 {
    width: 200px;
    text-align: center;
}

.mb_6 {
    width: 200px;
    text-align: center;
}

.mb_7 {
    width: 200px;
    text-align: center;
}

.mb_8 {
    width: 200px;
    text-align: center;
}

.mb_9 {
    width: 200px;
    text-align: center;
}

.mb_10 {
    width: 200px;
    text-align: center;
}

.mb_11 {
    width: 200px;
    text-align: center;
}

.mb_12 {
    width: 200px;
    text-align: center;
}

.mb_13 {
    width: 200px;
    text-align: center;
}

.mb_14 {
    width: 200px;
    text-align: center;
}

.mb_15 {
    width: 200px;
    text-align: center;
}

.mb_16 {
    width: 200px;
    text-align: center;
}

.mb_17 {
    width: 200px;
    text-align: center;
}

.mb_18 {
    width: 200px;
    text-align: center;
}

.table-tr2 td {
    width: 120px;
    margin: 2px 1px;
    border: 1px solid #d6d6d6;
    height: 28px;
    border-radius: 3px;
}

.table-tr2 th {
    margin: 3px;
    width: 120px;
}

.lensTableHeader {
    color: black;
    background-color: #afbbdd;
    font-size: 12px;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    text-align: center;
}

.width-size {
    width: 25%;
    font-size: 14px;
}

.saleRemarkPart {
    background-color: white;
    /* border: 1px solid #f6f9ff; */
    padding: 0px 10px 0px 10px;
}

.SaleTaxBtn {
    margin: 5px;
}

.button-col-SOTax {
    padding-right: 3px;
    font-size: 12px;
}

.paginationButton {
    margin: 0px;
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid lightgray;
    background-color: white;
}

.paginationActiveBtn {
    background-color: #d6e2ff !important;
    border: 1px solid #c6c2c2;
}

.mb_19 {
    width: 200px;
    text-align: center;
}

.mb_20 {
    width: 200px;
    text-align: center;
}

.mb_21 {
    width: 200px;
    text-align: center;
}

.mb_22 {
    width: 200px;
    text-align: center;
}

.Attrib_3 {
    width: 300px;
    text-align: center;
}

/* ************* {BatchWise} ************* */

.mb_24 {
    width: 70px;
    text-align: center;
}

.mb_25 {
    width: 100px;
    text-align: center;
}

.hover-tr:hover {
    background-color: #beddff;
}

.hover-tr .pd_1 {
    box-shadow: none !important;
    border: none !important;
}

.pd_1 {
    padding-left: 4px;
    font-size: 14px;
}

.tb_1 {
    color: #4c4a4a !important;
}

.table-input-13:focus-within {
    border-color: #007bff !important;
    border: 1.9px solid #007bff;
    box-shadow: 0 !important;
}

.col_58745 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: #d5d5d5;
}

.company-info-top-left-i1 {
    border-right: 1px solid #d1cdcd;
    justify-items: initial;
    width: 50%;
    background: #ffffff;
}

.col_587452 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: #d5d5d5;
    padding-bottom: 3px;
}

.ssm {
    /* padding-right: 10px; */
    display: flex;
    /* justify-content: center; */
}

.sol5 {
    width: 125px;
    color: #6a6ac6;
    font-size: 14px;
    padding-top: 2px;
}

.input-box-head-fm1 {
    display: flex;
    padding-top: 5px;
}

.input-box-content-l1 {
    width: 100%;
}

.input-box-content-r1 {
    width: 100%;
}

.alignCenter {
    display: flex;
    justify-content: flex-start;
    align-content: center !important;
    align-items: center !important;
}

.input-box-bill {
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
    position: relative;
}

.tab-double-dmm5 {
    display: flex;
}

.col-sm-sal-3 {
    display: flex;
    justify-content: space-between;
    width: 37px;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    font-size: 11px;
    font-weight: 600;
    font-family: monospace;
}

.input-sal3 {
    border: 1.5px solid #ccc;
    color: #555;
    font-size: 14px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 1px;
    width: 42%;
    padding-top: 1px;
    padding-bottom: 1px;
}

.pp1 {
    padding-top: 0 !important;
}

.ssp {
    position: absolute;
    color: #696969;
    pointer-events: none;
    transform: translateY(1rem);
    left: 31px;
    font-size: 14px;
}

.ssp2 {
    position: absolute;
    color: #696969;
    transform: translateY(1rem);
    left: 57.2%;
    font-size: 14px;
}

.col-sm-3:hover,
.col-sm-4:hover,
.col-sm-3:focus-visible,
.col-sm-4:focus-visible {
    background-color: #b1d6fd;
}

.modify_lbl_text15 {
    position: absolute;
    color: #9c9ea1;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 57.2%;
    top: -13px;
    font-size: 14px;
}

.input-height4 {
    width: 100%;
    /* height: 113px; */
    margin-bottom: 23px;
}

.input-height5 {
    width: 100%;
    /* height: 113px; */
    /* margin-bottom: 10px; */
}

.colLensRange {
    display: flex;
    justify-content: space-between;
    width: 8% !important;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    font-size: 11px;
    font-weight: 500;
    font-family: system-ui;
}

.llm-1 {
    color: #232323;
}

.modify_im_input14 {
    border: 1.5px solid #ccc;
    color: #555;
    font-size: 14px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 2px;
}

.modify_im_input14:valid~label {
    transform: translateY(21%) scale(0.7);
    background-color: #ffffffee !important;
    padding: 0 0.1em 0 !important;
    color: #7e7c7c !important;
    font-size: 13px;
}

.ppm-1 {
    padding-right: 2px;
}

.mode_category3 {
    width: 88%;
    padding-left: 5px;
}

.label-input-sa1:valid~label {
    transform: translateY(21%) scale(0.7) !important;
    background-color: #ffffffee !important;
    padding: 0 0.1em 0 !important;
    color: #7e7c7c !important;
    font-size: 13px;
}

.modify_p_text7 {
    position: absolute;
    color: #4b4848;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -18px;
    font-size: 14px;
}

.modify_im_input15 {
    border: 1.5px solid #ccc;
    color: #555;
    font-size: 14px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 2px;
}

.col_58 {
    display: flex;
    justify-content: flex-start;
    background-color: #d5d5d5;
    padding-bottom: 2px;
    padding-left: 10px;
}

.col_fm_xl1 {
    padding-top: 10px;
}

.container_over {
    width: 100%;
    overflow: auto;
    height: 140px;
    margin-top: 0px;
    display: flex;
    /* align-items: center; */
}

.pay_amt1 {
    font-size: 14px;
    color: #d20707;
}

.container_over::-webkit-scrollbar {
    /* display: none; */
}

.mode_cat3 {
    /* width: 100%; */
    padding-left: 5px;
}

.mode_cat6 {
    width: 100%;
}

.input-size-04 {
    width: 20%;
}

.padding-05 {
    padding-left: 20px;
    align-items: baseline;
}

.td-col4 {
    width: 60px;
    text-align: center;
}

.m-logo {
    display: flex;
}

.panel-time {
    display: none;
}

.buttom-box {
    display: none;
}

.input-box3 {
    display: flex;
}

.input-box3 {
    display: flex;
}

.dis-flex {
    display: flex;
}

.col-d-1 {
    display: flex;
}

.stock-im-2 {
    display: flex;
    align-items: baseline;
}

.gd-1 {
    display: flex;
    justify-content: space-between;
}

.table_head_mmb {
    display: flex;
}

.mmd {
    display: flex;
}

.ssm {
    display: flex;
}

.tab-double-dmm5 {
    display: flex;
}

.direct_coll-2 {
    display: flex;
    justify-content: space-between;
}

.direct_coll-1 {
    display: flex;
    justify-content: space-between;
}

.dm_3 {
    display: flex;
}

.table-th-2 {
    text-align: center;
    padding-right: 15px;
    width: 125px;
}

.table-tr5 {
    font-size: 11px;
    display: block;
    position: relative;
}

.modify_im_input17 {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 35px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
}

.PriceMarket {
    display: flex;
    justify-content: space-around;
    padding-right: 10px;
}


.dm_4 {
    font-size: 21px;
    padding: 7px 2px 2px;
    color: #007bffde;
}

.mode_category11 {
    width: 89%;
    padding-left: 8px;
}

.dm_4:hover {
    color: #0058b6;
}

.tableLefts {
    width: 100%;
    padding-top: 5px !important;
}

.LinkTextContent {
    text-decoration: none;
}

/* .fa-trash:hover {
    color: red;
} */

/* .fa-solid.fa-pen-to-square:hover {
    color: #1702d9;
} */

.table-input-12:hover {
    background-color: #c5dbe0;
}

.tag_btn {
    border-radius: 5px;
    border: 1px solid gray;
    background: #385ab0;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    height: 35px;
}

.height25vh {
    height: 25vh;
}

.delete_icon {
    color: red;
    font-size: 12px !important;
    border: none;
    border-radius: 10px;
    padding-left: 3px;
}

.tag_manage_1 {
    gap: 2px;
    margin: 3px;
    padding: 3px 12px;
    border: 1px solid gray;
    background: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dis_flex {
    display: flex;
    flex-direction: row-reverse;
}

.modify_p_text6 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 20px;
    top: -18px;
    font-size: 14px;
}

.wrapper {
    display: none;
}

.btm_im3 {
    padding-bottom: 20px;
}

.LoadProperties1 {
    border: none;
    margin-top: 1px;
    height: 33px;
    border-radius: 5px;
    font-size: 14px;
    width: 90%;
    font-weight: 600;
    background: #385ab0;
    color: #ffffff;
}

.height30vh {
    height: max-content;
    max-height: 200px;
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    margin-bottom: 5px;
    margin-top: 0px;
}

.height30vh .table-row1 {
    border-right: 1px solid #cccccc;
}

/* .height30vh .table-row2 {
    /* border-right: 1px solid #cccccc;
*/

.height30vh .tab_a1 {
    border-right: 1px solid #cccccc;

}

.space-between .span1 {
    display: flex;
    align-items: center;
}

.LeftSide01 {
    left: 77.5%;
}

.LeftSide02 {
    left: 54.4%;
}

.LeftSide03 {
    left: 25px;
}

.LoadHeadCol1 {
    width: 50%;
    display: flex;
}

.LoadHeadCol2 {
    width: 49%;
    display: flex;
}

.LoadHeadWidth1 {
    width: 100%;
}

.LocalHeadContent01 {
    height: 250px;
    border: 1px solid gray;
    width: 98%;
}





/* ******************** {New Css} *************** */


.table-bordered1 {
    border: 1px solid #ddd;
}

.td_col6 {
    border-left: 1px solid #e4e4e4;
    font-size: 14px;
    text-align: center;
}

.table_head_mmb1 {
    padding-right: 5px;
    font-size: 14px;
}

.table_head_nhb {
    display: flex;
    justify-content: center;
}

.table_head_nnb {
    display: flex;
    width: 200px;
    justify-content: center;
}

.table_head_mab {
    display: flex;
    width: 100px;
    justify-content: center;
}

.table_head_mmbb1 {
    padding-right: 5px;
    font-size: 14px;
    padding-left: 5px;
}

.input-so1 {
    height: 38px;
}

.height28vh {
    height: 28vh;
}


.input-remark-box-mm1 {
    width: 30%;
    display: flex;
}

.input-remark-left01 {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;

}

.mid-size-m01 {
    width: 110px;
}

.solo4 {
    width: 40px;
}

.equal {
    width: 50px;
    margin: auto;
}

.input-box-mo9 {
    width: 100%;
}

.padding-m03 {
    padding-left: 80px;
    padding-right: 10px;
    align-items: baseline;
}

.button-mpo2 {
    padding-left: 0px;
}

.load_pad {
    padding-left: 10px;
}

.optionBtn {
    background-color: lightblue;
    display: flex;
    justify-content: center;
    color: black;
    align-items: center;
}

.optionBtn:hover {
    background-color: #0058b6;
    color: white;
}

.input-box-vo1 {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 120px;
}

.show_btn {
    border: 1px solid #d0caca;
    color: black;
    border-radius: 5px;
    font-size: 15px;
    width: 95px;
    height: 35px;
}

.show_btn:hover {
    background-color: #b2b2b2;
    transition: all 0.2s ease-out;
}

.calculate_btn {
    border: none;
    color: #f0eaea;
    background-color: #6464ff;
    border-radius: 5px;
    font-size: 15px;
    padding: 4px;
    width: 90px;
    height: 35px;
    text-decoration: #007bff;
}

.calculate_btn:hover {
    background-color: #3d3dff;
    transition: all 0.2s ease-out;
    color: white;
}

.mode_limits {
    width: 100px;
}

.save_cat_btn {
    border: 1px solid #d0caca;
    color: black;
    border-radius: 5px;
    font-size: 15px;
    width: 135px;
    height: 35px;
}

.save_cat_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.table-input-box {
    width: 98%;
    margin: auto;
    height: 34PX;
    border-radius: 1px !important;
}

.table-input-box:hover {
    background-color: #c5dbe0;
}

.bill_panel_input {
    width: 148px;
    height: 35px;
    line-height: 28px;
    /* padding: 0 1rem; */
    padding-left: 0.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: white;
    color: #0d0c22;
    transition: 0.3s ease;
}

.bill_panel_input::placeholder {
    color: #9e9ea7;
}

.bill_panel_input:focus {
    outline: none;
    border-color: rgba(234, 76, 137, 0.4);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.check_box {
    height: 17px;
    width: 17px;
}

.group_head {
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 50%;
    padding-left: 0px;
    padding-right: 10px;
    display: flex;
}

.searchbar_panel_input {
    width: 160px;
    height: 35px;
    line-height: 28px;
    /* padding: 0 0rem; */
    padding-left: 2.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: white;
    color: #0d0c22;
    transition: 0.3s ease;
}

.searchbar_panel_input::placeholder {
    color: #9e9ea7;
}

.searchbar_panel_input:focus {
    outline: none;
    border-color: rgba(234, 76, 137, 0.4);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.date_panel_input {
    width: 120px;
    height: 35px;
    line-height: 28px;
    /* padding: 0 1rem; */
    padding-left: 0.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: white;
    color: #0d0c22;
    transition: .3s ease;
}

.date_panel_input::placeholder {
    color: #9e9ea7;
}

.date_panel_input:focus {
    outline: none;
    border-color: rgba(234, 76, 137, 0.4);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.print_btn {
    margin: auto;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.whatsapp_btn {
    margin: auto;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #0fbd0f;
    background: transparent;
    font-size: 24px;
}

.workshop_btn {
    margin: auto;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #800080;
    background: transparent;
    margin: 0px;
}

.radio-btm-BatchWise {
    width: 38%;
    padding-right: 20px;
    padding-left: 20px;
}

.radio-btn-BatchWise2 {
    color: #7e7d7d;
    font-weight: 600;
    padding-left: 15px;
}

.ItemSerialCol1 {
    margin-top: 10px;
}

.input-box-Im5 {
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 13px;
    width: 100%;
    position: relative;
}

/* .modify_p_text2 {
    top: -9px;
} */

.modify_p_text2Batch {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: 30px;
    font-size: 14px;
}

.BatchConfigBtn {
    border: 1px;
    color: white;
    background: #fa4949;
    height: 33px;
    border-radius: 5px;
    margin-left: 5px;
}

.newRowBtn {
    background-color: darkgreen;
    border: 1px solid darkgreen;
    border-radius: 5px;
    font-weight: 600;
    color: white;
}

.InputBoxButton {
    display: flex;
    padding-top: 3px;
    text-align: center;
    padding-left: 3px;
}

.AddNew_Btn1 {
    border: 1px solid rgb(255, 255, 255);
    color: white;
    background: #2a9f79;
    height: 30px;
    border-radius: 4px;
}

.Delete_btn1 {
    border: 1px solid rgb(253, 253, 253);
    color: white;
    background: #f64444;
    height: 30px;
    border-radius: 4px;
}

.Edit_Btn1 {
    border: 1px solid rgb(255, 253, 253);
    color: white;
    background: #1788b5;
    height: 30px;
    border-radius: 4px;
}

.BatchWise-modal2 {
    width: 45%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.table-th_Wise {
    padding-top: 4px;
    text-align: center;
    color: #fffdfd;
    font-size: 13px;
    padding-right: 10px;
    background: rgb(49 73 137);
    padding-bottom: 4px;
}

.BatchWise-modal3 {
    width: 31%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: whitesmoke;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.BatchWiseModalBox1 {
    padding-top: 10px;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
}

.table-input-15 {
    width: 100%;
    margin: auto;
    height: 26px;
    border-radius: 1px !important;
    background: white;
    padding: 5px;
    box-shadow: none !important;
}

.table-input-15:focus-visible {
    outline: none;
}

.save_btnBatch {
    border: none;
    color: #f0eaea;
    background-color: #2a9f79;
    border-radius: 5px;
    font-size: 15px;
    padding: 5px;
    width: 110px;
    height: 35px;
    font-weight: 600;
}

.reset_btnBatch {
    border: 1px solid gray;
    color: #dddddd;
    border-radius: 5px;
    font-size: 15px;
    width: 110px;
    height: 35px;
    font-weight: 600;
    background: #ff4163 !important;
}

/* // ****************** { css part 2}******************** */
.icon_btn_batch {
    font-size: 18px !important;
    padding: 5px 13px 3px 11px;
}

.input-box-m11 {
    width: 50%;
    padding: 7px;
    position: relative;

}

.modifyTextLabel {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 20px;
    top: -2px;
    font-size: 14px;
}

.modifyTextLabel2 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 7%;
    top: -2px;
    font-size: 14px;
}

.modifyTextLabel3 {
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 7.3%;
    top: -2px;
    font-size: 14px;
}

.modifyTextLabel4 {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 5%;
    top: -3px;
    font-size: 14px;
}

.modifyTextLabel5 {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 5%;
    top: -4px;
    font-size: 14.5px;
}

.modifyTextLabel6 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 11px;
    top: -2px;
    font-size: 14px;
}

.modifyTextLabel7 {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 6%;
    top: -8px;
}

.modifyTextLabel8 {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 10px;
    top: -9px;
    font-size: 14px;
}

.modifyTextLabelExtra {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 6%;
    top: 7px;
    font-size: 15px;
}

.HeadContentTitle {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}

.HeadContentInputBpx {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}

.MultiAlias-modal {
    width: 33%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.OpticalField-modal {
    width: 55%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    height: max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.input-box-checkbox1 {
    width: 70%;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.input-box-checkbox2 {
    width: 35%;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.input-box-checkboxItem {
    width: 30%;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.DirectFlex {
    display: flex;
}

.DirectWidth {
    width: 98%;
}

.ReverceInputBox {
    display: flex;
    flex-flow: row-reverse;
    height: 35px;
}

.ExtraInputBox {
    width: 20px;
    height: 18px;

}

.col-sm-3 {
    flex: 0 0 auto;
    width: 10%;
}

.col-sm-4 {
    flex: 0 0 auto;
    width: 10%;
}

input.col-sm-4 {
    text-align: center;
    padding-left: 0px;
}

/* ************User Authentication Css****************** */
.input-box-mo1 {
    width: 85%;
}

.search_panel_left_side {
    width: 15%;
}

.search_option {
    width: 100% !important;
}

/* .search_mode{
    padding-top: 13px;
    padding-bottom: 7px;
   } */
.table_hdo2 {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    /* height: 100%; */
    --scrollbar-thumb-bg: #888;
    --scrollbar-hover-bg: #555;
    --moz-scrollbar-bg: #fff;
}

.col-sm-no1 {
    display: flex;
    margin-bottom: 0px;
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 8px;
    width: 325px;
}

.col-sm-a12 {
    width: 50%;
    position: relative;
    min-height: 1px;
    font-size: 13px;
}

.show_main_col {
    display: flex;
    justify-content: center;
}

.user_color {
    color: red;
}

.trashIcon {
    color: red;
}

.user_color:hover {
    color: rgb(34, 34, 33);
    cursor: pointer;
}

.user-heading {
    background-color: rgb(33, 46, 79);
}

.fa-solid:hover {
    cursor: pointer;
}

.table_head_mmbo1 {
    padding-right: 5px;
    font-size: 14px;
    color: white;
}

/* ************End User Authentication Css****************** */

.btnTableIconIG {
    background-color: white;
    padding: 12px;
}

.label {
    font-size: 14px;
    color: darkgrey;
}

.btnTableIconItem {
    background-color: white;
    width: 10px;
    position: relative;
    left: 120px;
}

.modal-content_Attribute {
    background-color: #fefefe;
    margin: 6% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 12px;
    /* max-width: 80%; */
    /* height: 50%; */
}

.DeleteBtnImg {
    position: absolute;
    right: -4px;
    top: -5px;
    background-color: rgb(255 97 97);
    z-index: 2;
    /* padding: 3px; */
    height: 14px;
    width: 14px;
    font-size: 10px !important;
    color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}

.ImgDelIcon {
    font-size: 12px;
    background-color: transparent;
}

/* ************Account Master List Css****************** */
.col-sm-AML {
    display: flex;
    margin-bottom: 0px;
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 8px;
    width: 400px;
}

.AMlist_input {
    width: 120px;
}

.agemin {
    width: 60px;
}

.agemax {
    width: 50px;
}

.gender_AML {
    width: 85px;
}

.date_AML {
    width: 107px;
}


.search_panel_left_Acc_mstrlist {
    width: 60%;
    padding-top: 5px;
    padding-right: 5px;
}

.AML_icon {
    left: 1rem !important;
}

.search_panel_right_Acc_mstrlist {
    padding-top: 5px;
    padding-right: 10px;
    width: 40%;
}


/* ************End Account Master List Css****************** */

/* ************Bill Type Master For CSS****************** */
.table_head_mmbb1 {
    padding-right: 5px;
    font-size: 14px;
    text-align: center;
}

.input-box-mst1 {
    width: 100%;
}

.radio-btm-mn2 {
    width: 55%;
    display: flex;
    justify-content: space-between;
    padding-right: 128px;
    padding-left: 20px;
}

.mode_tax {
    width: 25%;
    padding-left: 8px;
}

.input-box-Bt {
    display: flex;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 10px;
    height: 40px;
    width: 100%;
    position: relative;
}

.input-box-mbt1 {
    width: 49%;
    display: flex;
    justify-content: center;
}

/* .border_bt {
    border: 1px solid #d1cdcd;
  
  } */

.region_hght {
    height: 80px;
    display: flex;
    align-items: center;
}

.region_tax {
    height: 60%;
}

.input-box-Imbt {
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 10px;
    /* height: 40px; */
    width: 100%;
    position: relative;
}

.other_hegt {
    height: 36%;
}

.service_flex {
    display: flex;
    justify-content: flex-end;
}

.mode_top {
    padding-top: 4px;
}

.radio-btm-mb2 {
    width: 48%;
    display: flex;
    justify-content: space-between;
    padding-right: 128px;
    padding-left: 20px;
}

/* ************ End Bill Type Master For CSS****************** */

/* ************User Master List****************** */
.search_panel_left_01 {
    width: 11%;
}

/* ************End User Master List****************** */

/* ************User Details****************** */
.company-info-top-left1 {
    /* border-right: 1px solid #d1cdcd; */
    justify-items: initial;
    width: 25%;
    padding: 22px;
    /* box-shadow: 2px 2px 15px rgb(212, 212, 212); */
}

.mode_category1 {
    width: 98%;
    padding-left: 8px;
}

.input-box-Imo1 {
    /* display: flex;
      justify-content: space-between; */
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 13px;
    /* height: 40px; */
    width: 100%;
    position: relative;
}

.input-box-mok1 {
    width: 100%;
    text-align: center;
}

.control_flex {
    width: 75%;
    /* display: flex; */
}

.stock-im-b1 {
    width: 33%;
}

.company_control {
    display: flex;
    padding-top: 3px;
}

.configure_radius {
    padding-bottom: 15px;
    width: 100%;
    /* height: 100%; */
    /* border: 1px solid #ccc; */
    /* margin: auto; */
    margin-top: 0px;
    border-radius: 8px;
    box-shadow: 0 0 5px 3px rgb(175 170 170 / 38%);
}

.control_radius {
    padding-bottom: 15px;
    width: 100%;
    /* height: 100%; */
    /* border: 1px solid #ccc; */
    /* margin: auto; */
    margin-top: 0px;
    border-radius: 8px;

}

.tab-content-02 {
    width: 100%;
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.item_scroll {
    height: 90vh;
    overflow: auto;
}

.item_scroll2 {

    overflow: auto;
}

.user_add {
    display: flex;
}

.plus_user {
    padding-top: 10px;
}


/* ******modify******* */

.custom-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.custom-dropdown button {
    padding: 10px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
}

.custom-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: none;
}

.custom-dropdown ul li {
    padding: 10px;
}

.custom-dropdown label {
    display: block;
}

.custom-dropdown input {
    margin-right: 5px;
}

.custom-dropdown:hover ul {
    display: block;
}


/* ******modify******* */




/* ************End User Details****************** */


/* ************Department Category Master Css****************** */
.control_flex_m1 {
    width: 100%;
    /* display: flex; */
}

.stock-im-dep1 {
    width: 50%;
}

.search_panel_fo1 {
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
}

.col_do1 {
    display: flex;
    width: 100%;
    padding-top: 5px;
    justify-content: center;
}

/* .depart_top {
    margin-top: 15px;
} */

.table_tbody_it1 {
    border-top: 1px solid #e3e1e1;
    height: 100px;
}

.table_tbody_it1:hover {
    background-color: #88c3b454;
}

.table_head_nnbm1 {
    display: flex;
    width: 100px;
    justify-content: center;
}

.table_hdo1 {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    /* height: 100%; */
    --scrollbar-thumb-bg: #888;
    --scrollbar-hover-bg: #555;
    --moz-scrollbar-bg: #fff;
}

.item_text {
    font-weight: 600;
    font-size: large;
}

.search_panel_ff2 {
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
}

.modal-content_ud {
    background-color: #fefefe;
    margin: 4% auto;
    padding: 10px;
    border: 1px solid #888;
    width: 80%;
    max-width: 80%;
}

/* ************ End Department Category Master Css****************** */

/* ************Department Details Css****************** */
.control_radius01 {
    width: 100%;
    /* height: 100%; */
    /* border: 1px solid #ccc; */
    /* margin: auto; */
    margin-top: 0px;
    border-radius: 8px;
    box-shadow: 0 0 5px 3px rgb(175 170 170 / 38%);
}

.depart_top01 {
    margin-top: 15px;
    padding-top: 5px;
}

.word_center {
    width: 25%;
}

.word_center1 {
    width: 30%;
}



/* ************ End Department Details Css****************** */

/* ************ Login Master Details Css****************** */
.mode_reset {
    margin-left: 10px;
}

.stock-im-depo1 {
    width: 98%;
}

.pic_mode {
    margin-top: 35px;
}

/* ************End  Login Master Details Css****************** */

/* ************Account Master Css****************** */
.frame_mode {
    width: 100%;
}

.item_scrollbar {
    height: 390px;
}

.item_scrollbar01 {
    height: 295px;
}

.left-container01 {
    display: none;
}




/* ************ End Account Master Css****************** */


/* ************User Authentication Css****************** */
.input-box-mo1 {
    width: 85%;
}

.search_panel_left_side {
    width: 15%;
}

.search_option {
    width: 100% !important;
}


.table_hdo2 {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    /* height: 100%; */
    --scrollbar-thumb-bg: #888;
    --scrollbar-hover-bg: #555;
    --moz-scrollbar-bg: #fff;
}

.col-sm-no1 {
    display: flex;
    margin-bottom: 0px;
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 8px;
    width: 325px;
}

.col-sm-a12 {
    width: 50%;
    position: relative;
    min-height: 1px;
    font-size: 13px;
}

.show_main_col {
    display: flex;
    justify-content: center;
}

.user_color {
    color: red;
}

.user_color:hover {
    color: rgb(34, 34, 33);
    cursor: pointer;
}

.user-heading {
    background-color: rgb(33, 46, 79);
}

.table_head_mmbo1 {
    padding-right: 5px;
    font-size: 14px;
    color: white;
}

/* ************End User Authentication Css****************** */

/* ************User Details check box Css****************** */

.multipleSelection {
    position: relative;
    display: inline-block;
}

.selectBox {
    position: relative;
    cursor: pointer;
}

.selectBox select {
    width: 150px;
    padding: 8px;
    border: 1px solid #ccc;
}

.overSelect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#checkBoxes {
    display: block;
    position: absolute;
    /* top: 0; */
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* max-height: 120px; */
    width: 98%;
    overflow: auto;
    z-index: 1;
}

#checkBoxesBusiness {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 120px;
    width: 98%;
    overflow: auto;
}

#checkBoxes label {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

#checkBoxes label:hover {
    background-color: #f2f2f2;
}

#checkBoxes label input:checked {
    background-color: #007bff;
    color: #fff;
    box-shadow: none !important;
}

#checkBoxesBusiness label {
    display: block;
    padding: 5px;
    cursor: pointer;
}

#checkBoxesBusiness label:hover {
    background-color: #f2f2f2;
}

#checkBoxesBusiness label input:checked {
    background-color: #007bff;
    color: #fff;
    box-shadow: none !important;
}

.user_checkbox {
    margin-right: 10px;
}

.selected-options {
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
}

/* ************End User Details check box Css****************** */



/* ************Delivery Incentive Css****************** */
.fa-solid.fa-square-check:hover {
    color: #1702d9;
}

/* ************End Delivery Incentive Css****************** */

/* ************XLS Css****************** */

.upload_btn {
    border: 1px solid gray;
    color: black;
    border-radius: 5px;
    font-size: 15px;
    width: 110px;
    height: 35px;
}

.upload_btn:hover {
    background-color: #dcd4d4;
    transition: all 0.2s ease-out;
}

.stock-im-xls {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: center;
    padding-top: 4px;
}

.upload_flex_xls {
    display: flex;
    justify-content: center;
    padding-top: 4px;
}

.xls_top {
    margin-top: 15px;
    text-align: center;
}

.xls_radius {
    margin-top: 11px !important;
}

.company_control_xls {
    display: flex;
    height: 30px;
}

.modal-content_xls {
    background-color: #fefefe;
    margin: 4% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 83%;
    max-width: 83%;
}

.excel_xls {
    margin-right: 10px;
}


/* ************End XLS Css****************** */

/* ************Vehicle Master Css****************** */
.modal_vehicle_Master {
    background-color: #fefefe;
    margin: 4% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 65%;
    max-width: 65%;
}

/* ************End Vehicle Master Css****************** */

/* ************New Table Css****************** */
.cfvlby {
    display: flex;
    align-items: stretch;
    width: 100%;
    background-color: #4653e7 !important;
    min-height: 35px !important;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    border-bottom-style: solid;
}

.jiAnER {
    min-height: 35px !important;
}

.TableDelIcon {
    cursor: pointer;
    font-size: 14px;
    padding-left: 10px;
}

.TableDelIcon:hover {
    color: red;
}

.TableEditIcon {
    cursor: pointer;
    font-size: 14px;
    padding-left: 5px
}

.TableEditIcon:hover {
    color: blue;
}

.iedScr {
    display: flex;
    width: 100%;
    color: white !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.tableOP {
    display: flex;
}

.TableDelClass {
    display: flex;
    justify-content: flex-end;
}

.CentreSide {
    display: flex;
    justify-content: flex-end;
}

.TableDelIcon {
    cursor: pointer;
    font-size: 15px;
    padding-left: 10px;
    color: #f10000;
    transition: all;
    transition-duration: 100ms;
}

.TableDelIcon:hover {
    scale: 1.2;
}

.TableWhatsappcon {
    cursor: pointer;
    font-size: 18px;
    padding-left: 10px;
    margin-top: -5px;
    color: #0abd0a;
    transition: all;
    transition-duration: 100ms;
    margin: auto 0;
}

.TableWhatsappcon:hover {
    scale: 1.2;
}

.TablePrint {
    cursor: pointer;
    font-size: 14px;
    padding-left: 8px;
    margin-top: 0px;
    transition: all;
    transition-duration: 100ms;
    color: #1392c3;
}

.TablePrint:hover {
    scale: 1.2;
}

.TableEditIcon {
    cursor: pointer;
    font-size: 14px;
    padding-left: 5px;
    transition: all;
    transition-duration: 100ms;
    color: black;
}

.TableEditIcon:hover {
    scale: 1.2;
}

/* *************************{css}******************************* */

/* body {
    background: #fff;
    font-family: 'Calibri', sans-serif !important;
    color: #4e4e4e;
    line-height: 22px;
} */


.EWayDiv {
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: #f3f7ff;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
}

.icon {
    font-size: 12px;
    color: black;
    padding: 7px;
    background-color: #f3f7ff;
    border-radius: 3px;
    border: 1px solid lightgrey;
}

.save_btn {
    padding: 5px;
    background-color: #2d1d9f;
    color: white;
}

.marginTop-1px {
    margin-top: 1px;
}

/* *************************{css End}******************************* */

.UseMasterLabelFix {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 7%;
    top: -12px;
    font-size: 14px;
}

.ItemMasterLabelFix {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 7%;
    top: -3px;
    font-size: 14px;
}

/* ****************{scroll Bar Hidden}*********************** */

.item_scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.item_scroll {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* ****************{scroll Bar Hidden End}*********************** */

.AccountMasterNameLabel {
    position: absolute;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 122px;
    top: -3px;
    font-size: 14px;
}

/* *************************{Top Save Button}******************************** */

.TopSaveBtn {
    margin-left: -6%;
}

.TopResetBtn {
    margin-left: -4.5%;
}

.TopSettingBtn {
    margin-left: -5%;
}

.TopMemberCardBtn {
    margin-left: -8.5%;
}

.TopOptionalFieldBtn {
    margin-left: -8.5%;
}

.button-box-main {
    display: flex;
    width: 600px;
    justify-content: space-evenly;
}

.hover-textSave {
    position: absolute;
    z-index: 1;
    background-color: #2b2725c9;
    color: white;
    padding: 4px 8px;
    /* right: 6%; */
    top: 42px;
    border: 1px solid #505050;
    border-radius: 8px;
    margin-top: 5px;
    margin-left: 10px;
    display: flex;
}

.hoverSmallButton {
    position: absolute;
    z-index: 1;
    background-color: #2b2725c9;
    color: white;
    padding: 4px 8px;
    /* right: 6%; */
    top: 42px;
    border: 1px solid #505050;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 25px;
    display: flex;
}


.ItemIconHover {
    position: absolute;
    top: -8px;
    right: 38px;
}

#SaveBtnMain:hover+.hover-textSave {
    display: block;
}

.hover-textSave:hover {
    display: block;
}

.save-button-main {
    width: max-content;
    background: lavender;
    padding: 5px 8px;
    border: none;
    color: black;
    border-radius: 5px;
    font-weight: 100;
    transition: all;
    transition-duration: 300ms;
    font-size: 14px;
    cursor: pointer;
}

.save-button-main-small {
    width: max-content;
    padding: 5px 8px;
    background: #212e4f;
    border: none;
    display: flex;
    color: #ffffff;
    border-radius: 15px;
    font-weight: 100;
    transition: all;
    transition-duration: 300ms;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Tablebutton5 {
    display: flex;
    padding-left: 8px;
    /* padding-right: 15px; */
    text-decoration: none;
}

.save-button-main-save {
    width: max-content;
    background: #ff9900;
    padding: 5px 7px;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: 100;
    width: 90px;
    transition: all;
    transition-duration: 300ms;
    font-size: 14px;
}

.save-button-main-save:hover {
    background: #ff9900;
    color: white;
    cursor: pointer;
}

.save-button-main:hover {
    background: #975cb3;
    color: white;
    cursor: pointer;
}

.save-button-main-small:hover {
    background: #975cb3;
    color: white;
    cursor: pointer;
}

.save-button-main:hover i {
    color: white;

}

.im-icon-3 {
    padding-left: 10px;
    font-size: 15px !important;
    margin-bottom: 3px;
    cursor: pointer;
    /* color: #f3f3f3; */
}

/* ************************{Product List Modal Box}*************************** */

.ModalBoxWidthFix {
    width: 75px;
    border-radius: 4px;
    /* position: absolute; */
    color: white;
    padding-left: 6px;
    border: 1px solid #2b2829 !important;
    background-color: #6e6d6d;
}

.ModalBoxInputM5 {
    width: 26%;
    display: flex;
    justify-content: space-between;
    height: 33px;
}

.ModalBoxCullomLeft {
    width: 50%;
}

.ModalboxTitleMain {
    width: 15%;
}

.ModalboxTitleSecond {
    width: 40%;
    padding-left: 0px !important;
}

.ModalboxTitleInput {
    width: 30%;
    padding-left: 7%;
    padding-bottom: 29px;
}

.modify_p_text_ProductModal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalLabeltext2 {
    position: absolute;
    color: #ffffff;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -8px;
    font-size: 14px;
    /* color: black; */
}

.modalContentTable {
    background-color: #fff;
    margin: 6% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 85%;
    max-width: 660px;
}

.modalTableTitleColor {
    color: #595959;
    font-size: 19px;
    padding-left: 25px;
    font-weight: 600;
}

.ItemGeneralSettingCol {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.modify_lbl_textage {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 30%;
    top: -9px;
    font-size: 14px;
}

.HeadContentItemgroup {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
}

/*************Add New Item Css****************** */
.table-th-serialno {
    width: 15% !important;
    margin-right: 5px;
}

.mb_3_itemname {
    width: 500px;
    text-align: center;
}

.mb_4_groupname {
    width: 300px;
    text-align: center;
}

.mb_5_unitname {
    width: 250px;
    text-align: center;
}

.pd_1_txtcenter {
    padding-left: 4px;
    font-size: 14px;
    text-align: center;
}

.clear_row {
    padding-top: 30px !important;
}

.take_time {
    padding-top: 8px;
    font-weight: 600;
}

.Newitem_add {
    display: flex;
    justify-content: flex-end;
}

.AddNewItem_Input_Box {
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 20px;
    height: 40px;
    position: relative;
}

.input-heightAddItem {
    height: 30px;
    padding-top: 5px;
}

.modify_AddNewItem_checkbox {
    padding-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.serialno_icon {
    display: flex;
    justify-content: space-between;
}

.Remove_Icon {
    color: red;
    margin-left: 5px;
}

.Copy_Icon {
    color: blue;
    margin-left: 5px;
}

.Copy_Icon:hover {
    color: rgb(224, 171, 171);
}

.Paste_Icon {
    color: rgb(3, 24, 32);
    margin-left: 5px;
    margin-right: 10px;
}

.Remove_Icon:hover {
    color: black;
    /* transition: all 0.2s ease-out; */
}

.Paste_Icon:hover {
    color: rgb(84, 238, 212);
}

.AddNewItem-modal {
    width: 23%;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
}

.close_modal {
    position: absolute;
    color: #aaaaaa;
    float: right;
    font-size: 24px;
    top: 3px;
    font-weight: bold;
    cursor: pointer;
    right: 21px;
}

.close_modal:hover {

    color: red;
}

.close_modal-attribute:hover {

    color: red;
}

.close_modal-category:hover {

    color: red;
}

.tab-content-modal {
    width: 100%;
    margin-top: 30px;
    display: flex;
}

.company-info-top-modal {
    /* width: 50%; */
    padding: 10px;
}

.control_flex_modal {
    width: 98%;
    /* display: flex; */
}

.AddNewItem_scroll::-webkit-scrollbar {
    display: none;
}

.AddNewItem_scroll::-webkit-scrollbar {
    scrollbar-width: none;
}

.input-box-NewItem {
    width: 15%;
}

.modify_Item_checkbox {
    width: 14px;
}

/* .border_barcode{
    border: 1px solid #e7dede;
  } */

.table-input-Barcode {
    box-shadow: none !important;
    width: 100%;
    margin: auto;
    height: 30px;
    border-radius: 1px !important;
    border: 1px solid #e7dede;
}

/*************End Add New Item Css****************** */

.TabCOlorWhite {
    color: white;
}

.favoriteIcon {
    color: red;
    display: flex;
    padding-top: 5px;
    position: relative;
    left: -19px;
    top: 3px;
    cursor: pointer;
}

.InvoiceLensSettingicon {
    position: absolute;
    left: 10px;
    font-size: 15px;
    top: 8px;
}

.InvoiceLensDisplay {
    display: flex;
}

.cont_prins {
    width: 163px !important;
}

.apply_check {
    width: 150px !important;
}

.item_increase {
    width: 100% !important;
    height: 100% !important;
}

.prs-ad {
    margin-left: 3px;
}

.AR-Prs {
    margin-left: 6px;
}

.IM-Prs {
    margin-left: 8px;
}

.labelEffectFixCode {
    position: absolute;
    top: -12px;
    left: 10px;
    transition: top 0.2s, font-size 0.2s;
    pointer-events: none;
    background-color: white;
    color: #7e7c7c !important;
    /* top: -10px !important; */
    font-size: 12px;
    /* font-weight: bold; */
}

.PageActiveFlex {
    display: flex;
}

/* Tooltip.css */

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1;
    display: inline-block;
}

/* .LensOverflow {
    overflow: scroll;
    height: 161px;
} */

/* **********************{Scroll Bar Hidden}****************************** */

.ItemGroup-modal::-webkit-scrollbar {
    display: none;
}

.overflowY::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar {
    display: none;
}

.container_over::-webkit-scrollbar {
    /* display: none; */
}

.tableOverflowItemGroup::-webkit-scrollbar {
    display: none;
}

.item_scroll::-webkit-scrollbar {
    display: none;
}

.AddNewItem_scroll::-webkit-scrollbar {
    display: none;
}

.AddNewItem_scroll::-webkit-scrollbar {
    scrollbar-width: none;
}

.LensOverflow::-webkit-scrollbar {
    display: none;
}

#checkBoxes::-webkit-scrollbar {
    display: none;
}

#checkBoxes {
    scrollbar-width: none;
}

.BackSpace {
    color: black;
    font-size: 15px;
    position: sticky;
    padding-left: 98%;
}

.im-IconRight {
    font-size: 12px;
    margin-bottom: 3px;
    color: black;
    padding-right: 7px;
}

.IconFontS1 {
    font-size: 16px;
}

.StarClr {
    color: red;
}

.AddressAreaInputHead {
    display: flex;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 2px;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
}

.AddressTextPin {
    left: 78.5%;
    position: absolute;
    color: #9c9ea1 !important;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    top: -9px;
    font-size: 14px;
}

.AddressTextState {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 36%;
    top: -11px;
    font-size: 15px;
}

.AddressContentBoxM1 {
    width: 32%;
}

.AddressContentBoxM2 {
    width: 45%;
}

.AddressContentBoxM3 {
    width: 24%;
}

.OptionalHeadTop {
    display: flex;
    padding: 5px 0px;
}

.AddressTextCountry {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 4%;
    top: -9px;
    font-size: 14px;
}

.AddressTextCountryAddress {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 3%;
    top: -12px;
    font-size: 14px;
    line-height: 20px;

}

.AddressTextInputBox {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    height: 28px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
    MARGIN-BOTTOM: 2PX;
    box-shadow: none !important;
    padding-left: 7px;
}

.AddressAreaInputHeadTop {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 2px;
    width: 100%;
    position: relative;
}

.LogoDeleteIcon {
    position: absolute;
    right: 26px;
    top: 0px;
    background-color: rgb(255 97 97);
    z-index: 2;
    padding: 3px;
    height: 13px;
    color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 10px;
}

/* ***********************{MasterSetting css}*********************************** */

.tabs {
    background-color: #e1effd;
    justify-content: space-between;
}

.tab {
    width: max-content !important;
    padding: 0px 25px;
    font-weight: 600;
    font-size: 13px;
}

.tab.active {
    font-weight: 600;
    background-color: #97660a;
    border: 1px solid #97660a;
    border-radius: 0px;
}

.EWayDiv {
    border: 1px solid lightgrey;
    border-radius: 0px;
    background-color: #f3f7ff;
    width: 100%;
    margin: auto;
    padding: 10px;
    margin-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.icon {
    font-size: 12px;
    color: black;
    padding: 7px;
    background-color: #f3f7ff;
    border-radius: 3px;
    border: 1px solid lightgrey;
}

.save_btn {
    padding: 5px;
    background-color: #2d1d9f;
    color: white;
}

.marginTop-1px {
    margin-top: 1px;
}

/* ***********************{MasterSetting css End}*********************************** */










/* ********************{Log-In}********************* */

.content i {
    margin-bottom: 10px;
}

.containerLogIn {
    border-radius: 1px;
    padding: 50px 40px 20px 40px;
    box-sizing: border-box;
    font-family: sans-serif;
    color: #737373;
    border: 1px solid rgb(219, 219, 219);
    text-align: center;
    background: white;
}

.containerLogIn svg {
    width: 16px;
    height: auto;
}

.content__form {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
}

.content__inputs {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.content__form label {
    border: 1px solid rgb(219, 219, 219);
    display: flex;
    align-items: center;
    position: relative;
    min-width: 268px;
    height: 38px;
    background: rgb(250, 250, 250);
    border-radius: 3px;
}

.content__form span {
    position: absolute;
    text-overflow: ellipsis;
    transform-origin: left;
    font-size: 12px;
    left: 8px;
    pointer-events: none;
    transition: transform ease-out 0.1s;
}

.content__form input {
    width: 100%;
    background: inherit;
    border: 0;
    outline: none;
    padding: 9px 8px 7px 8px;
    text-overflow: ellipsis;
    font-size: 16px;
    vertical-align: middle;
}

.content__form input:valid+span {
    transform: scale(calc(10 / 12)) translateY(-10px);
}

.content__form input:valid {
    padding: 14px 0 2px 8px;
    font-size: 12px;
}

.content__or-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    column-gap: 18px;
    margin-top: 18px;
}

.content__or-text span:nth-child(3),
.content__or-text span:nth-child(1) {
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgb(219, 219, 219);
}

.content__forgot-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    row-gap: 21px;
}

.content__forgot-buttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #00376b;
}

.content__forgot-buttons button:first-child {
    color: #385185;
    font-size: 14px;
    font-weight: 600;
}

.content__form button {
    background: rgb(0, 149, 246);
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    padding: 7px 16px;
    cursor: pointer;
}

.content__form button:hover {
    background: rgb(24, 119, 242);
}

.content__form button:active:not(:hover) {
    background: rgb(0, 149, 246);
    opacity: 0.7;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.userLeft1 {
    left: 6% !important;
}

.MargeTwoInput {
    display: flex;
}

.FixEndH {
    left: 53%;
}

.mt-4 {
    width: 100%;
}

/* 
  .tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3em 0.6em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    background: var(--background);
    z-index: -1;
    border-radius: 8px;
    scale: 0;
    transform-origin: 0 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  }
  
  .tooltip::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    bottom: -0.2em;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background: var(--background);
  }
  .input-box-m11:hover .tooltip {
    top: -100%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    scale: 1;
    animation: shake 0.5s ease-in-out both;
  }
   */

.CompWidthMaster {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 13px;
    position: relative;
}

.CompWidthMasterUser {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 13px;
    width: 33%;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    /* Use absolute positioning to overlay the content */
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.input-box-mok1:hover .dropdown-content {
    display: block;
    /* Show the content when hovering over .input-box-mok1 */
}

/* ************************{Scroll bar Hide}*************************** */
#checkBoxes::-webkit-scrollbar {
    display: none;
}

/* For Firefox */
#checkBoxes {
    scrollbar-width: none;
}

/* ************************{Scroll bar Hide}*************************** */

/*************Add Voucher List Css****************** */

.select_voucher_add {
    outline: none !important;
    border: 1.5px solid #e7dede;
    box-shadow: 1px 1px 1px #aba69c;
}

.content-addvoucher-height {
    height: 80px;
    padding: 5px;
}

.Account_Vouch {
    width: 45%;
    text-align: center;
}

.debit_width {
    width: 12%;
    justify-content: center;
}

.debit_width2 {
    width: 99%;
    justify-content: center;
}

.debit_width_remark {
    width: 37%;
    justify-content: center;
}

.Addvoucher-label {
    display: flex;
    justify-content: flex-end;
}

.tab-width-add {
    width: 100%;
}

.Gst-body-tax {
    margin-left: 15px;
}

.input-heightAddVoucher {
    height: 15px;
    padding-top: 5px;
}

.remark-margin-rgt {
    margin-right: 40px;
}

.remark-padding {
    padding: 2px;
}

.gst-table-width {
    width: 50%;
}

.margin-debit {
    margin-right: 10px;
}

.margin-credit {
    margin-right: 53px;
}

.Table-GST-left {
    width: 42%;
    margin-left: 15px;
    border: 1px solid #cccccc;
}

.Bill-AMT-Right {
    width: 57%;
}

.table-down-AddVoucher {
    display: flex;
    justify-content: flex-end;
    /* padding-right: 40px; */
}

.Reff-Series {
    border: none;
    width: 40%;
    margin: 5px 10px;
}



/*************Damage And Shrinkage Css****************** */

.flex-end-gross {
    display: flex;
    justify-content: flex-end;
}

.dmg-width {
    width: 25%;
}

.shrink_width {
    width: 53%;
    justify-content: center;
}

/*************API Configuration Master Css****************** */

.input_height_Api {
    display: flex;
}

.input_Api_width {
    width: 96%;
}

.input_Apivalue_width {
    width: 96%;
}

.Api-flex-end {
    display: flex;
    justify-content: flex-end;
}

.parameter-margin-left {
    margin-left: 15px;
}

.hd-rd-bottom {
    padding-bottom: 3px;
}

/*************SMS Template  Css****************** */

.Template-margin-top {
    margin-top: 25px;
}

.Sms-Template-box {
    min-height: 200px;

}

.sms-header-box {
    min-height: 80px;
}

.Sms-Text-Box {
    padding-left: 13px;
    padding-right: 5px;
}

.td_width_sms {
    width: 8%;
}

.Sms_Save {
    /* border: 1px solid #ddd;
    padding: 8px; */
    background-color: white;
    width: 67px;
}

.SaveBtn_Sms {
    color: white;
    background-color: #5e709e;
    border: none;
    padding: 3px;
    border-radius: 10px;
    width: 80%;
    height: 35px;
    font-size: 14px;
}

.AddBtn_Sms {
    color: white;
    background-color: #5e709e;
    border: none;
    padding: 3px;
    font-size: 14px;
    border-radius: 10px;
    width: 80%;
    height: 35px;
}

/*************Salt Css****************** */

.More_info_check {
    padding-left: 5px;
    margin-top: 3px;
}

.Flex-Start-Moreinfo {
    display: flex;
    justify-content: flex-start;
}

.TextTooltip1 {
    padding-top: 5px;
    color: #505050;
    font-size: 16px;

}


.tableList-main {
    width: 99%;
    margin: auto;
    margin-top: 5px;
    border: 1px solid #dddddd;
}


.table-head-main {
    height: 30px;
    background-color: #212e4f;
    color: white;
}

.tableList-main th {
    text-align: center;

}

.tableList-main td {
    text-align: center;
    border: 1px solid #dddddd;
}


.tablelist-main-search {
    padding-left: 10px;
    margin-bottom: 8px;
    border-radius: 5px;
    margin-top: 8px;
    box-shadow: none;
    width: 85%;
    margin: 5px 0px;
}

#header-0-0 {
    width: 50px;
}

.tablelist-input-box {
    background-color: #e1effd;
}


.table-list-button-box {
    align-items: flex-end;
    background-color: #e1effd;
    margin: 0 8px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    border-radius: 8px;
    flex-direction: column;
    /* position: fixed; */
    /* bottom: 2px; */
    /* width: 90%; */
}

.table-list-button {
    border: 0;
    width: 20px;
    font-size: 10px !important;
    height: 20px;
    background-color: #212e4f;
    color: white;
    border-radius: 50px;
    margin: 0 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-list-page-txt {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.tablelist-main-search-button {
    border: 0;
    font-weight: 600;
    background-color: #53699f;
    width: 150px;
    box-shadow: 1px 1px 5px #bfbfbf;
    border-radius: 5px;
    color: white;
}

.tablelist-action-box {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 85%;
    margin: 0px auto;
}


.table-body-main tr:hover {
    /* background-color: #dddddd; */
    background-color: #e1effd !important;
}


.tablelist-datefilter-box {
    position: relative;
}

.tablelist-datefilter {
    position: absolute;
    width: 330px;
    height: 200px;
    background-color: #ededed;
    left: -25%;
    border-radius: 5px;
    top: 35px;
    box-shadow: 0px 0px 5px #cdcdcd;
    /* display: none; */
}

.tablelist-datefilter-1 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    justify-content: space-around;
}

.tablelist-datefilter-option {
    width: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #dddddd;
    height: 25px;
    margin-top: 5px;
    padding-left: 10px;
    border-radius: 3px;
    background-color: white;
    font-size: 12px;
    cursor: pointer;
}

.tablelist-datefilter-option-selected {
    background-color: #e1effd;
    scale: 1.05;
    font-size: 13px;
}


.tablelist-datefilter-2 {
    padding: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.tablelist-datefilter-date {
    display: flex;
    justify-content: space-between;
    /* width: ; */
}

.tablelist-datefilter-date input {
    width: 45%;
    padding: 2px 7px;
    border-radius: 5px;
}

.tablelist-datefilter-button {
    float: right;
    margin-right: 20px;
    border: none;
    background-color: #53699f;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    width: 90px;
    transition: all;
    transition-duration: 200ms;
}

.tablelist-datefilter-button:hover {
    box-shadow: 3px 3px 20px #efefef;
}

.shortcutkeys-header {
    width: 100%;
    display: flex;
    color: black;
    height: 30px;
    align-items: center;
    justify-content: space-around;
    color: black;
    border-bottom: 1px solid white;
    margin: 5px 0px;
}

.shortcutkeys-header P {
    margin: 0 0px;
    font-size: 12px;
    font-weight: 600;
}

.slider-container100 {
    position: fixed;
    top: 0;
    right: -97%;
    overflow: scroll;
    height: 100vh;
    width: 97%;
    /* background-color: #f8f8ff; */
    background-color: white;
    border: 2px solid grey;
    border-radius: 20px;
    transition: right 0.3s ease-in-out;
    z-index: 5;
}

.slider-container100.open {
    right: 0;
}

.InputOptionalFieldrap {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}

.ItemTool2 {
    position: absolute;
    top: 7px;
    left: 157px;
}

.TextTooltipWhite {
    padding-top: 5px;
    color: #ffffff;
    font-size: 16px;
}

.ItemTool4 {
    position: absolute;
    top: 7px;
    left: 205px;
}

/* ********************** Alert Box ************************ */

.alert-card {
    width: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 0.5rem;
    background: #606c88;
    background: linear-gradient(to right top, #3f4c6b, #606c88);
    padding: 20px 25px 10px 25px;
    color: rgb(107, 114, 128);
    box-shadow: 0px 87px 78px -39px rgba(0, 0, 0, 0.4);
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.alert-card-error {
    width: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 0.5rem;
    background: rgb(240 240 240);
    padding: 20px 25px 10px 25px;
    color: rgb(107, 114, 128);
    box-shadow: 0px 87px 78px -39px rgba(0, 0, 0, 0.4);
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.content {
    /* margin-left: 0.75rem; */
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    text-align: center;
}

.alert-title {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    cursor: default;
}

.alert-title-error {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #4f4f4f;
    cursor: default;
}

.desc {
    margin-bottom: 0.6rem;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: rgb(202, 200, 200);
    cursor: default;
}

.desc-error {
    margin-bottom: 0.6rem;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #707070;
    cursor: default;
}

.actions {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.action {
    display: flex;
    justify-content: center;
}

.download,
.notnow {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.375rem 0.5rem;
    text-align: center;
    font-size: 14px;
    line-height: 1rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    outline: 2px solid transparent;
    border: 1px solid rgba(253, 253, 253, 0.363);
}

.download {
    background-color: #284385;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.18, 0.69, 0.18, 0.94);
}

.download:hover {
    background-color: white;
}

.download-error,
.notnow {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.375rem 0.5rem;
    text-align: center;
    font-size: 14px;
    line-height: 1rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    outline: 2px solid transparent;
    border: 1px solid rgba(253, 253, 253, 0.363);
}

.download-error {
    background-color: #a40000;
    font-weight: 600;

    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.18, 0.69, 0.18, 0.94);
}

.download-error:hover {
    background-color: #650000;
}


.notnow {
    background-color: #606c88;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.18, 0.69, 0.18, 0.94);
}

.notnow:hover {
    background-color: #3f4c6b;
}

.alert-close {
    margin: -0.375rem -0.375rem -0.375rem auto;
    right: 13px;
    width: 100%;
    height: 22px;
    position: absolute;
    width: 22px;
    display: inline-flex;
    border-radius: 0.5rem;
    background-color: #606c88;
    padding: 3px;
    color: rgba(255, 255, 255, 1);
    border: none;
    transition: all 0.25s cubic-bezier(0.18, 0.69, 0.18, 0.94);
    cursor: pointer;
    top: 10px;
}

.alert-close:hover {
    background-color: #3f4c6b;
}



.alert-close-error {
    margin: -0.375rem -0.375rem -0.375rem auto;
    right: 13px;
    width: 100%;
    height: 22px;
    position: absolute;
    width: 22px;
    display: inline-flex;
    border-radius: 0.5rem;
    background-color: rgb(240 240 240);
    padding: 3px;
    color: rgb(0 0 0);
    border: none;
    transition: all 0.25s cubic-bezier(0.18, 0.69, 0.18, 0.94);
    cursor: pointer;
    top: 10px;
}




.close svg {
    height: 1.25rem;
    width: 1.25rem;
}

/* ********************** Loader ************************ */



.loader {
    position: relative;
    width: 54px;
    height: 54px;
    border-radius: 10px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader div {
    width: 8%;
    height: 24%;
    background: white;
    position: absolute;
    left: 50%;
    top: 30%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    animation: fade458 1s linear infinite;
}

@keyframes fade458 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
}

.loader .bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
}

.loader .bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -1.1s;
}

.loader .bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -1s;
}

.loader .bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.9s;
}

.loader .bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.8s;
}

.loader .bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.7s;
}

.loader .bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.6s;
}

.loader .bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.5s;
}

.loader .bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.4s;
}

.loader .bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.3s;
}

.loader .bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.2s;
}

.loader .bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.1s;
}

.tablelist-input-box input {
    width: 75%;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #c2cce7 !important;
}

.tablelist-input-box select {
    width: 130px;
    padding: 3px 8px;
    border-radius: 5px;
    border: 1px solid #c2cce7 !important;
    color: #626262;
}

/*************Recipe Management Css****************** */

.container-Recipe {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    height: 98vh;
}

.container_main-RcpMgmt {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* margin-top: 10px; */
    padding-left: 13px;
}

.container_Recipe-Management {
    border-radius: 10px;
    width: 99%;
    border: 2px solid #e5efff;
    height: 100%;
    text-align: initial;
    background: white;
    margin-right: -8%;
    /* overflow-x:  hidden ;
  overflow-y: scroll; */
}

.Recipe_right_top {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    padding: 10px;
    position: fixed;
    /* border-bottom: 1px solid #e5efff; */
    background-color: white;
    z-index: 1;
}

.Recipe-button-main {
    /* width: 120px; */
    height: 35px;
    background: rgb(231, 231, 231);
    padding: 5px 10px;
    border: 1px solid grey;
    color: black;
    border-radius: 8px;
    font-weight: 500;
    transition: all;
    transition-duration: 300ms;
}

.NewRecipe-button-main-search {
    /* width: 145px; */
    height: 35px;
    background: #d60f0f;
    padding: 5px 10px;
    border: none;
    color: white;
    border-radius: 8px;
    font-weight: 500;
    transition: all;
    transition-duration: 300ms;
}

.ItemRecipe-button-main-multiple {
    /* width: 175px; */
    height: 35px;
    background: rgb(231, 231, 231);
    padding: 5px 10px;
    border: 1px solid grey;
    color: black;
    border-radius: 8px;
    font-weight: 500;
    transition: all;
    transition-duration: 300ms;
}

.NewRecipe-button-main-search:hover {
    background: #ff9900;
    color: white;
    cursor: pointer;
}

.Recipe-button-main:hover {
    background: #975cb3;
    color: white;
    cursor: pointer;
    scale: 1.05;
    box-shadow: 2px 4px 6px rgb(202, 202, 202);
}

.ItemRecipe-button-main-multiple:hover {
    background: #975cb3;
    color: white;
    cursor: pointer;
    scale: 1.05;
    box-shadow: 2px 4px 6px rgb(202, 202, 202);
}

.Recipe-button-main:hover i {
    color: white;
}

.ItemRecipe-button-main-multiple:hover i {
    color: white;
}

.Recipe-flex-start {
    display: flex;
    justify-content: flex-start;
    /* margin-top: 10px; */
}



.Recipe_Select_Item {
    /* padding-top: 67px; */
    display: flex;
    flex-direction: column;
    /* background-color: #e5e5e5; */
    background-color: #e1effd;
    margin-top: 1rem !important;
    padding-bottom: 5px;
    border-radius: 10px;
    padding-top: 17px;
}

.mode_Recipe {
    width: 50%;
    padding-left: 8px;
}

.stock-recipe-MGM {
    width: 100%;
    display: flex;
}

.input-flex-recipe {
    display: flex;
    justify-content: flex-start;
    padding-right: 0px;
}

.recipe_bgcolor {
    background-color: #ffffff;
    /* background-color: #eaeaea; */
}

.table_hd_recipe {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    /* height: 100%; */
    --scrollbar-thumb-bg: #888;
    --scrollbar-hover-bg: #555;
    --moz-scrollbar-bg: #fff;
}

.td_col_Recipe {
    /* padding-left: 12px; */
    font-size: 14px;
    text-align: center;
}

.table-border_RCP {
    border: 0px solid #ddd;
}

.table_Action {
    width: 10%;
}

.thead_scroll_Recipe {
    height: 30px;
}

.table_head_RCP_MGM {
    display: flex;
    justify-content: center;
}

.table_tbody_tr_RCPMG {
    height: 35px;
}

.table-tbody_RecipeMgm {
    box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, .1) !important;
    position: relative !important;
    /* background-color: #5e709e; */
    background-color: #212e4f;
    color: #ffffff;
}

.thead_scroll_RecipeManage {
    height: 30px;
}

.table_tbody_Recipe {
    border-top: 1px solid #e3e1e1;
    height: 45px;
}

.modified_flex {
    display: flex;
    justify-content: center;
}

.Action_Buttons {
    display: flex;
    justify-content: center;
    padding-top: 8px;
}

.modified_top {
    margin-top: 15px;
}

.delete_btn_RCP {
    margin: auto;
    border: none;
    padding-top: 4px;
}

.Recipes_Text {
    padding-top: 15px;
    padding-left: 10px;
    font-size: 15px;
    color: #48a2a2;
}

.border_hide {
    outline: none;
    border: none;
    border-bottom: 1px solid #cfcfcf;
}

.Recipe_footer {
    display: flex;
    justify-content: flex-start;
    bottom: 0;
    width: 90%;
    padding: 5px;
    position: fixed;
    border-bottom: 1px solid #e5efff;
    background-color: #e1effd;
    border-radius: 10px;
    z-index: 1;
    margin-bottom: 12px;
}

.Recipes_Footer_Text {
    padding-top: 8px;
    padding-left: 2px;
    font-size: 15px;
    color: #7c7e7e;
    font-weight: 600;
}

/*************End Recipe Management Css****************** */

/*************Add Recipe Css****************** */
.input-AddSelect-BoxRecipe {
    width: 100%;
}

.container_Recipe-Add {
    border-radius: 10px;
    width: 99%;
    border: 2px solid #e5efff;
    height: 100%;
    text-align: initial;
    background: white;
    margin-right: -8%;
    /* overflow-x:  hidden ;
overflow-y: scroll; */
}

.Recipe_change_Item {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    /* background-color: #e5e5e5; */
    background-color: #e1effd;
    margin-top: 0rem !important;
    border-radius: 10px;
}

.input-flex-Addrecipe {
    display: flex;
    justify-content: flex-end;
    padding-right: 9px;
}

.Recipe_radius_add {
    padding-bottom: 15px;
    width: 96%;
    /* height: 100%; */
    /* border: 1px solid #ccc; */
    margin: auto;
    margin-top: 0px;
    border-radius: 8px;
    box-shadow: 0 0 5px 3px rgb(175 170 170 / 38%);
}

.RCP_flex {
    display: flex;
    justify-content: space-between;
}

.Recipe_PAV_head {
    width: 98%;
    margin: auto;
    border-radius: 5px;
}

.head_RCP_brd {
    padding: 4px !important;
    border: none !important;
    background-color: #5e709e;
    color: white;
    font-size: 14px;
}

.Add_rcp_td {
    border: none !important;
    text-align: center !important;
}

.Recipe_ADD_flex-end {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.input-height_Recipe {
    padding-top: 22px;
}

.recipe_add_height {
    height: 85px;
}

.Add_Recipe_top {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
    /* border-bottom: 1px solid #e5efff; */
    background-color: white;
    z-index: 1;
}


/*************Add Recipe Css****************** */


/*************Recipe Css****************** */

.normal_div {
    height: 80px;
}

.Additional_Cost {
    margin-bottom: 15px;
}

.BtnSave-Margin-Right {
    margin-right: 11px;
}

.Add_rows {
    font-size: 21px;
    padding: 7px 19px 2px;
    color: #212e4f;
}

.Add_rows1 {
    font-size: 21px;
    padding: 7px 17px 2px;
    color: #212e4f;
}

.Add_rows2 {
    font-size: 21px;
    padding: 7px 20px 2px;
    color: #212e4f;
}

.Additional_width {
    width: 44%;
}

.input-box-NewRcp {
    width: 100%;
}

.mode_Rcp_BTR {
    width: 50%;
    padding-left: 8px;
}

.RCP-width-BTR {
    width: 27%;
}

.Input-Box-Godown_Item {
    width: 100%;
}

.copyBtn-chaitan {
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: auto;
    padding: 2px;
    margin-right: 5px;
    background-color: #efefef;
}

.ques {
    font-size: 13px;
}

.ans {
    font-size: 12px;
    color: #626262;
}

.CPLHead {
    width: 92%;
    border: 1px solid gray;
}

.CPLHeaderBar {
    border: 1px solid gray;
    display: flex;
    justify-content: center;


}

.CPLTextContent {
    text-align: center;
    align-items: baseline;
}

.CPLContentHeader {
    width: 100%;
    display: flex;


}

.CPLContentRight {
    width: 50%;
}

.CPLContentLeft {
    width: 50%;
    border-right: 2px solid gray;
}

.CPLContentLeftTop {
    border: 1px solid gray;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;

}

.CPLContentRightTop {
    border: 1px solid gray;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    height: 30px;
    padding-left: 5px;
    padding: 2px;

}

.CPLContentLeftLabel {
    display: flex;
    justify-content: space-between;
    padding: 1px 6px 1px 34px;

}

.CPLContentLeftInput {
    display: flex;
    padding: 7px;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 30px;
}

.CopyButton {
    color: white;
    font-size: 12px;
    padding-top: 3px;
    cursor: pointer;
    font-weight: 600;
    border: 1px solid white;
    background-color: darkgreen;
    border-radius: 5px;
    padding: 0px;
    width: 50px;
}

.CPLContentLeftLabel2 {
    width: 100px;
    padding-left: 15px;
}

.CPLInputBox {
    width: 130px;
    padding: 5px;
    height: 30px;
}

.ButtonTabSave {
    display: flex;
    text-decoration: none;
    justify-content: center;
    padding-top: 0px;
}

.multi-dropdown-container {
    width: 300px;
    margin: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    margin: 5px 0;
}

.sub-dropdown {
    margin-left: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 5px;
}

/* const [optionalData1, setOptionalData1] = useState([])

    const [optionalValues, setOptionalValues] = useState({
        optionalFieldName: '',
        optionalFieldValue: ''
    });

    const handleOptionalDataChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        const newItem = {
            optionalFieldName: optionalValues.optionalFieldName,
            optionalFieldValue: optionalValues.optionalFieldValue,

        };
        const updatedValues = [...optionalData1, newItem];
        setOptionalData1(updatedValues);
    } */

.disabledInput {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
}

.toastDiv_ItemMaster {
    position: absolute;
    /* bottom: 70%; */
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 999;
    width: 65px;
    display: flex;
    justify-content: center;
    top: 25%;
}


/*************Attribute Field****************** */

.mb_3_attributename {
    width: 250px;
    height: 25px;
    text-align: center;
}

.mb_4_attributevalue {
    width: 500px;
    text-align: center;
}

.Table-Attribute-Field {
    text-align: center;
    font-size: 12px;
    /* display: block !important; */
    position: relative !important;
    display: flex;
    justify-content: space-between;
    /* max-width: 1220px; */
    width: 100%;
    margin-top: 15px;
}

.Attribute-Checkbox {
    width: 9% !important;
}

.tabrow-head-padding {
    padding: 4px;
}

.Field-attmargintop {
    margin-top: 15px;
}

.srno-flex-attribute {
    display: flex;
    justify-content: center;
}

.Note-Multi-Attribute {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.note-padding-left {
    padding-left: 20px;
}

.Note-text-font {
    font-weight: 400;
    margin-bottom: 15px;
    color: red;
}

.atrbt-val-outline {
    outline: none;
}

.Attribute_1_txtcenter {
    padding-left: 4px;
    font-size: 14px;
}

.priority-width {
    width: 5% !important;
}

.mb_5_showattribute {
    width: 200px;
    text-align: center;
}

/* .suggestion_list{
    position: absolute;
    z-index: 1;
    width: 22%;
    background-color: rgb(241 241 241);
  } */

.table-container-attribute {
    overflow: auto;
    width: 100% !important;
    height: 300px;
}

.table-container-attribute::-webkit-scrollbar {
    display: none;
}

.table-container-attribute {
    scrollbar-width: none;
}

.suggestion_list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    position: absolute;

}

.suggestion_list li {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.suggestion_list li:hover {
    background-color: #f0f0f0;
}

.suggestion_list::-webkit-scrollbar {
    display: none;
}

.sugg-margin-top {
    margin-top: 5px;
    width: 22%;
}

.sugg-Attrib-val {
    width: 44%;
}

@keyframes rotateIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.loggedInDataDiv {
    min-height: 45px;
    display: flex;
    flex-direction: column;
    background-color: #212e4f;
    justify-content: center;
    padding: 1px;
    margin: 0px 10px;
    border-left: 2px solid #edeff4;
    animation: rotateIn 1s ease-out forwards;
    padding-left: 10px;
    font-size: 14px;
    width: max-content;
    /* border-radius: 5px; */
}

.loggedInDataLabel {
    color: white;
    text-align: center;
    font-weight: 500;
}



.MHut-Bill-text {
    font-size: 13px;
    text-align: center;
}

.table-bill-MHut {
    text-align: center;
    color: #000000;
    font-size: 13px;
    /* min-width: 70px; */
}

.item-left-bill {
    text-align: start;
}

.table-bill-hd {
    width: 100%;
    margin: auto;
    position: relative;

}

.flex-center-MHut {
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    background-color: rgb(33, 46, 79);
}

.MHut-text {
    font-size: 25px;
    color: white;

}

.MHut-width-bill {
    width: 45%;
}

/* .MHut-width-KOT{
    width: 17%;
  } */

.border-line-MHut {
    border-bottom: 1px solid #a1a1a1;
}

.ques-text-col {
    font-size: 13px;
    color: black;
}

.MHut-Bg-border {
    border: 1px solid grey;
}

.MHut-GST-Border {
    background-color: white;
    padding: 5px;
    border: 1px solid grey;
}

.MHut-color-KOT {
    background-color: white;
    padding: 5px;
    border: 1px solid grey;
}

.MHut-underline {
    text-decoration: none;
}


/*************Payment Received Detail****************** */

.td_sno-btn {
    padding: 8px 10px;
}

.sno_btn {
    width: 60px;
}

.SNO-Width {
    width: 5%;
}

.modal-payment {
    width: 58%;
    max-height: 85%;
    height: auto;
}

.overflow-pymt {
    overflow-y: scroll !important;
    /* height: 400px !important; */
}

.modal-overflow-pymt {
    overflow-y: scroll !important;
    /* height: 400px !important; */
    max-height: 400px !important;
    height: auto;
}


.modal-overflow-pymt::-webkit-scrollbar {
    display: none;
}

.modal-overflow-pymt {
    scrollbar-width: none;
}

.modal-overflow-pymt::-webkit-scrollbar {
    width: 0px;
}

.date-time-payment {
    display: flex;
    width: 100%;
    height: 55px;
}



.date-time-payment1 {
    width: 50%;
    margin: 1%;
}

.payment-height-Prd {
    height: 45px;
}

.company-width-pymt {
    width: 100%;
}



/*************Order Report****************** */

.order-rpt-top {
    margin-top: 0%;
}

.select-btn {
    width: 100px;
    margin: 5px;
}

.configure_radius_add_update {
    padding: 20px 10px 10px 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.config-search-top {
    margin-top: 20px;
    padding-top: 15px !important;
}

.tb-scroll-width {
    width: 88vw;
    overflow: scroll;
}

.tb-scroll-width::-webkit-scrollbar {
    width: 0;
}



.modal-ord-title {
    display: flex;
    justify-content: center;
}

.modal-color-CAD {
    color: #141c2f !important;
}

.update-gst-td {
    display: flex;
    padding: 5px;
}

.update-btn-flex {
    display: flex;
    justify-content: center;
}

.th-bg-color {
    background-color: rgb(33 46 79) !important;
}

.Ord-txt-bold {
    font-weight: bold;
}

.SelectIcon {
    margin-bottom: 10px;
}

.star-fd {
    color: grey;
}

.number-fd {
    color: #333;
}

.star-flex-feedback {
    display: flex;
    justify-content: space-evenly;
}

.feed-flex-edit {
    display: flex;
    margin-top: 25px;
}

.edit-feed-bottom {
    padding-bottom: 8px;
}

.feedback-followup {
    text-align: center;
    font-size: 16px;
}

.followup-height {
    height: 80px;
}

.submit-flex {
    justify-content: center;
}

.modal-feedback {
    width: 50%;
    height: 50%;
}

.mobile-width-no {
    width: 98%;
}

.icon-info {
    font-size: 100px;
    color: grey;
}


.user-icon-size {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.user-img-info {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 35px;
}

.user-name {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
}

.user-img-width {
    width: 70px;
    height: 50px;
    border-radius: 50%;
}

.user-top-img {
    display: flex;
    justify-content: center;
}

.input-top-no {
    margin-top: 35px;
}

.right-icon-flex {
    display: flex;
    justify-content: center;
}

.fa-circle-right {
    padding: 30px;
    font-size: 25px;
    color: blue;
}

.Cust-Info-Box {
    width: 40%;
    min-width: 250px;
    margin: auto;
    border-radius: 10px;
    margin-top: 80px;
    box-shadow: 10px 10px 20px #cdcdcd;
}

.feedback-customer-detail {
    font-size: 16px;
}

.icon-x-add {
    font-size: 13px;
}

.feedback-report-width {
    width: 100%;
}

.feedback-date {
    display: flex;
}

.remove-padding-right {
    padding-right: 1px !important;
}

.select-decoration {
    text-decoration: none;
}

@media print {
    /* Define styles for printing */
}

.row::-webkit-scrollbar {
    display: none;
}

.back-mhbill {
    color: white;
    position: absolute;
    left: 15px;
}


.of-scroll {
    overflow-y: scroll;
}


@media(max-width:650px) {
    .input-remark-left01 {
        display: block;
    }

    .company_control {
        display: block;
    }

    .stock-im-dep1 {
        width: 100%;
        padding-top: 5px;
    }

    .input-box-Imo1 {
        margin-top: 15px;
    }

    .payment-height-Prd {
        height: max-content !important;
    }

    .date-time-payment1 {
        width: 100% !important;
        padding-top: 5px;
    }

    .modal-payment {
        width: 100%;
        max-height: 85%;
        height: auto;
    }


}

.scrollModal {
    overflow: auto;
    height: "150px"
}

.scrollModal::-webkit-scrollbar {
    display: none;
}

.variationtable-tr {
    border: 1px solid #d6d6d6;
    font-size: 14px;
}

.variationtable-tr td {
    border: 1px solid #d6d6d6;
    text-align: center;
    font-size: 14px;

}

.attribute-ct {
    border: 1px solid #d6d6d6;
    padding: 0px 5px;
    border-radius: 5px;
    font-size: 15px;
}

.variationtable-tr th {
    border: 1px solid #d6d6d6;
    text-align: center;
    font-size: 14px;

}


.productAttri-buttons {
    display: flex;
    justify-content: center;
    margin-top: 8px;

}



.productAttri-buttons button {
    background: #ff9900;
    min-width: 80px;
    color: white;
    border-radius: 8px;

}

.product-filter-parent-attb {
    width: 80%;
    margin: auto;
    border: 1px solid lightgray;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.product-filter-parent-attb-child {
    width: 20%;
    margin-bottom: 20px;
}

.product-filter-parent-attb-child-box {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    margin: auto;
    padding: 5px 10px;
    border-radius: 8px;
}


.suggestion-box {
    border: 1px solid #c8c8c8;
    width: 98%;
    border-radius: 5px;
    margin-top: 5px;
    position: absolute;
    background-color: white;
    z-index: 1;
}

.suggestion-item {
    padding: 4px 6px;
    border-bottom: 1px solid #c8c8c8;
}

.suggestion-item:nth-child(n):last-child {
    border-bottom: 0;
}

.suggestion-item:hover {
    padding: 4px 6px;
    background-color: #ebebeb;
    cursor: pointer;
}

#tblTransactionList-rp {
    margin: auto;
}

#tblTransactionList-rp thead tr {
    text-align: center;
}

#tblTransactionList-rp tbody td {
    text-align: center;
    padding: 5px;
    border: 1px solid #dfdfdf;
}

.Productstock_Master_table {
    /* border: 1px solid #dedede; */
    text-align: center;
    width: 90%;
    margin: auto;
    margin-top: 15px;
}

.Productstock_Master_table tr {
    border: 1px solid #dedede;
    text-align: center;
}

.Productstock_Master_table th {
    border: 1px solid #dedede;
    text-align: center;
    height: 45px;
    font-size: 15px;
}


.Productstock_Master_table td {
    border: 1px solid #dedede;
    text-align: center;
    padding: 5px;
    font-size: 14px;
}


.productStock-name_box {
    display: flex;
    flex-direction: column;
    border: 0 !important;
    padding: 12px !important;
}

.productStock-name_box p {
    display: flex;
    align-items: center;
    font-size: 13px;

}

.productStock-name_box h6 {
    text-align: start;
    margin: 0;
    font-size: 15px;
}

.productStock-name_box input {
    margin-bottom: 2px;
    margin-left: 5px;
}

.ProductStock_details {
    cursor: pointer;
}

.ProductStock_managediv {
    display: flex;
    flex-direction: column;
}

.ProductStock_managediv div {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
}

.ProductStock_managediv input {
    margin-left: 5px;
    margin-right: 5px;
}

.update_buttoninProductstock {
    background-color: #9eabcc;
    color: white;
    border-radius: 8px;
    height: 30px;
}

.productstockmanagar-select {
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #dedede;
}

.resetButton-vatiation {
    height: 25px;
    color: white;
    border: 1px solid white;
    padding: 0px 5px;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
}


.shortcutModal {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
    padding: 30px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    z-index: 1000;
}


.heading-shortcutModal {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.heading-shortcutModal div {
    display: flex;
    /* justify-content: center; */
}


.heading-shortcutModal div:nth-child(1) {
    width: 35%;
}

.heading-shortcutModal div:nth-child(2) {
    width: 30%;
}

.heading-shortcutModal div:nth-child(3) {
    width: 15%;
    justify-content: center;
}

.heading-shortcutModal div:nth-child(4) {
    width: 20%;
    justify-content: center;
}

.heading-shortcutModal div input {
    width: 95%;
    font-size: 14px;
    padding-left: 8px;
    height: 35px;
}

.heading-shortcutModal div select {
    width: 95%;
    padding-left: 8px;
    font-size: 14px;
    height: 35px;
}

.heading-shortcutModal div button {
    background-color: #ff9900;
    color: white;
    border-radius: 8px;
    width: 80px;
    font-size: 14px;
    padding: 0;
    height: 35px;
}

.sidebar-button-dynamic {
    /* border-radius: 8px; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    border: none;
    border-bottom: 1px solid rgb(211, 211, 211);
    width: 230px;
    height: 40px;
    background-color: transparent;
    overflow: hidden;
    margin: 0;
    padding: 1px;
}

.sidebar-button-dynamic:hover {
    background-color: #cdcdcd;
    border-right: 8px solid #212e4f;
}


.DynamicButtontable {
    border: 1px solid gray;
    margin-top: 15px;
    font-size: 14px;
}

.DynamicButtontable tr th {
    text-align: center;
    border: 1px solid gray;
    /* margin-top: 15px; */
    padding: 5px;
}

.DynamicButtontable tr td {
    text-align: center;
    border: 1px solid gray;
    padding: 5px;
    /* margin-top: 15px; */
}


.shortcutModal .fa-xmark {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 15px;
}



/* CustomSelect.css */
.custom-select {
    position: relative;
    display: flex;
    width: 80px;
    justify-content: center;
}

.select-selected {
    background-color: #f1f1f1;
    width: 80px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: center;
}

.select-items {
    position: absolute;
    background-color: #f1f1f1;
    z-index: 99;
    width: 292px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 5px;
    border: 1px solid #d7d7d7;
    box-shadow: 4px 4px 4px #c3c3c3;
}

.select-items span {
    padding: 10px;
    width: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-items span:hover {
    background-color: #ddd;
}

/* .select-items i {
    margin-right: 8px;

} */

.shortcutModal h3 {
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin-bottom: 15px;
}


.fw-12 {
    font-size: 13px !important;
}


/* .addItemtnInsale {
    width: 95%;
    margin: 5px auto 15px auto;
    border-radius: 5px;
    background-color: #5e709e;
    color: white;
    font-weight: 600;
} */

.addItemtnInsale {
    width: 55px;
    margin: 5px auto 15px auto;
    border-radius: 50%;
    /* background-color: #212e4f; */
    background-color: rgb(94 112 158);
    color: white;
    font-weight: 600;
    position: fixed;
    bottom: 60px;
    height: 55px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    box-shadow: 0px 0px 30px #eeeeee;
}

.addItemtnInsale i {
    color: white;
    font-size: 20px;
}

.SaleItemAddPageComponent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background-color: white;
    top: 0;
    z-index: 101;
    scrollbar-width: none;
}

.SaleItemAddPageComponent-TopPanel {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efefef;
    background-color: #212e4f;
    color: white;
}

.SaleItemAddPageComponent-TopPanel p {
    width: 80%;
    align-items: center;
    font-size: 16px;
}

.SaleItemAddPageComponent-TopPanel i {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;

}

.SaleItemAddPageComponent-inputContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 3px;
    max-height: 87vh;
    overflow: scroll;
    align-items: flex-start;
    /* For Firefox */
    scrollbar-width: none;
    /* For Internet Explorer and Edge */
    -ms-overflow-style: none;
}

/* For Webkit browsers like Chrome, Safari */
.SaleItemAddPageComponent-inputContainer::-webkit-scrollbar {
    display: none;
}

.SaleItemAddPageComponent-inputContainer input {
    margin: 6px auto;
    width: 100%;
    height: 38px;
    padding: 5px;
    border: 1.5px solid #ccc;
    border-radius: 5px;
    position: relative;
}


.SaleItemAddPageComponent-Input.first-input {
    width: 93.5%;
}

.SaleItemAddPageComponent-Input-div {
    width: 45%;
    position: relative;
}

.SaleItemAddPageComponent-Input-div label {
    /* width: 98.5%; */
    pointer-events: none;
    font-size: 15px;
    position: absolute;
    left: 13px;
    top: 13px;
    padding: 0px;
    background: white;
    z-index: 1;
    color: #858585;

}

.SaleItemAddPageComponent-Input-div:first-of-type {
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* Set the width of the first input div to 100% */
}


.SaleItemAddPageComponent-Input:focus+.SaleItemAddPageComponent-label,
.SaleItemAddPageComponent-Input:valid+.SaleItemAddPageComponent-label {
    top: -5px;
    transition: all;
    transition-duration: 0.3s;
    font-size: 13px;
    left: 3px !important;
    font-weight: 600;
    color: #797979 !important;
    padding: 0px 3px;
}


.SaleItemAddPageComponent-Input:focus {
    border-color: #007bff;
    /* Change border color on focus */
}

.SaleItemAddPageComponent-buttons {
    width: 100%;
    display: flex;
    bottom: 0;
    position: fixed;
    z-index: 10;
    background: white;
}

.SaleItemAddPageComponent-buttons button {
    width: 50%;
    height: 50px;
    background-color: rgb(94 112 158);
    color: white;
    font-weight: 600;
    /* background-color: #212e4f; */
    z-index: 5;
    margin-left: 0 !important;
}

.SaleItemAddPageComponent-buttons button:nth-of-type(2) {
    background-color: #e50000;
}



.ItemmasterDuggestionsaleinvoice {
    position: fixed;
    /* top: 100%; */
    max-height: 143px;
    width: 200px;
    font-size: 13px;
    padding: 0;
    z-index: 999;
    /* font-weight: 600; */
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 28%);
    overflow: auto;
    text-align: start;
    /* scrollbar-width: none; */
}

.ItemmasterDuggestionsaleinvoice li {
    padding: 8px 10px 5px 10px;
    margin: 0;
    border-bottom: 1px solid #ebebeb;
}


.ItemmasterDuggestionsaleinvoice li:hover {
    background-color: #ebebeb;
}

.showMobile {
    display: none;
}

.input-box-m6 div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
}


.width-righteye {
    width: 43%;
    margin-right: 13px;
}

.width-lefteye {
    width: 43%;
    /* margin: 5px; */

}

.width100mobile {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 160px; */
}

.PopforSelectingLens {
    position: fixed;
    width: 60%;
    height: 375px;
    bottom: 0;
    left: 50%;
    padding: 50px;
    transform: translate(-50%, 0%);
    background-color: #f7f7f7;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 35px rgb(0 0 0 / 34%);
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    z-index: 1000;
    overflow-y: auto;
    cursor: pointer;
}

.PopforSelectingLens_heading {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;
    margin-top: 12px;
}

.PopforSelectingLens_Loop {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 350px;
    justify-content: center;
    gap: 3px;
    scrollbar-width: none;
}

.PopforSelectingLens_Loop::-webkit-scrollbar {
    width: 0px;
}

.PopforSelectingLens_Loop .PopforSelectingLens_Loop_div {
    width: 65px;
    height: 35px;
    border-radius: 8px;
    box-shadow: 3px 4px 10px rgb(223 223 223);
    margin: 7px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(70, 70, 70);
}

.PopforSelectingLens_Buttons {
    display: flex;
    padding: 10px;
    justify-content: center;
    gap: 50px;
    width: 100%;
    height: 50px;
    align-items: center;
}

.PopforSelectingLens_Buttons button {
    width: 50px;
    height: 30px;
    background-color: rgb(94 112 158);
    color: white;
    border: 0;
    border-radius: 8px;
}

#customAlert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
}

.popup-back {
    position: fixed;
    width: 100%;
    height: 1500vh;
    top: 0;
    left: 0;
    /* background: rgb(0 0 0 / 16%); */
    animation: modalback 500ms;
    /* z-index: 1001; */
}

.xmark-ms-2 {
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    height: 35px;
    justify-content: center;
    left: 50%;
    position: fixed;
    /* top: 114px; */
    bottom: 385px;
    z-index: 10;
    box-shadow: 2px 3px 6px #656565;
    transform: translate(-50%);
    width: 35px;
}

.xmark-ms-2 i {
    font-size: 20px;
}

.PopforSelectingLens_nxt-button {
    width: 80px;
    height: 40px;
    background-color: rgb(104 104 203);
    color: white;
    border: 0;
    border-radius: 8px;
}

.ItemShowingTabMobileBox {
    width: 100%;
    display: none;
    margin: 15px 0px 0px 0px;
    padding-top: 10px;
    flex-direction: column;
    border-top: 1px solid #ededed;
}

.ItemShowingTabMobile {
    width: 95%;
    margin: auto;
    height: max-content;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #f7f7f7;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    /* background-color: rgb(104 104 203); */
    /* box-shadow: 1px 1px 4px #dddddd; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ItemShowingTabMobileBox p {
    font-size: 16px;
    margin: 0;
    padding: 5px;
    text-align: center;
    font-weight: 600;
}

.ItemShowingTabMobile-datarow {
    font-size: 14px;
    margin: 2px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(3) label,
.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(3) i {
    color: orange !important;
}

.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(1) label {
    color: black;
}

.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(2) label,
.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(2) i {
    color: gray;
}

.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(4) label {
    font-size: 14px;
    cursor: pointer;
    color: #080808;
    padding: 4px;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 2px #e5e5e5;
    width: 30%;
    background: #ffffff;
    font-weight: 600;
}

.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(4) label:active {
    box-shadow: none;
}

.ItemShowingTabMobile:active {
    box-shadow: none;
}

.ItemShowingTabMobile .ItemShowingTabMobile-datarow:nth-child(4) i {
    color: black;
    margin-left: 5px;
    font-size: 12px;
}


.ItemShowingTabMobile i {
    font-size: 11px;
    margin-right: 3px;
    color: #515151;
    font-weight: 600;
}

.ItemShowingTabMobile-datarow span {
    background-color: #ee9b01;
    padding: 0px 3px;
    color: white;
    border-radius: 5px;
    margin-right: 6px;
}

.BillPayAccountDetails-box {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.BillPayAccountDetails {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.BillPayAccountDetails-1 {
    display: flex;
    width: 100%;
    justify-content: center;
    position: sticky;
    bottom: 22px;
    background: white;
    border-top: 1px solid rgb(238, 238, 238);
}

.BillPayAccountDetails-2 {
    display: flex;
    width: 100%;
    height: 33px;
    justify-content: center;
    position: sticky;
    bottom: 0px;
    background: white;
    padding: 7px;
    border-top: 1px solid #cccccc;
}

.BillPayAccountDetails div {
    display: flex;
    margin: 1.5px 0px;
    justify-content: center;
    width: 95%;
}


.BillPayAccountDetails-1 div {
    width: 45%;
}

.BillPayAccountDetails-2 div {
    width: 45%;
}

.BillPayAccountDetails-input {
    width: 100% !important;
    margin: auto;
}

.BillPayAccountDetails-input span {
    float: left;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 600;

}

.display-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.hover-tr .table-th-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    background-color: white;
}


/* Custom sale invoice top input field styles */
.saleinvoicetop-input-container {
    width: 49%;
    position: relative;
}

.saleinvoicetop-input-container input[type="text"],
.saleinvoicetop-input-container input[type="number"],
.saleinvoicetop-input-container input[type="date"] {
    font-size: 13px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #bbb;
    padding: 0px 5px;
    /* background-color: transparent; */
    background-color: rgb(247, 247, 247);
    outline: none;
    height: 26px;

}

.saleinvoicetop-input-container .saleinvoicetop-label {
    position: absolute;
    top: 0;
    left: 0;
    color: #bbb;
    transition: all 0.3s ease;
    pointer-events: none;
}

.saleinvoicetop-input-container input[type="text"]:focus~.saleinvoicetop-label,
.saleinvoicetop-input-container input[type="text"]:valid~.saleinvoicetop-label,
.saleinvoicetop-input-container input[type="number"]:focus~.saleinvoicetop-label,
.saleinvoicetop-input-container input[type="number"]:valid~.saleinvoicetop-label,
.saleinvoicetop-input-container input[type="date"]:focus~.saleinvoicetop-label,
.saleinvoicetop-input-container input[type="date"]:valid~.saleinvoicetop-label {
    top: -10px;
    font-size: 13px;
    line-height: 11px;
    color: #212e4f;
    left: -3px !important;
    transform: translateY(0%) scale(1) !important;
    /* font-weight: 600; */
    background-color: transparent;

}

.saleinvoicetop-input-container .saleinvoicetop-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #444;
    transform: scaleX(0);
    transition: all 0.3s ease;
}

.saleinvoicetop-input-container input[type="text"]:focus~.saleinvoicetop-underline,
.saleinvoicetop-input-container input[type="text"]:valid~.saleinvoicetop-underline,
.saleinvoicetop-input-container input[type="number"]:focus~.saleinvoicetop-underline,
.saleinvoicetop-input-container input[type="number"]:valid~.saleinvoicetop-underline,
.saleinvoicetop-input-container input[type="date"]:focus~.saleinvoicetop-underline,
.saleinvoicetop-input-container input[type="date"]:valid~.saleinvoicetop-underline {
    transform: scaleX(1);
}

.saleinvoicetop-input-container input:focus-visible {
    background-color: #d5e9ff;
}


.saleinvoicetop-input-container select {
    font-size: 14px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #bbb;
    padding: 0px 5px;
    /* background-color: transparent; */
    background-color: rgb(247, 247, 247);
    outline: none;
    appearance: none;
    height: 26px;

    /* Hide default select box arrow */
}

.saleinvoicetop-input-container .saleinvoicetop-label {
    position: absolute;
    top: 2px;
    left: 0px;
    padding-left: 4px;
    color: #000000;
    transition: all 0.3s ease;
    pointer-events: none;
    background-color: rgb(247, 247, 247);
    width: 100%;
    font-size: 13px;
}

.saleinvoicetop-input-container select:focus~.saleinvoicetop-label,
.saleinvoicetop-input-container select:valid~.saleinvoicetop-label {
    top: -12px;
    font-size: 12px;
    line-height: 12px;
    font-size: 12px;
    left: -3px !important;
    transform: translateY(0%) scale(1) !important;
    color: #212e4f;
    /* font-weight: 600; */
}

.saleinvoicetop-input-container .saleinvoicetop-underline {
    position: absolute;
    bottom: 0;
    left: 0px !important;
    height: 1px;
    width: 100%;
    background-color: #767676;
    transform: scaleX(0);
    transition: all 0.3s ease;
}

.saleinvoicetop-input-container select:focus~.saleinvoicetop-underline,
.saleinvoicetop-input-container select:valid~.saleinvoicetop-label,
.saleinvoicetop-input-container select:valid~.saleinvoicetop-underline {
    transform: scaleX(1);
    background-color: transparent;
}

.saleinvoicetop-input-container select option {
    padding-left: 5px;
}

.flex-end .fa-rotate-left {
    font-size: 19px;
}

.flex-end .fa-square-plus {
    font-size: 19px;
}

.LensSelectioninvoice {
    border-radius: 5px;
    width: 25%;
    text-align: center;
    /* margin-left: 5px; */
}

.stock-label div {
    display: flex;
    align-items: center;
}

.addNew_btn_saleInvoice {
    background-color: #5e709e;
    color: white;
    text-align: center;
}

.addNew_btn_saleInvoice i {
    padding-left: 5px;
}

#LensFormat1 {
    margin-top: 3px;
}


.inputEffect:focus+.labelEffect1,
.inputEffect:valid+.labelEffect1 {
    top: -7px;
    left: 5px;
    font-size: 13px;
    line-height: 12px;
    width: max-content;
    color: #5a5a5a;
    transform: translateY(-8%) scale(0.9);
    /* font-weight: bold; */
}


.BillPayAccountDetails-box-2 {
    display: flex;
    flex-wrap: wrap;
}

.input-box-s12 {
    width: 74px;
    justify-content: center;
}

.Uploadprescription-lens {
    display: flex;
    width: 30%;
    height: 16px;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.Uploadprescription-lens span {
    position: absolute;
    color: black;

}

.Uploadprescription-lens input {
    opacity: 0;
    width: 70px;
}

.lensSectionInvoiceButton {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    background-color: #5e709e;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    box-shadow: 0px 0px 10px #efefef;
}

.lensSectionInvoiceIcon {
    color: red;
    margin: 0px 5px;
    font-size: 14px;
    margin-top: 5px;
}

.Checkboxandoptioninvoice {
    display: flex;
    flex-direction: column;
    border: 1px solid #bbb;
    width: max-content !important;
    position: fixed;
    z-index: 10;
    background-color: white;
    min-width: 203px;
    width: 140px;
    max-height: 120px;
    overflow-y: scroll;
    justify-content: initial !important;
}

.Checkboxandoptioninvoice input {
    margin-right: 5px;
}

.Checkboxandoptioninvoice div {
    border-bottom: 1px solid #bbb;
    width: 100% !important;
    height: 25px;
    padding: 2px 12px;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    justify-content: flex-start;
}

.Checkboxandoptioninvoice div:hover {
    background-color: #0075ff2e;
}

.AdjAmountBox {
    position: absolute;
    color: red;
    top: -20px;
    font-size: 13px;
    z-index: 0;
}

.salePrevNextBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    height: 20px;
    width: 20px;
    background-color: #5e709e;
    color: white;
    border-radius: 5px;

}

.salePrevNextBtn i {
    color: white;
}

.pagination-tr {
    display: flex;
    justify-content: flex-end;
    margin: 5px 28px;
}

.pagination-tr td {
    /* font-weight: 600; */
    font-size: 13px;
}



.salepgpagination-select {
    border: 1px solid #bbb;
    width: 45px;
    margin-right: 10px;
    margin-left: 5px;
    border-radius: 5px;
}

.PageNoPagination {
    width: 25px;
    background-color: white;
    border: 1px solid #bbb;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0;
}

.PopforSelectingLens-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
}

.PresImageView {
    width: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.PresImageViewDelete {
    position: fixed;
    background-color: red;
    color: white;
    top: 90%;
    border-radius: 8px;
    font-size: 14px;
    padding: 5px 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.PopupAndSearchCondition-box {
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: #f3f7ff;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    margin-top: 0px;
    /* height: 79vh; */
    display: flex;
}

.PopupAndSearchCondition-box-1 {
    width: 33.3%;
    margin-left: 30px;
}


.PopupAndSearchCondition-box-2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    margin-left: 5px;

}

.PopupAndSearchCondition-box-2 div {
    display: flex;
    gap: 7px;
    align-items: center;
}

.PopupAndSearchCondition-box-1 P {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
}

.PopupAndSearchCondition-box-2 input {
    width: 16px;
    height: 16px;
}

.PopupAndSearchCondition-box-2 p {
    font-size: 16px;
    font-weight: 100;
    margin: 0;
}

.tab-container {
    margin-top: 10px;
}

/******************************Coupon Master New*********************************/

.coupon-new-left {
    width: 70%;
}

.Coupon-box-template {
    border: 1px solid #e5e5e5;
    padding: 15px;
    outline: none;
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 15px;
}

.flex-products-dis-coupons {
    display: flex;
    justify-content: space-between;
}

.Amount-off-products-coupon {
    font-size: 14px;
    font-weight: 600;
}

.product-discount-coupon {
    font-size: 14px;
    font-weight: 500;
}

.coupon-method {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}

.tabs-coupon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
    /* width: 290px; */
}

.tabs-coupon div {
    margin: 0px 0px;
    padding: 5px 10px;
    border: none;
    background: #f0f0f0;
    cursor: pointer;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    border-radius: 5px;
}

.tabs-coupon div.active {
    background: #8f80bb;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    font-size: 14px;
}

.tabs-coupon .active {
    background: #8f80bb;
    color: white;
}

.input-box-coupon {
    width: 100%;
    position: relative;
}

.gen-random-code {
    display: flex;
    justify-content: flex-end;
    color: #007bff;
    font-size: 14px;
}

.top-box-coupon {
    margin-top: 20px;
}

.customer-eligibility-cpn {
    font-size: 14px;
    font-weight: 600;
}

.redeemable-cpn {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}

.flex-radio-cutomer-cpn {
    display: flex;
    margin-top: 10px;
    align-items: center;
    line-height: 0px;
}

.all-customer-cpn {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.customer-radio-cpn {
    width: 15px;
    height: 15px;
}

.radio-height-cpn {
    height: 18px;
}

.btn-browse-cpn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-browse {
    padding: 5px 15px;
    font-size: 15px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #cecece;
}

.ul-cpn-detail {
    list-style-type: none;
    color: #555;
}

.li-cpn-detail {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.0;
}

ul .li-cpn-detail:before {
    content: "• ";
    color: #333;
    font-size: 14px;
    margin-right: 5px;
}

.top-active-input {
    margin-top: 10px;
}

.top-type-cpn {
    margin-top: 15px;
}

.input-with-symbol-cpn .symbol-container-cpn {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    /* overflow: hidden; */
}

.symbol-left-cpn {
    position: absolute;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #666;
    border-right: 1px solid #ccc;
    padding: 5px;
    background-color: #f9f9f9;
    white-space: nowrap;
}

.symbol-right-cpn {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #666;
    border-right: 1px solid #ccc;
    padding: 5px;
    background-color: #f9f9f9;
    white-space: nowrap;
}

.discount-value-cpn {
    width: 40%;
}

.value-cpn-padding {
    padding-left: 20px;
}

.error-message-cpn {
    color: red;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}

.alert-icon-cpn {
    font-size: 14px;
}

.search-icon-cpn {
    position: absolute;
    left: 10px;
    font-size: 15px;
    color: #888;
    top: 8px;
}

.search-cpn-padding {
    padding-left: 30px;
}

.input-cpn-puramt {
    width: 60%;
}

.purchase-margin-cpn {
    margin-left: 25px;
}

.selected-collection-cpn {
    font-size: 14px;
    font-weight: 600;
    color: #6b6868;
}

.Top-customer-cpn {
    margin-top: 10px;
}

.Top-tab2-cpn {
    margin-top: 20px;
}

.flex-save-discard {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 15px;
    right: 135px;
}

.btn-discard {
    padding: 5px 5px;
    font-weight: 500;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #ffffff;
    color: black;
    margin-right: 10px;

}

.btn-save-discount {
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #261b1b;
    color: white;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
}

.btn-save-discount:hover {
    background-color: #5a5757;
}

.discard-delete-btn-cpn {
    border: 1px solid #d7d7d7;
    outline: none;
    border-radius: 10px;
}

.modal_collection-cpn {
    width: 50%;
    height: 80vh;
    padding: 10px;
}

.collection-color-cpn {
    color: #000000 !important;
}

.flex-home-check-cpn {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
}

.cpn-collection-checkbox {
    height: 17px;
    width: 17px;
}

.check-cpn-box {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-cpn-name {
    font-size: 15px;
    font-weight: 500;
}

.product-cpn-lbl {
    font-size: 15px;
    font-weight: 500;
    color: #848282;
}

.icon-cpn-collection {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.collection-home-cn {
    width: 15%;
    text-align: center;
}

.footer-collection-cpn {
    position: fixed;
    bottom: 5px;
    border-top: 1px solid #dbdbdb;
    width: 96%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.flex-btn-cancel-add {
    display: flex;
    justify-content: center;
}

.cancel-btn-cpn {
    width: 60px;
    padding: 4px;
    background-color: white;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
}

.add-btn-cpn {
    width: 50px;
    padding: 4px;
    background-color: #d0d0d0;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    outline: none;
    font-size: 14px;
    font-weight: 500;
}

.add-btn-cpn:hover {
    background-color: #000000;
    color: white;
}

.coupon-top-content-box {
    padding: 20px;
}

.coupon-discount-type-box {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
}

.customer-buy {
    margin-top: 20px;
}

.percentage-right-cpn {
    right: 109px;
}

.automatic-discount-coupon {
    font-size: 15px;
    width: 22px;
    height: 18px;
    margin-right: 5px;
}

.discard-icon {
    margin-left: 10px;
}


/*******************************Loyalty Master**********************************/

.Loyalty-width-master {
    width: 100%;
}

.loyalty-padding-box {
    padding: 0px;
}

.loyalty-top-member {
    margin-top: 20px;
}

.Loyalty-point-convert-tag {
    font-size: 15px;
    font-weight: 600;
    color: #494848;
    padding: 8px;
    padding-bottom: 8px;
    margin-bottom: 13px;
    border-radius: 5px;
    align-items: baseline;
}

.flex-card-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plus-icon-loyalty {
    font-size: 20px;
    margin-left: 5px;
    color: #007bff;
}

.occassion-width-setting {
    width: 100%;
}

.table-t-Occassion {
    width: 100%;
    margin: auto;
    position: relative;
}

.loyalty-update-btn {
    border: 1px solid #d1d1d1;
    outline: none;
    padding: 0px 20px;
    border-radius: 5px;
    background-color: #ff9900;
    color: white;
    font-size: 15px;
}

.flex-loyalty-Save {
    display: flex;
    justify-content: center;
    width: 100%;
}

.btn-loyalty-save {
    width: 8%;
    font-size: 15px;
    border: 1px solid #d2d2d2;
    outline: none;
    border-radius: 5px;
    background-color: #ff9900;
    color: white;
}

.loyalty-item-top {
    margin-top: 25px;
}


/************************Patient Master*****************************/

.img-height-eye {
    height: 5px !important;
}

.patient-padding {
    padding: 10px !important;
}

.tab-patient-top {
    margin-top: 10px;
}

.patient-table-width {
    width: 100%;
}

.td_name_width {
    width: 20%;
}

.Patient_treatment_btn {
    height: 35px;
    /* margin-top: 4px; */
}

.input-patient-mat {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 12px;
}

.input-patient-box {
    height: 30px;
}

.address-padding-patient {
    padding: 0px;
    height: 30px;
}

.UseMasterLebalFix {
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 7%;
    top: -12px;
    font-size: 14px;
}

.lbl-text-pt {
    top: -11px;
}

.flex-pid-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pid-search-icon {
    padding: 3px 10px;
}

.patient-widthcredit {
    width: 100%;
}

.suggestions-dropdown-coupon {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    background: #fff;
    z-index: 10;
    width: 100%;
    /* overflow-y: auto; */
}

.suggestion-item-coupon {
    padding: 8px 12px;
    cursor: pointer;
}

/* .suggestion-item-coupon:hover {
    background-color: #f0f0f0;
} */

.suggestion-item-coupon:hover,
.suggestion-item-coupon.focused {
    background-color: #f0f0f0;
    color: #333;
}

.selected-items-container-coupon {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.selected-item-coupon {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #e0e0e0;
    border-radius: 20px;
}

.remove-coupon-item-button {
    margin-left: 8px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.suggestions-country-dropdown {
    position: absolute;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    margin-top: 50px;
}

.suggestion-country-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-country-item:hover {
    background-color: #f0f0f0;
}

/* customer dropdown */
.dropdown-container-customer {
    position: relative;
    padding: 10px;
    margin-top: 10px;
}

.suggestions-dropdown-customer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    z-index: 1000;
}

.suggestions-dropdown-customer .suggestion-item-customer {
    padding: 10px;
    cursor: pointer;
}

.suggestions-dropdown-customer .suggestion-item-customer:hover {
    background-color: #e0f7fa;
}

.add-coupon-loyalty {
    padding-left: 0px;
    padding-right: 2px;
    width: 48%;
}

#checkBoxesCoupon {
    display: block;
    position: absolute;
    /* top: 0; */
    left: 2px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* max-height: 120px; */
    width: 98%;
    overflow: auto;
    z-index: 1;
}

#checkBoxesCoupon::-webkit-scrollbar {
    display: none;
}

/* For Firefox */
#checkBoxesCoupon {
    scrollbar-width: none;
}

#checkBoxesCoupon label {
    display: block;
    padding: 5px;
    cursor: pointer;
}

#checkBoxesCoupon label:hover {
    background-color: #f2f2f2;
}

#checkBoxesCoupon label input:checked {
    background-color: #007bff;
    color: #fff;
    box-shadow: none !important;
}

.modal_coupon-loyalty {
    width: 50%;
    height: 80vh;
    padding: 10px;
}

.scroll-coupon-loyalty {
    overflow-y: scroll;
    height: 35vh;
}

/*************************Loyalty Master**********************************/
.tablelist-input-box {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
}

.input-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
}

.pagination-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.pagination-arrow {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #555;
}

.pagination-arrow:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.search-box-loyalty {
    display: flex;
    align-items: center;
    gap: 5px;
}

.search-input-loyalty {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.search-button-loyalty {
    padding: 0px 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-button-loyalty:hover {
    background-color: #0056b3;
}

.discount_label {
    position: absolute;
    right: 10px;
}


.lens-invoice-division {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 12px;
    margin: 12px 0px;
    font-size: 12px;
}

.lens-invoice-division button {
    border-radius: 8px;
    background-color: #5e709e;
    color: white;
    padding: 2px 10px;
    min-width: 100px;
}

.overflow-lens {
    height: 150px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.Salebillinput {
    width: 70px;
    height: 16px;
    border-radius: 5px;
    border: none;
    padding: 0px 5px;
    font-size: 12px;
}

.PopUpSerialNumber {
    min-width: 30%;
    width: max-content;
    padding: 0px;
    padding-bottom: 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: flex-start;
}

.PopUpSerialNumber-itemName {
    height: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bbb;
    padding: 15px;
    font-weight: 600;

}

.PopUpSerialNumber-itemDetails {
    display: flex;
    padding: 15px;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
}

.PopUpSerialNumber-itemDetails .PopUpSerialNumber-itemDetails {
    width: max-content;
    margin-left: 20px;
}

.PopUpSerialNumber-table {
    width: 95%;
    border-collapse: collapse;
    /* margin-top: 10px; */
}

.PopUpSerialNumber-table th,
.PopUpSerialNumber-table td {
    border: 1px solid #ddd;
    padding: 0px;
    font-size: 13px;
    text-align: center;
}

.PopUpSerialNumber-table th {
    background-color: #f2f2f2;
    min-width: 80px;
    width: 15%;
}

.PopUpSerialNumber-table tbody {
    display: block;
    height: 50vh;
    overflow-y: auto;
    width: 100%;
}

.PopUpSerialNumber-table tr {
    height: 30px;
}

.PopUpSerialNumber-table tr td input {
    text-align: center;
    border: none;
    width: 100%;
    height: 30px;
    padding: 0px 10px;

}

.PopUpSerialNumber-table tr td select {
    text-align: center;
    border: none;
    width: 100%;
    height: 30px;
    padding: 0px 10px;

}

.PopUpSerialNumber-table thead {
    position: sticky;
    /* outline: 1px solid #ddd; */
    top: 0;
}

.PopUpSerialNumber-buttonBox {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;

}

.PopUpSerialNumber-buttonBox .PopUpSerialNumber-button1 {
    margin: 0px 10px;
    width: 100px;
    color: white;
    background-color: #d30d0d;
}

.PopUpSerialNumber-buttonBox .PopUpSerialNumber-button2 {
    margin: 0px 10px;
    width: 100px;
    color: white;
    background-color: rgb(72 87 124);
}

@keyframes blinkAttention {
    0% {
        background-color: #ffb3b3;
        /* Soft Red */
        transform: scale(1);
        opacity: 1;
    }

    25% {
        background-color: #ffffb3;
        /* Soft Yellow */
        transform: scale(1.025);
        opacity: 0.8;
    }

    50% {
        background-color: #b3ffb3;
        /* Soft Green */
        transform: scale(1);
        opacity: 1;
    }

    75% {
        background-color: #ffffb3;
        /* Soft Yellow */
        transform: scale(1.025);
        opacity: 0.8;
    }

    100% {
        background-color: #ffb3b3;
        /* Soft Red */
        transform: scale(1);
        opacity: 1;
    }
}

.blinking {
    animation: blinkAttention 1s ease-in-out infinite;
}




/******************************************************/
.dropdown-list-select {
    /* position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 93%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  top: 98%;
  left: 16px; */
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    transform: translateY(-5px);
}

.dropdown-option {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.dropdown-option input {
    margin-right: 10px;
}

/* .dropdown-option span {
    flex: 1;
} */

.dropdown-option:hover {
    background-color: #f1f1f1;
}

.TD-center-column {
    text-align: center;
}

.checkbox-report {
    width: 16px;
    height: 25px;
}

.report_layout-width {
    width: 100%;
}

.Report_Layout_top {
    margin-top: 15px;
}

.flex-report-page {
    display: flex;
}

.Search-Any_Text {
    display: flex;
    justify-content: space-between;
    width: 99%;
    align-items: flex-end;
    background-color: #e1effd;
    margin: 0 8px;
    padding-top: 5px;
    padding-bottom: 10px;
}

.Input-search-report {
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #c2cce7 !important;
}

.Search-report-box {
    margin-left: 35px;
    width: 30%;
}

.select-data-type {
    outline: none;
    border: 1px solid #ccc9c9;
}

.Report-Layout-setting {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
}

.question-layout-icon {
    width: 35px;
    background-color: #dfdfdf;
    text-align: center;
    border-radius: 5px;
}

.Report-layout-model {
    width: 50%;
    height: 65vh;
    padding: 10px;
}

.modal-title-Report {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0px;
    margin-bottom: 10px;
}

.Report-Fixed-Header {
    font-size: 20px;
}

.left-table-layout {
    width: 30%;
}

.right-table-layout {
    width: 60%;
}

.flex-report-for {
    display: flex;
}

.Report-template-model {
    width: 50%;
    height: 50vh;
    padding: 10px;
}

.temp-save {
    border: 1px solid #f4f4f4;
    border-radius: 7px;
    width: 15%;
}

.Save-flex-btn {
    display: flex;
    justify-content: center;
}

.execut-btn {
    background-color: #4caf50;
    color: white;
    padding: 8px 16px;
    margin: 0px 0px 12px 12px;
    font-size: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.2s ease;
}

.execut-btn:hover {
    background-color: #45a049;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.execut-btn:active {
    background-color: #3d8b40;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.date-right-prev {
    right: 1px;
}

.FirmName-select {
    margin-top: 5px;
    margin-bottom: 0;
}

.input-firmname {
    height: 30px;
    background-color: white;
}

.check-firname {
    width: 90% !important;
    margin-left: 15px;
}



.column_name {
    font-size: 15px;
    margin-bottom: 0px;
}


.div-for-column-header-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.add-csv-btn {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    transition: all 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 123, 255, 0.2);
}

.add-csv-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 5px 12px rgba(0, 123, 255, 0.3);
    transform: translateY(-2px);
}

.add-csv-btn:active {
    background-color: #004494;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
}

.add-csv-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.add-csv-btn+.add-csv-btn {
    margin-left: 12px;
}

/* ---------------------Modal design----------------------------- */

/* Modal backdrop styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: none;
    /* Hidden by default */
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Ensure it appears above other content */
}

.modal.show {
    display: flex;
    /* Show the modal when the 'show' class is added */
}

/* Modal content styling */
.modalContent {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    position: relative;
    left: 35%;
    top: 40%;
}

/* Close button styling */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.closeButton:hover {
    color: #f00;
    /* Change color on hover */
}

/* Header styling */
.modalHeader {
    margin-top: 0;
    font-size: 18px;
}

/* Input and select styling */
.inputField,
.selectField {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Button styling */
.addButton,
.cancelButton {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.addButton {
    background-color: #28a745;
    color: #fff;
}

.addButton:hover {
    background-color: #218838;
}

.cancelButton {
    background-color: #dc3545;
    color: #fff;
    margin-left: 10px;
}

.cancelButton:hover {
    background-color: #c82333;
}

.input-textarea {
    margin-top: 7px;
    border: 1.5px solid #ccc;
    min-height: 28px;
    outline: none;
}

td {
    margin-left: 3px;
    margin-right: 3px;
}


.show_hide {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}


.fill-to-all {
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    background-color: #218cff;
    border: none;
    padding: 6px 12px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    /* Space for an optional icon */
    box-shadow: 0 3px 6px rgba(33, 140, 255, 0.2);
    margin: 12px;
}

.fill-to-all:hover {
    background-color: #0b76e0;
    /* Darker blue */
    box-shadow: 0 5px 10px rgba(33, 140, 255, 0.3);
    transform: translateY(-2px);
}

.fill-to-all:active {
    background-color: #065cb5;
    /* Even darker blue */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
}

.fill-to-all:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(33, 140, 255, 0.4);
}

.fill-to-all+.fill-to-all {
    margin-left: 8px;
}

/* .table-body-main td {
    /* overflow: hidden; */
/* Hide overflow content */
/* white-space: nowrap; */
/* Prevent text wrapping */
/* text-overflow: ellipsis; */
/* Show ellipsis for overflow text */


/* .table-body-main {
    display: block;
    max-height: 200px;
    overflow-y: scroll;
}

.table-body-main tr {
    display: table;
    width: 100%;
    table-layout: fixed;
} */

.flex-import-export {
    display: flex;
    justify-content: space-between;
}

/******************************Sale list modal*************************************/
.salelist-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,
            0,
            0,
            0.7);
    /* Slightly darker overlay for more contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
    /* Smooth fade-in effect */
}

.salelist-modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    /* Rounded corners for a more modern look */
    width: 350px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Soft shadow for depth */
    transform: scale(0.9);
    animation: scaleUp 0.3s ease-out;
    /* Smooth scale-up effect */
}

.salelist-modal-buttons button {
    margin: 10px;
    padding: 12px 24px;
    cursor: pointer;
    background-color: #007bff;
    /* Fresh blue color */
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    /* Smooth hover effect */
}

.salelist-modal-buttons button:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
    transform: translateY(-2px);
    /* Slight lift on hover */
}

.salelist-modal-buttons button:active {
    transform: translateY(2px);
    /* Button press effect */
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes scaleUp {
    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

.DeleteWorkShoputton {
    color: red;
    position: absolute;
    font-size: 12px;
    right: 2px;
    top: 3px;
}

/*******************************************************************/

/**************************Stock Out Modal*****************************/
/* Modal container */
.modal-container-stockout {
    padding: 20px;
}

.open-modal-btn-stockout {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.open-modal-btn-stockout:hover {
    background-color: #2980b9;
}

/* Modal overlay (background) */
.modal-overlay-stockout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

/* Modal window */
.modal-stockout {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal title */
.modal-title-stockout {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Form input and select styles */
.form-group-stockout {
    margin-bottom: 20px;
}

.form-label-stockout {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 6px;
}

.form-select-stockout,
.form-input-stockout {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Submit button */
.submit-btn-stockout {
    background-color: #2ecc71;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.submit-btn-stockout:hover {
    background-color: #27ae60;
}

/* Close button */
.close-btn-stockout {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: poin ter;
    color: #999;
}

.close-btn-stockout:hover {
    color: #666;
}


.TableImageInAttribute {
    border: 1px solid #cccccc;
    width: 80%;
    margin: 20px auto;
}

.TableImageInAttribute thead tr th {
    border: 1px solid #cccccc;
    text-align: center;
}

.TableImageInAttribute td {
    border: 1px solid #cccccc;
    text-align: center;
    padding: 5px;
}

.TableConvertIcon {
    cursor: pointer;
    font-size: 14px;
    padding-left: 8px;
    margin-top: 0px;
    transition: all;
    transition-duration: 100ms;
    color: #000000;
}

.TableConvertIcon:hover {
    scale: 1.2
}

.success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(72, 187, 120, 0.85);
    color: white;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: fadeInOut 3s ease-in-out forwards;
    z-index: 1000;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translate(-50%, -55%);
    }

    10% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    90% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -45%);
    }
}


.meg1 {
    margin: 5px;
    width: 45%;
}

/******************************Voucher Wise Template Setting********************************************/
.toggle-input {
    width: 45px;
    height: 20px;
    appearance: none;
    background-color: #ccc;
    border-radius: 50px;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-input:checked {
    background-color: #4caf50;
}

.toggle-input::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 22px;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s ease;
}

.toggle-input:checked::before {
    transform: translateX(23px);
}

/******************************Voucher Wise Template Setting********************************************/

/********************Print Modal***********************/
/* BillBarModal.css */

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInBlur {
    from {
        backdrop-filter: blur(0px);
    }

    to {
        backdrop-filter: blur(5px);
    }
}

.BillBar-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-out forwards, fadeInBlur 0.3s ease-out forwards;
}

.BillBar-modal-content {
    background-color: white;
    padding: 1.25rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: max-content;
    animation: slideIn 0.5s ease-in backwards;
}

.BillBar-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BillBar-modal-header h2 {
    font-size: 1.25rem;
    font-weight: bold;
}

.BillBar-modal-close {
    color: #4b5563;
    cursor: pointer;
    transition: color 0.3s;
}

.BillBar-modal-close:hover {
    color: black;
}

.BillBar-modal-buttons {
    display: flex;
    gap: 0.75rem;
    margin-top: 1rem;
}

.BillBar-modal-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    color: white;
}

.BillBar-modal-format1 {
    background-color: #3b82f6;
}

.BillBar-modal-format2 {
    background-color: #3b82f6;
}

.BillBar-modal-sendmsg {
    background-color: #10b981;
}

.BillBar-modal-email {
    background-color: #f59e0b;
}

.BillBar-modal-close-btn {
    background-color: #ef4444;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    color: white;
}

/********************Print Modal***********************/

/**********************Loyalty Master***********************/
.category-suggestions-loyaltymaster {
    position: absolute;
    width: 46%;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    list-style: none;
    padding: 5px 0;
    margin: 5px 0 0 0;
    max-height: 180px;
    overflow-x: hidden;
    z-index: 10;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    scrollbar-width: thin;
}

.suggestion-item-loyaltymaster {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    transition: background 0.2s, transform 0.1s;
    border-bottom: 1px solid #f0f0f0;
}

.suggestion-item-loyaltymaster:last-child {
    border-bottom: none;
}

.suggestion-item-loyaltymaster:hover {
    background: #f8f9fa;
    transform: scale(1.02);
}

.no-results-loyaltymaster {
    font-style: italic;
    color: #888;
    text-align: center;
}

.selected-items-container-loyaltymaster {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns */
    gap: 10px;
    /* Space between bubbles */
    margin-top: 5px;
}

.selected-item-bubble-loyaltymaster {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 14px;
    min-width: 140px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.selected-item-bubble-loyaltymaster:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}


.remove-item-loyaltymaster {
    margin-left: 8px;
    cursor: pointer;
    font-weight: bold;
}

/**********************Loyalty Master***********************/

.table-list-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: scale(0.95);
    transition: all 0.3s ease-in-out;
}

.table-list-button:disabled i {
    pointer-events: none;
    filter: grayscale(100%) brightness(0.7);
}

/* ------------Manmohan-Theme-Master------------- */
.top-theme-master {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.top-theme-master-left {
    width: auto;
    height: auto;
    display: flex;
}

.div-for-key-value {
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    width: 120px;

    label {
        font-size: 12px;
        font-weight: 600;
    }
}

.btn-add-theme {
    width: 100%;
    justify-content: end !important;
    margin-top: 10px;
}

.column-for-theme-master {
    width: 100%;
}

.all-input {
    width: 100%;
    background-color: #fff;
    padding: 32px 32px;
    display: flex;
    gap: 13px;
    flex-wrap: wrap;
}

.bt-add-theme-master {
    width: auto;
    background-color: #53699f;
    color: white;
    font-size: 13px;
    font-weight: 700;
    border-radius: 5px;
    padding: 6px 33px;
}

.input-div-theme-master {
    align-items: start;
}

.input-theme-master {
    width: 95% !important;
    background-color: white;
    padding: 0px 12px;
}

.modal-for-theme-master {
    height: 100%;
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
}

.modal-div-theme-add {
    min-width: 130px;
    min-height: 100px;
    border-radius: 5px;
    background-color: white;
    z-index: 5;
    padding: 7px 12px;

    .div-input {
        width: 100%;
        height: auto;

        label {
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.div-add-cancel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
    margin-top: 6px;

    button {
        width: auto;
        height: auto;
        padding: 4px 8px;
        font-size: 13px;
        font-weight: 600;
        color: white;
    }
}

.modal-back-black {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000045;
}

.div-add-cancel {
    button {
        border-radius: 5px;
        padding: 4px 9px;
        background-color: #53699f;
    }
}

.label-color {
    top: -23% !important;
    background-color: transparent !important;
}


.input-theme-color {
    padding: 0px !important;
    border: 1px solid #f2f2f2 !important;
}

.product-img-view::before {
    content: "test  ....";
    position: absolute;
    left: -100px;

}

.theme-master-dropdown-input {
    width: 98%;
    height: auto;
    position: relative;

    ul {
        top: 35px;
        left: 0px;
        border: 1px solid #ccc;
        z-index: 4;
        width: 98%;
        padding: 0px;
        margin: 0px;
        background-color: white;
        position: absolute;

        li {
            position: relative;
            position: relative;
            padding: 5px 10px;
            margin: 0px;
            cursor: pointer;

            &:hover {
                background-color: #f5f5f5;
            }

            &:hover .theme-product-card-img {
                display: block !important;
            }
        }


    }
}

.dropdown-label {
    top: -11px !important;
    background-color: transparent !important;
}

.theme-product-card-img {
    display: none;
    position: absolute;
    width: 218px;
    z-index: 2;
    right: -213px;
    top: -20px;
    box-shadow: 0px 0px 22px 0px #ccc;
}

.theme-dropdown-details-page {
    width: 322px;
    height: auto;
    left: -336px;

    img {
        width: 100%;
    }
}


.theme-master-main-div {
    width: 100%;
    margin-top: 13px;
    row-gap: 8px;
}


.theme-master-dropdown-img {
    width: 100%;
    height: auto;
}


.theme-title-and-theme {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px 15px;
}

.theme-master-theme-header {
    width: 100%;
}


.slider-type-img {
    left: -231px !important;
}

.My-card-img {
    left: -231px;
}

.My-cart-img-example {
    left: -100px !important;
}

.img-show-li {
    left: -210px;
}


.number-input-hover:hover {
    .theme-product-card-img {
        display: block;
        width: 130px;
        right: -113px;
    }
}

.Product-rating-text-left {
    left: -135px;
}


.config-button {
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 2px 2px 5px;
}

.config-button:active {
    background-color: #a4a4a4dd;
}

.c-red {
    color: red;
}

.my-5px {
    margin: 5px 0px;
}

.px-20px {
    padding-left: 20px;
    padding-right: 20px;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.relative {
    position: relative;
}

.t-14 {
    top: 14px;
}

.mt-30px {
    margin-top: 30px;
}

.border-2 {
    border: 2px solid #ccc;
}

.br-4 {
    border-radius: 4px;
}

.p-5px {
    padding: 5px;
}

.display-block {
    display: block;
}

.w-100pr {
    width: 100%;
}

.mr-5 {
    margin-right: 5px;
}

.blue-button {
    background-color: #0569bf;
    color: white;
    font-weight: bold;
}

.blue-button:active {
    border-color: #66afe9;
    background-color: #155084;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.create-account-container {
    height: 100vh;
    background-color: white;
}

.create-account-box {
    background-color: #dfdfdf;
    /* background: rgba(255, 255, 255, 0.1); */
    height: 85vh;
    padding: 10px 5px;
    width: 90vw;
    max-width: 500px;
    border-radius: 4px;
    /* box-shadow: 0px 0px 5px #878282; */
    box-shadow: 0px 0px 10px gray;
    justify-content: space-around;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.create-account-box>h3 {
    font-weight: bold;
    color: #383d3d;
}

.create-account-div {
    width: 80%;
    border-radius: 4px;
    border-collapse: separate;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: flex-start;
}

.create-account-div>span {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-shrink: 0;
}

.create-account-div>input,
.create-account-div>select {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    flex-grow: 1;
    outline: none;
}

.create-account-div>input:focus,
.create-account-div>select:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}


.create-account-div>button {
    padding: 4px 10px;
    border: 1px solid gray;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    flex-shrink: 0;
}

.cancCss {
    width: calc(100% - 40px - 85.31px) !important;
}

.cherry1 {

    position: absolute;
    top: 16px;
    left: -2px;
    width: 112px;
    transform: rotate(444deg);
    z-index: -1;
    opacity: 0.7;
    filter: blur(1px);
}

.cherry2 {

    position: absolute;
    top: 33%;
    left: 7%;
    width: 75px;
    transform: rotate(344deg);
    z-index: -1;
    opacity: 0.7;
    filter: blur(2px);
}

.cherry3 {
    position: absolute;
    top: 32%;
    width: 154px;
    transform: rotate(442deg);
    z-index: -1;
    right: 22%;
    opacity: 0.8;
    filter: blur(1px);
}

.cherry4 {

    position: absolute;
    width: 129px;
    transform: rotate(351deg);
    z-index: -1;
    right: -4%;
    opacity: 0.7;
    filter: blur(1px);
    top: -3%;
}

.cherry5 {

    position: absolute;
    left: -22px;
    width: 166px;
    transform: rotate(1156deg);
    z-index: -1;
    bottom: -46px;
    opacity: 0.7;
    filter: blur(1px);
}

.cherry6 {

    position: absolute;
    width: 129px;
    transform: rotate(495deg);
    z-index: -1;
    right: -1%;
    opacity: 0.7;
    filter: blur(1px);
    bottom: -3%;
}

.features-lens-itemNew {
    width: 97%;
    margin: 20px auto;
}



.checkbox-div {
    width: 100%;
    height: auto;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;

    span {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #ccc;
        padding: 3px 5px;
        transition: all 100ms;
    }

    .active {
        background-color: #ccc;
        color: white;
        transition: all 100ms;
    }
}

.cp-lens-master {
    display: flex !important;
    justify-content: end !important;
    /* margin-top: 12px; */
}

/* Tushar */

.lens-master-lens-details-container {

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lens-master-lens-details-sub-container {

    display: flex;
}

.lens-master-lens-details-sub-sub-container {

    display: flex;
    justify-content: space-between;
    width: 90%;
}

.lens-master-lens-details-category {

    display: flex;
    justify-content: space-between;
}

.lens-master-lens-details-div {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    font-weight: bold;
}

.lens-master-lens-details-div>input {

    width: 40px !important;
}

.selected-items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
    /* Added space from the top */
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.selected-item-bubble {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
}

.selected-item-bubble:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.remove-item {
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-left: 5px;
    background: rgba(255, 255, 255, 0.3);
    padding: 2px 6px;
    border-radius: 50%;
    transition: background 0.2s ease-in-out;
}

.remove-item:hover {
    background: rgba(255, 255, 255, 0.6);
}


/* =================================================================/
  /*  Tree
  /* =================================================================/ */
/* Basic structure */
/* Basic structure */
/* Container */
/* Container */
.tree-node {
    display: block;
}

/* Parent & Leaf Nodes */
.tree-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    font-weight: 500;
    background: white;
    color: #444;
    position: relative;
}

/* Hover Effect */
.tree-item:hover {
    background: #f8f9fa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

/* Arrow Icon (Only for Non-Leaf Nodes) */
.tree-icon {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: transform 0.2s ease-in-out;
}

/* Rotate Arrow When Expanded */
.tree-icon.expanded-tree i {
    transform: rotate(90deg);
}

/* Child Nodes (Proper Indentation) */
.tree-children {
    margin-left: 15px;
    /* Ensures correct parent-child indentation */
    border-left: 2px solid #ddd;
    padding-left: 10px;
}

/* Leaf Node Styles */
.leaf {
    color: #27ae60;
    padding-left: 20px;
}

.leaf-text {
    font-size: 12px;
    margin-left: 6px;
    color: #888;
}

/* Parent Node */
.parent {
    color: #007bff;
}


/* =================================================================/
  /*  Tree
  /* =================================================================/ */