input:focus {
    outline: none;
}

/* ************************* Edit Css  ************************ */


.edit-classes {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-top: 10px;
}

.edit-paragraph {
    /* font-weight: 400; */
    font-size: medium;
    color: rgba(214, 206, 206, 0.712);
}

.edit-selector {
    padding: 0px 11px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: rgba(5, 5, 5, 0.36);
    font-size: 14px;
    color: #dad5d5e5;
    width: 40%;
}

.edit-selector :focus {
    outline: none;
    box-shadow: 0 0 4px #f4f5f7;
    border-color: #007bff;
}

.edit-selector option {
    background-color: rgba(12, 12, 12, 0.76);
    color: #e2d9d9;
}

.textarea-edit-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 5px;
}

.edit-inputbox {
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: rgb(24 21 21 / 36%);
    font-size: 14px;
    color: #dad5d5e5;
    resize: vertical;
    width: 90%;
}

.edit-inputbox:focus {
    outline: none;
    box-shadow: 0 0 4px #007bff;
    border-color: #007bff;
}



.edit-status,
.edit-component {
    display: flex;
    justify-content: center;
}

.edit-style-para {
    font-size: medium;
    font-family: serif;
    color: rgba(177, 175, 175, 0.712);
}

.edit-setting-box-main {
    border: 1px solid rgba(0, 0, 0, 0.664);
    margin-top: 3vh;
    border-bottom: none;
}

.edit-settingbutton {
    width: 100%;
    background-color: #212e4f;
    /* border: 1px solid black; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.664);
    /* border-top: 1px solid black; */
    font-weight: 600;
    font-size: 16px;
    color: white;
    height: 45px;
    display: flex;
    align-items: center;


}

.edit-openbar {
    width: 100%;
    background-color: #212e4f;
    /* border: 1px solid black; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.664);
    /* border-top: 1px solid black; */
    font-weight: 600;
    font-size: 14px;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    /* margin-top: 1vh; */
}

/* ************************* EditSetting Css  ************************ */

.editsetting-container {
    display: flex;
    height: 10vh;
    justify-content: center;
    margin-top: 3vh;
    margin-bottom: 3vh;

}


.id-section {
    display: flex;
    flex-direction: column;
    margin: 2%;
    justify-content: space-between;


}

.editsetting-input {
    width: 110%;
}

.editsetting-textcolor {
    color: rgb(236, 236, 232);
}

/* ************************* EditSetting Css  ************************ */

.genral-heading {
    margin: 1% 2%;
    margin-top: 2vh;
    font-weight: 500;
    font-size: medium;
    color: #dadde0;
    margin-bottom: 10px;
}

.alignbox {
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
}

.align-button {
    width: 32%;
    background-color: #b4b5b6;
    border: 1px solid black;
    height: 5vh;
    color: white;
}



/* ************************* GeneralSection Css  ************************ */


.general-container {
    margin-bottom: 2vh;
}

.general-sectionoption {
    display: flex;
    flex-direction: column;
    width: 46%;
    height: 7vh;
    justify-content: space-between;
}

.general-selectioncontainer {
    display: flex;
    justify-content: space-evenly;
}

.selectorstyle {


    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: rgba(5, 5, 5, 0.36);
    font-size: 14px;
    color: #dad5d5e5;
    width: 98%;
    padding-left: 5px;

}

.selectorstyle :focus {
    outline: none;
    box-shadow: 0 0 4px #007bff;
    border-color: #007bff;
}

.selectorstyle option {
    background-color: rgba(12, 12, 12, 0.76);
    color: #e2d9d9;
}

.general-paragraph {
    color: white;
}


.global-selectbutton {
    display: flex;
    justify-content: space-evenly;
    margin: 0px 6px;
    /* margin-top: 1vh; */
}


/* ************************* GlobalSelector Css  ************************ */




.displaynone {
    display: none;
}


.globalselector-container {
    margin: 2px;
    margin-top: 10px;

}

.globalselector-select {
    appearance: none;
    -webkit-appearance: none;
    margin-left: -3rem;
    border: none;
    background-color: #8b8b8b;
    padding-left: 1%;
    color: white;
    text-align: center;
}

.globalselector-input {
    background-color: #8b8b8b;
    width: 100%;
    border-radius: 5px;
    padding-left: 5px;
    color: rgb(255, 255, 255);
    font-weight: 600;

}

.globalselector-input::placeholder {
    color: white;
    padding-left: 4%;

}

/* ************************* DimensionSection Css  ************************ */


.dimension-marginpaddingcomponent {
    margin-top: 1vh;
    width: 90%;
    margin: auto;
    justify-self: center;
    border: 1px solid black;
    padding: 5px;
    padding-top: 0;
    background-color: #212e4f;
    box-shadow: inset;
    border-radius: 4px;
}

.dimension-container {
    margin-bottom: 2vh;
}

/* ************************* TypographySection Css  ************************ */
.typography-selectioncontainer {
    display: flex;
    flex-direction: column;
    width: 46%;
    height: 7vh;
    justify-content: space-between;
    margin: 2px;
}

.typography-selection-globalseltcontainer {
    display: flex;
    width: 95%;
    margin: auto;

}

.typography-sectionoption {
    width: 100%;
    margin-top: 5px;
}

.colorbox {
    height: 2vh;

    background-color: white;

}

.typography-colorchange {
    display: flex;
}

.typography-colorsection {
    width: 96%;
    margin: auto;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.para-alignbutton {
    width: 98%;
    margin: auto;
}

.para-alignbutton {
    margin-top: 1vh;
}

.lineheight {

    margin: auto;
}

.text-plus {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;

}

.small {
    font-size: smaller;
}

.rotate {
    transform: rotate(45deg);

}

.typoicon {
    font-size: smaller;
}

.firstcontent-paragicon,
.firstcontent-deleteicon {
    display: flex;
    justify-content: flex-start;
}

.firstcontent {
    display: flex;
    justify-content: space-between;
    width: 96%;
    border: 1px solid black;
    background-color: black;
    margin: 3px auto;
    padding: 2px 0px;
    border-radius: 5px;
}

.vertical-justify {
    display: flex;
    align-items: center;
}

.secondcon-XY {
    display: flex;

}

.secondcontent {
    width: 96%;
    margin: auto;
}

.firstcontent-container {
    margin: 10px;
    padding: 4px;
    border: 2px solid rgb(5, 5, 5);

}

.typography-textshadow {
    margin-bottom: 2vh;
}

.margin-button-main {
  margin: 2vh;
  background-color: #212e4f;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0px 20px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.margin-button-main:hover {
  background-color: #161e35;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* ************************* DecorationSection Css  ************************ */



.decoration-container {
    width: 96%;
    margin: 2vh auto;



}

.opacity-range {
    display: flex;
    width: 98%;

    justify-content: space-around;
    margin: auto;
}

.range-input {
  --webkit-appearance: none;
  width: 100%;
  height: 7px;
  margin: auto;
  background: #d3d3d3;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-right: 6%;
}

.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

.range-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;

}

.range-inputvalue {
    border: 1px solid black;
    border-radius: 4px;

}

.decoration-sectionoption {
    margin-top: 8px;
}

.displayflex-decoration {
    display: flex;
}

.open-imagemodal {
    width: 100%;
    background-color: black;
    color: white;
    height: 4vh;
    display: flex;
    justify-content: center;
    font-size: larger;
    border-radius: 5px;
}


/* ************************* ExtraSection Css  ************************ */


.Extracontainer {
    margin: 10px 8px;

}



/* ************************* FlexSection Css  ************************ */


.flex-container {
    margin-bottom: 10vh;
}

.flex-contparent {
    display: flex;
    width: 98%;
    margin: auto;
    justify-content: space-between;
}

.flex-flex-parent {
    margin-top: 1vh;
    padding: 0px 5px;
}

.flex-input {
    border-radius: 5px;
    font-weight: 600;
    padding-left: 6px;

}

.flex-childorder {
    display: flex;
}

.flex-inputchild {
    width: 93%;
    border-radius: 5px;
    padding-left: 7px;
    font-weight: 600;
}

.margintop-flex {
    margin-top: 10px;
}

.typo-plus {
    background-color: #b4b5b6;
}




/* ***************** image-modal*********************************** */


.image-modal {
    width: 850px;
    height: 400px;
    border: 1px solid rgba(94, 94, 94, 0.822);
    color: rgb(0, 0, 0);
    background-color: rgb(56, 56, 56);
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    animation: modalslide 700ms;
}

.image-modal-main {
    width: 100%;
    /* border: 1px solid rgba(207, 189, 189, 0.452); */
    border-radius: 5px;
    height: 80%;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.image-modal-left {
    width: 54%;
    border: 2px dashed rgba(255, 255, 255, 0.452);
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background-color: rgb(87, 87, 87);
}

.image-modal-right {
    width: 40%;
    border: 1px solid rgba(207, 189, 189, 0.452);
    height: 100%;
    background-color: rgb(87, 87, 87);
}

.upload-image-txt {
    font-size: 18px;
    color: rgb(180, 180, 180);
    font-weight: 600;
}

.image-modal-rignt-top {
    display: flex;
    width: 95%;
    margin: auto;
    margin-top: 8px;
    height: 35px;
    display: flex;
    justify-content: space-between;
}

.image-modal-right-bottom {
    height: 85%;
    width: 95%;
    margin: auto;
    /* border: 1px solid red; */
    overflow-y: scroll;
}

.image-modal-right-bottom::-webkit-scrollbar {
    display: none;
}

.add-image-input {
    padding: 2px 5px;
    width: 220px;
    border-radius: 8px;
    background-color: rgb(151, 151, 151);
}

.add-image-input::placeholder {
    color: white;
}

.add-image-btn {
    background-color: rgb(151, 151, 151);
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    color: white;

}

.right-bottom-box {
    height: 100px;
    width: 100%;
    margin-bottom: 5px;
    background-color: rgb(155, 155, 155);
    border-radius: 8px;
    display: flex;
    align-items: center;
    border-radius: 0px;
    padding-left: 5px;
}

.image-box {
    width: 99%;
    /* margin: auto; */
    height: max-content;
    min-height: 150px;
    border: 1px solid black;
    background-size: 100% 100%;
}