@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  /* overflow: hidden ; */
  font-size: 13px;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Space Grotesk', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
}

.dropped-item {
  position: relative;
}

.p-8 {
  padding: 8px;
}

.icon-edit {
  color: rgb(255, 255, 255);
  padding: 8px 11px 8px 9px;
  border: 5px;
  font-size: 15px;
}

p {
  margin: 0;
}

.rotateicon {
  transform: rotate(90deg);
}

.navbar {
  width: 100%;
  height: 53px;
  background-color: rgb(33 46 79);
  /* background:  linear-gradient(to right bottom ,black, rgb(146, 146, 146)); */
  border-bottom: 1px solid rgb(0 0 0);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

.container-bottom {
  display: flex;
  /* min-height: 70vh; */
  min-height: 44.8vw;
  height: max-content;
  flex-direction: row;
}

.container-bottom-right {
  width: 22%;
  /* background-color: rgba(0, 0, 0, 0.76); */
  background: linear-gradient(to right bottom,
      rgb(33 46 79),
      rgb(216, 216, 216));
  overflow: scroll;
  height: 100vh;
  border-left: 1px solid rgb(0 0 0);
}

.container-bottom-right::-webkit-scrollbar {
  display: none;
}

.container-bottom-left {
  width: 78%;
  background-color: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: scroll;
  overflow-y: scroll;
  min-height: 93vh;
  height: 93vh;
}

.container-bottom-left::-webkit-scrollbar {
  display: none;
}

.editimagebutton {
  opacity: 0;
}

.nav-option-box {
  width: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
  border-left: 1px solid rgb(0 0 0);
}

.nav-option-box-2 {
  width: 44%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 53px;
  border-left: 1px solid rgb(0 0 0);
}

.nav-display-box {
  width: 26%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgb(0 0 0);
  height: 35px;
  margin-left: 22%;
}

.nav-option-box-main {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.nav-txt-1 {
  color: white;
  margin: 0;
}

.homeicon {
  color: white;
  padding: 8px;
  border-radius: 5px;
  font-size: 15px !important;
}

.homeicon:hover {
  background-color: rgba(255, 255, 255, 0.89);
  transition: all;
  transition-duration: 800ms;
  cursor: pointer;
  color: black;
}

.selected-icon {
  background-color: rgba(255, 255, 255, 0.89);
  cursor: pointer;
  color: black !important;
  padding: 8px;
  border-radius: 5px;
  font-size: 15px;
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.89) , -2px -2px 4px rgba(0, 0, 0, 0.753); */
}

.nav-select-box-1 {
  background-color: rgba(32, 32, 32, 0.822);
  color: rgb(255, 255, 255);
  width: 65%;
  padding: 4px 10px;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.349);
}

.nav-select-box-1:focus-visible {
  outline: none;
}

.icon-hover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.726);
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 7px;
  border-radius: 7px;
  right: -35px;
  top: 40px;
  display: none;
  width: 100px;
  height: max-content;
  text-align: center;
  z-index: 0;
  animation: slowopen 200ms;
}

.icon-hover-1 {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.726);
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 7px;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.411);
  right: -3px;
  top: 35px;
  display: none;
  width: 100px;
  height: max-content;
  text-align: center;
  z-index: 1;
  animation: slowopen 200ms;
}

@keyframes slowopen {
  0% {
    width: 0px;
    opacity: 0;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

.demo-web {
  width: 100%;
  height: 20px;
  background-color: black;
  color: white;
}

.icon-box {
  position: relative;
}

.icon-box:hover .icon-hover {
  display: block;
  display: flex;
  z-index: 2;
}

.icon-box-1 {
  position: relative;
  width: 33%;
  text-align: center;
}

.icon-box-1:hover .icon-hover-1 {
  display: block;
  display: flex;
}

/* ******************** MultiScreen CSS ********************** */

.default-screen,
.screen-laptop,
.screen-tv {
  background-color: #d3d3d3;
  max-width: 100%;
  /* height: 100%; */
  width: 100%;
  display: flex;
  transition: all;
  transition-duration: 600ms;
  flex-direction: column;
}

.screen-tablet {
  transition: all;
  width: 65%;
  border-radius: 6px;
  transition: all;
  transition-duration: 600ms;
  border: 1px solid #919191;
  box-shadow: 0px 0px 12px rgb(97, 97, 97);
}

.screen-mobile {
  transition: all;
  width: 35%;
  transition: all;
  transition-duration: 600ms;
  border: 1px solid #919191;
  box-shadow: 0px 0px 12px rgb(97, 97, 97);
  position: relative;
}

.device-emulator-container {
  display: flex;
  justify-content: space-evenly !important;
  align-items: center !important;
  -moz-flex-direction: column;
  flex-direction: row !important;
  width: 100%;
}

.button-group {
  position: absolute !important;
  top: 8%;
  right: 23% !important;
}

#remove-preview {
  display: none;
}

.tab-frame {
  position: relative;
  top: 15px !important;
  left: 15px !important;
  width: 420px !important;
  height: 570px !important;
  border: none;
  border-radius: 25px !important;
  background-color: white !important;
}

.mobile-frame {
  top: 20px !important;
  width: calc(310px - 37px);
  height: 563px !important;
  border-radius: 33px !important;
}

.tab-frame-rotate {
  transform: rotate(-90deg) !important;
  width: 570px !important;
  position: relative;
  height: 420px !important;
  left: -60px !important;
  top: 90px !important;
}

.mobile-frame-rotate {
  transform: rotate(-90deg) !important;
  width: 568px !important;
  position: relative;
  height: 270px !important;
  left: -128px !important;
  top: 164px !important;
}

.mobile-frame {
  background-color: white !important;
  color: black !important;
}

.button-group {
  margin-bottom: 10px;
  z-index: 10;
  display: flex;
  flex-direction: row;
}

.icon-button {
  height: 35px !important;
  width: 35px !important;
  padding: 7px 5px 5px 5px;
  border-radius: 50%;
  margin: 5px;
  border: none;
  background: #8b8b8b !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.icon-button1 {
  height: 35px !important;
  width: 35px !important;
  padding: 7px 5px 5px 5px;
  border-radius: 50%;
  margin: 5px;
  border: none;
  background: #8b8b8b !important;
  justify-content: center !important;
  align-items: center !important;
  display: none;
}

.icon-button-emulator {
  height: 33px !important;
  width: 160px !important;
  padding: 6px 8px 6px 8px;
  border-radius: 15px;
  margin: 5px;
  border: none;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  background: #8b8b8b !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.mobile-reposition {
  position: relative;
  left: 0px !important;
  top: 0px !important;
}

.tab-reposition {
  position: relative;
  top: 0px !important;
  left: 0px !important;
}

.displayblock {
  display: block !important;
}

/* ******************************** */

/* setting CSS */

.edit-heading {
  text-align: center;
  font-size: 14px;
  color: white;
  margin-top: 5px;
}

/* Viewcode CSS */

.viewcode-modal {
  width: 800px;
  height: 580px;
  border: 1px solid rgba(94, 94, 94, 0.822);
  /* position: absolute; */
  /* transform: translate(95%, 5%); */
  color: rgb(0, 0, 0);
  background-color: rgb(56, 56, 56);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modalslide 700ms;
}

@keyframes modalslide {
  0% {
    transform: translate(-50%, -80%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.viewcode-modal-heading {
  font-size: 25px;
  position: absolute;
  color: white;
  left: 35px;
  top: 5px;
}

.viewcode-modal-main {
  width: 93%;
  height: 100%;
  margin-top: 50px;
  margin: auto;
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
}

.viewcode-modal-left {
  width: 49%;
  height: 97%;
  background-color: rgb(163, 163, 163);
  border-radius: 15px;
}

.viewcode-modal-right {
  width: 49%;
  height: 97%;
  background-color: rgb(163, 163, 163);
  border-radius: 15px;
}

.viewcode-modal-left-heading {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.418);
  padding-right: 15px;
  color: rgba(255, 219, 18, 0.979);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* ******************************************************* */

/* ******************ADD COMPONENT CSS************************ */

.addcomponent-main-box {
  width: 100%;
  background-color: rgb(33 46 79);
  border-bottom: 1px solid black;
  font-size: 14px;
  padding: 5px;
  padding-left: 10px;
  font-weight: 600;
  height: 40px;
  color: white;
  cursor: pointer;
}

.add-comopnent-icon {
  padding: 8px 15px;
}

.basics-component-box {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  background-color: rgb(112 119 138);
  /* background:  linear-gradient(to right bottom ,rgb(114, 110, 110), rgb(29, 29, 29)); */
}

/* @keyframes slowopen {
    0%{
    height: 0vh;
    grid-template-columns: repeat(1, auto);
    }

    100%{
    height: max-content;
    grid-template-columns: repeat(2, auto);
    }
   } */

.forms-component-box {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  background-color: rgb(112 119 138);
}

.reportDesign-component-box {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  background-color: rgb(112 119 138);
}

.extra-component-box {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  background-color: rgb(112 119 138);
}

.basics-component {
  width: 95px;
  height: 95px;
  /* border: 1px solid black; */
  margin: 8px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(33 46 79);
  box-shadow: 2px 3px 15px rgba(128, 128, 128, 0.705);
  transition: all;
  transition-duration: 200ms;
}

.basics-component:hover {
  box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.705);
}

.add-component-text-box {
  display: flex;
  justify-content: center;
}

.addcomponent-icon-box {
  display: flex;
  justify-content: center;
}

.add-component-txt {
  color: #dbdbdb;
  font-size: 13px;
}

#modal_box_close {
  font-size: 22px;
  padding: 3.5px 6px;
  border-radius: 50%;
  transition: all;
  cursor: pointer;
  transition-duration: 500ms;
  color: white !important;
  position: absolute;
  right: 5px;
  top: 3px;
}

#modal_box_close:hover {
  color: rgb(255, 0, 0) !important;
}

.modal-back {
  position: fixed;
  width: 100%;
  height: 1500vh;
  top: 0;
  left: 0;
  background: rgba(59, 59, 59, 0.705);
  animation: modalback 500ms;
  z-index: 1001;
}

@keyframes modalback {
  0% {}

  100% {}
}

.ac-icon-1 {
  color: rgb(212, 212, 212);
  font-size: 27px;
  margin-bottom: 8px;
}

/* ****************************************** */

/* ******************SHOW-STRUCTURE-CSS************************ */

.structure-1 {
  width: 100%;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.562);
  border: 1px solid rgb(12, 12, 12);
  color: black;
  /* padding: 2px; */
  /* padding-left: 5px; */
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.structure-1.hover-border-web-template {
  border: 1px solid rgb(105, 170, 255);
  background-color: rgb(224, 247, 255);
  transition: background-color 0.3s, border 0.3s;
}

.structure-small {
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.425);
  border: 1px solid rgb(12, 12, 12);
  color: black;
  padding: 1px;
  padding-left: 0px;
  font-size: 13px;
  font-weight: 600;
}

.structure-1-1 {
  display: none;
}

/* **************************ABOUTUS CSS************************************* */

.aboutus-modal {
  width: 300px;
  height: 500px;
  border: 1px solid rgba(94, 94, 94, 0.822);
  color: rgb(255, 255, 255);
  background-color: rgb(36, 36, 36);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  animation: modalopen 500ms ease-in-out;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.137);
  padding: 25px;
  font-size: 15px;
  overflow: hidden;
}

@keyframes modalopen {
  0% {
    height: 0;
    transform: translate(-50%, -50%) rotate(30deg);
    box-shadow: 0px 0px 20px rgb(209, 209, 209);
    opacity: 0;
  }

  100% {
    height: 500px;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.know-more-button {
  border: 1px solid white;
  padding: 5px 20px;
  color: white;
  border-radius: 10px;
  bottom: 10px;
  transform: translate(50%, -20%);
  position: absolute;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.137);
  background-color: rgb(49, 49, 49);
}

/* **************************addtemplate modal CSS************************************* */

.addtemplate-modal {
  width: 300px;
  border: 1px solid rgba(94, 94, 94, 0.822);
  color: rgb(255, 255, 255);
  background-color: rgb(36, 36, 36);
  border-radius: 8px;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.137);
  padding: 25px;
  font-size: 15px;
  overflow: hidden;
}

.save-temp-button {
  border: 1px solid white;
  padding: 5px 20px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.137);
  background-color: rgb(49, 49, 49);
}

.add-template-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-template-div input {
  width: 100%;
  margin: 15px 0px;
  border-radius: 5px;
  padding: 5px;
}

/* website content  */

.app {
  width: 100%;
  /* margin: 0 auto; */
  height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.header {
  background-color: rgba(155, 82, 112, 0.842);
  color: #fff;
  padding: 20px;
  text-align: center;
}

.navbar1 {
  background-color: #f1f1f1;
  text-align: center;
}

.nav-links {
  list-style-type: none;
  padding: 8px;
  margin: 0;
  background-color: bisque;
}

.nav-links li {
  display: inline-block;
  margin-right: 25px;
  font-weight: 600;
  /* text-decoration: dashed; */
}

.nav-links a {
  text-decoration: none;
  color: #000000;
  font-size: 20px;
}

.content {
  padding: 20px;
  /* background-color: white; */
}

.footer {
  background-color: rgba(155, 82, 112, 0.842);
  color: #fff;
  padding: 20px;
  text-align: center;
}

/* sidebar */

.sidebar-mobile {
  position: absolute;
  top: 0;
  left: -255px;
}

.navbar-menu-box-mobile {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.navbar-menu-mobile {
  width: 250px;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 13px;
  justify-content: center;
  height: 35px;
  align-items: center;
  transition: all;
  transition-duration: 400ms;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid rgb(156, 156, 156);
}

.logo-sidebar {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00699d;
}

.navbar-mbile-logo-img-sidebar {
  width: 70px;
  height: 30px;
  /* margin-top: 5px; */
}

.slider-close {
  color: rgb(255, 255, 255);

  font-size: 22px;
}

.close-slider-box {
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TemplateSelectBox {
  border: 1px solid black;
  background-color: #edeff4 !important;
  padding: 5px;
  border-radius: 5px;
}

.TemplateSaveButton {
  border: none;
  background-color: #ff9900;
  color: white;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: max-content;
  min-width: 30px;
  border-radius: 5px;
}

/* *****************************Media query************************************ */

@media (max-width: 1200px) {}

@media (max-width: 900px) {
  .conditional-modal {
    width: 80%;
    height: 650px;
  }
}

@media (max-width: 650px) {
  .c-modal-input-box {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .c-modal-input-small {
    width: 90%;
    margin-top: 15px;
  }

  .c-modal-input-small2 {
    width: 90%;
    margin-top: 15px;
  }

  .c-modal-input-small3 {
    width: 90%;
    margin-top: 15px;
  }

  /* *********NAVBAR******************* */

  .navbar-types {
    display: none !important;
  }

  .nav-mobile-screen {
    display: flex !important;
  }

  .sidebar-mobile {
    display: flex !important;
  }

  .navbar-menu.active {
    color: rgb(255, 255, 255);
    text-shadow: 3px 3px 5px rgb(0, 0, 0);
    font-size: 17px;
    background: #47474757 !important;
  }
}