#innerhtml {
    page-break-inside: avoid;
    overflow: hidden;
    width: 58mm;
    /* Fixed width matching jsPDF options */
}

.leftSideMenu {
    width: 100%;
    height: 92vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: lightgray;
    /*overflow: -moz-hidden-unscrollable;*/
    scrollbar-width: none;
}

.leftSideMenu::-webkit-scrollbar {
    display: none;
}

.trackModelStyle {
    height: auto;
    max-height: 85vh;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

.trackModelStyle::-webkit-scrollbar {
    display: none;
}

.menuContainer {
    width: 40%;
    overflow: scroll;
    height: max-content;
    /*overflow: -moz-hidden-unscrollable;*/
    scrollbar-width: none;
}

.menuContainer::-webkit-scrollbar {
    display: none;
}

.cartItem {
    overflow: scroll;
    /* height: 350px; */
    height: 388px;
    background-color: white;
    scrollbar-width: none;
}

.cartItem::-webkit-scrollbar {
    display: none;
}

.multiplePosDiv {
    width: 100% !important;
    background-color: ghostwhite !important;
}

.thead {
    padding: 0px;
    border: 1px solid transparent;
    color: black;
    background-color: lightgray;
}

.menuItem {
    display: flex;
    justify-content: flex-start;
    border-radius: 10px;
    width: 100%;
    margin: 12px 12px;
    padding: 0px;
    cursor: pointer;
    border-left: 5px solid #cc6215;
    background-color: white;
    box-shadow: 3px 3px 8px #9f9f9f;
    padding: 10px 10px 10px 6px;
}

.listItem {
    display: flex;
    justify-content: flex-start;
    border: 1px solid lightgrey;
    border-radius: 10px;
    width: 100%;
    margin: 12px 12px;
    padding: 5px;
    cursor: pointer;
    background-color: white;
}

.menuLabel {
    color: black;
    font-weight: 600;
    font-size: 13px;
    font-family: system-ui;
}

.gridView-pos {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    margin: 0px 5px;
    justify-content: space-evenly;
    align-content: baseline;
}

.messageDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: #151212;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
    padding: 10px;
}

.gridView3 {
    display: grid;
    grid-template-columns: repeat(3, auto);
    margin: 0px 5px;
    justify-content: normal;
    width: auto;
    max-width: 100%;
    overflow: scroll;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.5s ease;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.greyBtn {
    background-color: gainsboro;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    color: black;
    font-weight: 600;
    border: 1px solid grey;
    cursor: pointer;
}

.greyBtn:hover {
    background-color: dimgrey;
    color: white;
}

.serviceTypeBtn {
    background-color: #12234d;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

.serviceTypeBtnActive {
    background-color: #12234d;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

.serviceTypeBtn:hover {
    background-color: white;
}

.increDecreBtn {
    background-color: white;
    padding: 1px 8px;
    width: 20%;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.scroll-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box;
}

.scroll-container {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /*height: 200px;*/
    transition: scrollbar-color 0.5s ease;
}

.scroll-container::-webkit-scrollbar {
    /*width: 1px;*/
    display: none;
}

.leftSideMenuContainer {
    max-height: 100%;
    overflow-y: auto;
}

/* styles.css */
.hide-spinner::-webkit-outer-spin-button,
.hide-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .hide-spinner {
 -moz-appearance: textfield; 
} */

.cart-btn {
    color: #12234d;
    background-color: white;
    border: 2px solid #12234d;
    padding: 4px;
    border-radius: 10px;
    width: 20%;
    font-weight: 600;
}

.mt_Top {
    margin-top: 0.7rem;
}

.cart-btn:hover {
    color: white;
    background-color: #12234d;
    border: 2px solid white;
}

.pos-circle {
    height: 17px;
    width: 17px;
    background-color: dimgrey;
    border-radius: 20px;
}

.pos-circleDineIn {
    height: 25px;
    width: 25px;
    background-color: dimgrey;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    align-content: center;
    font-family: calibri;
}

.tableNumber {
    background-color: #f2f2f2;
    height: 70px;
    width: 75px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed darkgrey;
    margin-right: 20px;
    font-weight: 600;
    flex-direction: column;
}

.table-VerticalScroll {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #eaeafbfa transparent;
    -ms-overflow-style: auto;
}

.table-VerticalScroll::-webkit-scrollbar {
    width: 1px
}

.tableNumber-pos {
    background-color: white;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid darkgrey;
    margin-right: 20px;
    font-weight: 600;
    flex-direction: row;
}

.variationDiv {
    border: 1px solid lightgrey;
    padding: 10px;
    display: grid;
    /* justify-content: center; */
    /* align-content: center; */
    /* align-items: center; */
    text-align: center;
    border-radius: 10px;
    background-color: #fbf7f7;
}

.menu-pos {
    height: 85vh;
    overflow-y: scroll;
}

.menu-pos::-webkit-scrollbar {
    display: none;
}

.menu-pos {
    scrollbar-width: none;
}

.cartScroll {
    height: 87.5vh;
    overflow-y: scroll;
}

.cartScroll::-webkit-scrollbar {
    display: none;
}

.cartScroll {
    scrollbar-width: none;
}

.payemntIcon {
    color: black;
}

.paymentDiv-pos {
    padding: 5px;
    border: 1px solid black;
    background-color: ghostwhite;
    width: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.paymentDiv:hover {
    background-color: lightgray;
}

.paymentDiv:hover .payemntIcon {
    color: black;
    font-size: 18px;
}

.paymentDiv:hover label {
    color: white;
    font-weight: 700;
}

.fw-600 {
    font-weight: 600;
}

.menuList {
    cursor: pointer;
    margin: 0px 2px;
    background-color: lightgray;
}

.menuListAcive {
    cursor: pointer;
    margin: 0px 0px;
    background-color: #b3b3b3;
    border-right: 5px solid rgb(102, 102, 102);
}

.menuList:hover {
    background-color: #b3b3b3;
    /* border-right: 5px solid rgb(102, 102, 102); */
}

.input-containerEffect {
    position: relative;
    margin-bottom: 5px !important;

}

.input-containerEffect12 {
    position: relative;
    margin-bottom: 20px;
}

.inputEffect {
    width: 100%;
    height: 28px;
    padding: 0px 5px 0px 0px;
    border: 1px solid lightgrey !important;
    border-radius: 5px;
    border: medium;
    width: 100%;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #6a6161 !important;
    padding-left: 10px;
}

.BorderBottomsale {
    border: none !important;
    border-bottom: 1px solid #bbb !important;
    /* height: 32px; */
    /* padding-top: 5px; */
    background-color: rgb(247, 247, 247) !important;
    color: rgb(48, 48, 48) !important;
    border-radius: 0 !important;
}


.BorderBottomsale~label {
    color: rgb(48, 48, 48) !important;
    background-color: rgb(247, 247, 247);

}



.labelEffect {
    position: absolute;
    top: 7px;
    left: 10px;
    transition: top 0.2s, font-size 0.2s;
    pointer-events: none;
    background-color: white;
    color: #6a6161 !important;
}

.icon_ov1 {
    font-size: 18px;
    padding: 3px;
}

.labelEffect1 {
    position: absolute;
    top: 3px;
    left: 6px;
    transition: top 0.2s, font-size 0.2s;
    pointer-events: none;
    background-color: white;
    color: #6a6161 !important;
    font-size: 14px;
    width: 83%;
    text-align: left;
}

.inputEffect:focus+.labelEffect,
.inputEffect:valid+.labelEffect {
    top: -10px !important;
    font-size: 13px;
    width: max-content;
    transform: translateY(-8%) scale(0.9);
    color: #7e7c7c !important
        /* font-weight: bold; */
}

.inputEffect:focus+.labelEffect1,
.inputEffect:valid+.labelEffect1 {
    top: -7px !important;
    left: 5px !important;
    font-size: 13px !important;
    line-height: 12px;
    width: max-content;
    color: #7e7c7c !important;
    transform: translateY(-8%) scale(0.9);
    /* font-weight: bold; */
}

.tableStyle-pos {
    border-collapse: collapse;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
}

.thStyle {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    /* background-color: #aeb6c8; */
    background-color: #5e709e;
    color: white;
    font-weight: 600;
    font-size: 14px;
}

.tdStyle {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
    /* background-color: white; */
}

.black-btn {
    color: white;
    background-color: #12234d;
    border: 2px solid white;
    padding: 10px;
    border-radius: 10px;
    width: 22%;
    font-weight: 600;
}

.black-btn:hover {
    color: white;
    background-color: #5d72a7;
    border: 2px solid white;
}

.viewBtn {
    color: darkred;
    background-color: white;
    border: 2px solid darkred;
    padding: 5px;
    border-radius: 10px;
    width: 80%;
    font-weight: 600;
}

.viewBtn:hover {
    color: white;
    background-color: darkred;
    border: 2px solid white;
}

.closeBtn {
    background-color: dimgray;
    padding: 5px;
    border-radius: 10px;
    width: 100%;
    color: white;
    font-weight: 600;
    border: 3px solid white;
    margin: 0px 5px;
}

.hoverRow:hover {
    background-color: #e6e6fa54;
}

.searchIcon {
    margin: 8px 0px;
    border-radius: 5px;
    padding: 3px;
}

.searchIcon:hover {
    background-color: #3e5899;
}

.searchIcon:hover i {
    color: white !important;
}

.hover-text {
    position: absolute;
    z-index: 1;
    background-color: black;
    color: white;
    font-size: 13px;
    padding: 7px;
    font-weight: 600;
    top: 0px;
    right: 100%;
    width: max-content;
    font-family: sans-serif;
}

/* .fa-solid {
    background-color: transparent;
    font-size: 18px;
} */

@keyframes modalback {
    0% {
        background: rgba(134, 134, 134, 0.8);
    }

    100% {
        background: rgba(58, 58, 58, 0.8);
    }
}

@keyframes modalslide {
    0% {
        transform: translate(-50%, -80%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

.modal-back {
    position: fixed;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    background: rgba(58, 58, 58, 0.8);
    z-index: 101;
    animation: modalback 500ms;
}

.info-modal {
    width: 55%;
    max-height: 500px;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
    padding: 10px;
    overflow: scroll;
    scrollbar-width: none;
}

.info-modal::-webkit-scrollbar {
    display: none;
}

.xmark {
    color: black;
    cursor: pointer;
}

.xmark:hover {
    background-color: lightgray;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

/***************** Media Query ******************/
/**************** Media Query (900px)**************/
@media(max-width : 900px) {}


/***************************** Home Page *******************************/

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
}

.container_main {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    border: 1px solid black;
    /*margin-left:25px;*/
}

.left-container {
    height: 100vh;
    background-color: transparent;
    color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: width 0.3s;
}

.left-container.expanded {
    width: 200px;
}

.right-container {
    flex: 1;
    /* padding: 5px; */
    background-color: transparent;
    transition: margin-left 0.3s;
}

.app-container {
    display: flex;
}

.OrderNow-btn {
    color: white;
    background-color: #12234d;
    border: 2px solid white;
    /* padding: 10px; */
    border-radius: 10px;
    width: 20%;
    font-weight: 600;
}

.OrderNow-btn:hover {
    color: #12234d;
    background-color: white;
    border: 2px solid #12234d;
}

.modal-content5 {
    background-color: #fefefe;
    margin: 6% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 675px;
}

.ov_modal_content {
    width: 100%;
    display: flex;
}

.ov_left_side {
    width: 40%;
}

.ov_right_side {
    width: 60%;
}

.input-box-Im_ov {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 2px;
    margin-bottom: 3px;
    width: 100%;
    position: relative;
}

.im-icon-1 {
    padding-left: 7px;
    font-size: 12px !important;
    margin-bottom: 3px;
    cursor: pointer;
    color: #dfdfdf;
}

.white-btn {
    color: #12234d;
    background-color: white;
    border: 2px solid #12234d;
    padding: 10px;
    border-radius: 10px;
    width: 20%;
    font-weight: 600;
}

.variableItem-modal {
    width: auto;
    max-height: 90%;
    height: auto;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
    padding: 10px;
    min-width: 50%;
}

.OrderPayMode {
    padding: 4px;
    height: 40px;
    margin-top: 3px;
    width: 50px;
}

.white-btn:hover {
    color: white;
    background-color: darkgreen;
    border: 2px solid white;
}

/* ScrollbarHidden.css */
.scroll-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow: hidden;
    /*Hide the scrollbar*/
    background-color: transparent;
}

.circular-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    background-color: black;
}

.bottomLabel {
    font-weight: 700;
    color: dimgrey;
    width: 95%;
    text-align: center;
}

.bottomInstruction {
    display: flex;
    justify-content: space-between;
    margin-left: 110px;
    margin-bottom: 70px;
}

.Pos_invoice_scroll {
    height: 65vh;
    overflow: auto;
}

.Pos_invoice_scroll_AM {
    height: 75vh;
    overflow: auto;
}

.Pos_invoice_scroll::-webkit-scrollbar {
    width: 10px;
    /* This sets the width of the scrollbar */
}

.Pos_invoice_scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(133, 133, 133);
    /* This sets the color of the scrollbar thumb */
}

.CloseHeadBtn {
    display: flex;
    justify-content: flex-end;
    height: 3px;
    position: relative;
    top: -8px;
    right: 4px;

}

.head-title {
    font-size: 1.8rem;
    height: 34px;
    margin-bottom: 5px;
}

.CrossBtnBack {
    cursor: pointer;
    font-size: 24px;
    height: 4px;
    position: relative;
    top: 4px;
    right: 4px;
}

.POS_Table_Tr1 {
    border-bottom: 1px solid #e7e7e7;
    height: 35px;

}

.POS_Table_Tr1:hover {
    background-color: #e1effd;
}

.POS_table_tr {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(166, 6, 6);
    height: 18px;
    width: 18px;
    border-radius: 15px;
    cursor: pointer;
}

.POS_Table_Td1 {
    text-align: center;
    padding-left: 10px;
    cursor: pointer;

}

.POS_Table_Td2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(166, 6, 6);
    height: 18px;
    width: 18px;
    border-radius: 15px;
    cursor: pointer;
}

.POS_Table_Td3 {
    text-align: center;
    cursor: pointer;
    font-size: 13px;
}

.POS_Table_Input_Text {
    width: 50px;
    text-align: center;
    font-size: 13px;
    height: 20px;

}

.POS_Table_Label1 {
    color: white;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.Icon_Pen_POS {
    padding-left: 5px;
    font-size: 10px;
    color: #585858;
}

.pos_AreaText_input {
    border: 1.5px solid #ccc;
    width: 100%;
    color: #555;
    font-size: 14px;
    /* height: 35px; */
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
}

.pos_AreaText_input:focus {
    border-color: #007bff !important;
    border: 1.9px solid #007bff;
    box-shadow: none !important;
}

.POS_Select_im_input {
    border: 1px solid transparent;
    width: 50%;
    color: #555;
    font-size: 14px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 4px;
    background-color: none !important;
    background: none;
    appearance: none;
}

.POS_Select_im_input:focus {
    border-color: #007bff !important;
    border: 1.9px solid #007bff;
    box-shadow: none !important;
}

.mt-3 {
    margin-top: 0.8rem !important;
}

.ques1 {
    font-size: 12px;
    padding-top: 5px;
}

/* *******************{+- Button CSS}**************** */

.quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    /* justify-content: center; */
    padding: 0;
    padding-bottom: 3px;
}

.quantity__minus,
.quantity__plus {
    display: block;
    width: 22px;
    height: 22px;
    margin: 0;
    background: #ededed;
    text-decoration: none;
    text-align: center;
    line-height: 0px;
    border: none;
    font-size: 20px;
    DISPLAY: flex;
    ALIGN-ITEMS: center;
    JUSTIFY-CONTENT: center;
}

@media(max-width:1150px) {

    .quantity__minus:hover,
    .quantity__plus:hover {
        background: #575b71;
        color: #fff;
    }
}

.quantity__minus:hover,
.quantity__plus:hover {
    background: #ededed;
    color: black;
}

.quantity__minus {
    border-radius: 6px 0 0 6px;
}

.quantity__plus {
    border-radius: 0 6px 6px 0;
}

.quantity__input {
    width: 33px;
    height: 22px;
    FONT-SIZE: 13PX;
    margin: 0;
    padding-left: 0px;
    text-align: center;
    border-top: 2px solid #dee0ee;
    border-bottom: 2px solid #dee0ee;
    border-left: 1px solid #dee0ee;
    border-right: 2px solid #dee0ee;
    background: #fff;
    color: #8184a1;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: textfield;
}

.quantity__input:focus {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: textfield;
}

.quantity__input::-webkit-inner-spin-button,
.quantity__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.quantity__minus:link,
.quantity__plus:link {
    color: #8184a1;
}

.quantity__minus:visited,
.quantity__plus:visited {
    color: #fff;
}

/***************** Media Query ******************/
/**************** Media Query (780px)**************/
@media(max-width : 780px) {
    /* .right-container {
        margin-left: 35px;
    } */

    .bottomInstruction {
        margin-left: 20px;
    }

    .circular-image {
        border-radius: 50%;
        width: 80px;
        height: 80px;
    }

    .bottomLabel {
        font-size: 12px;
    }
}

/**************** Media Query (420px)**************/
@media(max-width : 420px) {
    .bottomInstruction {
        flex-direction: column;
        justify-content: center
    }
}

/**************** Media Query (2024px)**************/
@media screen and (min-width: 1500px) and (max-width: 1920px) {
    .cartScroll {
        height: 89vh;
        overflow-y: scroll;
    }

    .menu-pos {
        height: 88vh;
        overflow-y: scroll;
    }

    .leftSideMenu {
        /* height: 99vh; */
        height: 93%;
        overflow-y: scroll;
    }
}

.smallSelectBox {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 5px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.tb-scroll-width-feedBack {
    width: 100%;
    overflow: scroll;
}

/* ********************************************************* */

/*************Cancellation Receiver Analysis****************** */

.tb-scroll-width {
    /* width: 88vw; */
    width: 100%;
    overflow: scroll;
}

.tb-scroll-width::-webkit-scrollbar {
    width: 0;
}

.tb-scroll-width11 {
    /* width: 88vw; */
    width: 100%;
    overflow: scroll;
}

.table-width-cancel {
    width: 1500px;
}

.modal-color-CAD {
    color: #141c2f;
}

.config-margin-top {
    margin-top: 10px;
}

.status-btn {
    border-radius: 2px;
    margin-right: 0px;
}

.td-padding-sn {
    padding: 3px;
}

.td-itemname {
    width: 12%;
}

.modal-cancellation {
    min-width: 50%;
    width: auto;
    max-width: 95%;
    height: auto;
    max-height: 90%;
}

.display-none-500 {
    display: none !important;
}

/*************Feedback setting****************** */
.priority-feed-width {
    width: 14%;
    margin-left: 25px;
}

.feedback-flex {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
}

@media(max-width:500px) {
    .input-containerEffect12 {
        position: relative;
        width: 98% !important;
    }

    .modal-cancellation {
        min-width: 95%;
        /* min-width: 50%;
        width: auto;
        max-width: 95%;
        height: auto;
        max-height: 90%; */
    }

    .display-none-500 {
        display: block !important;
    }

    .variableItem-modal {
        width: 95%;
    }

    .gridView3 {
        display: grid;
        grid-template-columns: repeat(2, auto);
    }
}



.description {
    position: absolute;
    font-size: 14px;
    top: 100%;
    left: 46px;
    width: 235px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-weight: normal;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
}

.question-mark:hover+.description {
    display: block;
}

.td-description-width {
    width: 22%;
}

.ul-padding-left {
    padding-left: 15px;
}

.li-margin-bot:before {
    margin-bottom: 10px;
    content: "•";
    margin-right: 5px;
    position: absolute;
    left: 13px;
}


.li-margin-bot {
    text-align: left;
}


.iconBox-ov {
    width: 20px;
    margin-top: -10px;
}

.btnPaymentStatus-ov {
    font-weight: 600;
    color: rgb(87, 78, 78);
    background-color: ghostwhite;
    border: 1px solid darkgrey;
    font-size: small;
    padding: 5px;
    height: 38px;
}


.remark-ov {
    width: 123px;
    margin-right: 5px;
    padding: 4px 8px;
    border-radius: 5px;
}

.tableScroll-kot {
    overflow: scroll;
    height: auto;
    max-height: 390px;
    border: 1px solid #e0e0e0;
}

.tableScroll-kot::-webkit-scrollbar {
    display: none;
}

.tableScroll-kot::-webkit-scrollbar {
    width: 0.5em;
}

/* Track */
.tableScroll-kot::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.tableScroll-kot::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
}

/* Hide scrollbar on Firefox */
.tableScroll-kot {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.tableHover:hover {
    background-color: rgba(141, 161, 255, 0.23);
    /* border: 1px solid darkgrey; */
}

.viewBlueBtn {
    color: darkblue;
    background-color: white;
    border: 2px solid darkblue;
    padding: 5px;
    border-radius: 10px;
    width: 80%;
    font-weight: 600;
}

.viewBlueBtn:hover {
    color: white;
    background-color: darkblue;
    border: 2px solid white;
}

.TrackOrderModel {
    width: 50%;
    min-width: 700px;
    height: 95vh;
    background-color: white;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;

}

.track-row {
    display: flex;
    width: 45%;
    justify-content: space-between;
    align-self: center;
    margin: 0;
    margin-bottom: 10px;
}


.track-row input {
    width: 70%;
}

.track-row textarea {
    width: 70%;
    border: 1px solid rgb(233, 233, 233);
    height: 80px;
}

.track-row p {
    margin: 0;
}

.track-data-row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px 0px;
    border-bottom: 1px solid rgb(233, 233, 233);
}

.track-order-table {
    width: 95%;
    margin: auto;
    margin-top: 20px;
    border: 1px solid rgb(179, 179, 179);
}

.track-order-table th {
    background: #e6e6e6;
    text-align: center;
    border: 1px solid rgb(179, 179, 179);
    padding: 5px;
}

.track-order-table td {
    border: 1px solid rgb(179, 179, 179);
    text-align: center;
    padding: 5px 0px;
}

.track-button-box {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}

.track-button {
    width: 100px;
    padding: 8px;
}

.alignCenter {
    text-align: center;
}

.billPrintModal {
    width: 35%;
    height: 510px;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;

    overflow-x: hidden;
    overflow-y: scroll;
    background-color: lightgray;
    /*overflow: -moz-hidden-unscrollable;*/
    scrollbar-width: none;
}

.billPrintModal::-webkit-scrollbar {
    display: none;
}

.feedBackModal_Scroll {
    /* width: 40%; */
    overflow: scroll;
    /* height: max-content; */
    /*overflow: -moz-hidden-unscrollable;*/
    scrollbar-width: none;
    height: 90%
}

.feedBackModal_Scroll::-webkit-scrollbar {
    display: none;
}

.posSelectBox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: 1px solid transparent;
}

.pos_Small_SaveBtn {
    border-radius: 5px;
    padding: 0px 7px;
    background-color: green;
    color: white;
    font-weight: 600;
}

@media(max-width:650px) {
    .info-modal {
        width: 95%;
    }
}

.pos_deliveredBtn {
    background-color: #006400a6;
    border: 1px solid #006400a6;
    color: white;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-weight: 600;
}

.pos_deliveredBtn:hover {
    background-color: white;
    border: 1px solid darkgreen;
    color: darkgreen;
}

.pos_printBtn {
    background-color: #b504048c;
    border: 1px solid #b504048c;
    color: white;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-weight: 600;
}

.pos_printBtn:hover {
    background-color: white;
    border: 1px solid darkred;
    color: darkred;
}

.modal-overflow-feedback {
    overflow-y: scroll !important;
    max-height: 100vh !important;
    height: 80vh;
}

.modal-overflow-feedback::-webkit-scrollbar {
    display: none;
}

.modal-overflow-feedback {
    scrollbar-width: none;
}

.cancelModalPopup {
    overflow: scroll;
    height: 122px;
    scrollbar-width: none;
}

.cancelModalPopup::-webkit-scrollbar {
    display: none;
}

.overFlow_none {
    overflow: scroll;
    scrollbar-width: none;
}

.overFlow_none::-webkit-scrollbar {
    display: none;
}

.focusOnTable {
    position: fixed;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    background: rgba(58, 58, 58, 0.8);
    z-index: 101;
    animation: modalback 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_pos {
    position: relative;
    display: inline-block;
    padding: 10px;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
    opacity: 0.9;
    transition: opacity 0.3s;
    height: 70px;
}

/* .arrow_pos {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid black;
} */

.popup-message_pos {
    padding: 5px;
}

/* ************************ */

.slider-container_pos {
    position: fixed;
    /* bottom: -120px; */
    bottom: -8%;
    left: 57%;
    max-width: 70%;
    height: auto;
    width: 43%;
    background-color: #f8f8ff;
    border: 2px solid grey;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: bottom 0.3s ease-in-out;
    z-index: 5;
}

.slider-container_pos.open {
    /* bottom: 102px; */
    bottom: 15%;
}

.arrow-toggle_pos {
    /* position: relative;
    top: 155px;
    left: 50%; */
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid black;
    cursor: pointer;
    /* position: fixed;

    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid black;
    cursor: pointer;
    bottom: 23%; */
}

.arrow-pos {
    font-size: 50px;
    transition: transform 0.3s ease;
}

.arrow-pos.up {
    transform: rotate(180deg);
}

.slider_pos {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, visibility 0s linear 0.3s;
    visibility: hidden;
}

.slider_pos.open {
    bottom: 0;
}

.slider_pos {
    position: absolute;
    bottom: -300px;
    left: 0;
    right: 0;
    background-color: #4a90e2;
    color: white;
    transition: bottom 0.3s ease-in-out;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}


.arrow-toggle_pos-box {
    width: 100px;
    position: absolute;
    height: 30px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -31px;
    left: 50%;
    transform: translateX(-50%);
}

/* ************************************ */
.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f8ff;
    text-align: center;
}

.welcome-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.welcome-container p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
}

.welcome-actions {
    display: flex;
    gap: 15px;
}


.input-Width50 {
    width: 50%;
}

.paddingzero {
    padding: 0;
}


.sale-Invoice-plus-button {
    font-size: 20px;
    padding: 0;
}

.lensInfoRight-box {
    width: 60%;
    margin-top: 0px;
}

.lensInfoLeft-box {
    width: 40%;
}

.radiolabel_box {
    display: flex;
    justify-content: space-around;
    margin: 15px 0px;
}

/* .welcome-actions button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
} */

/* .welcome-actions button:hover {
    background-color: #0056b3;
} */

.slider-containerPOS {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #f0f0f0;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
}

.slider-containerPOS.open {
    height: 100vh;
    z-index: 5;
}

.slider-contentPOS {
    padding: 0px;
    height: 98%;
    overflow-y: auto;
    width: 100%
}

.suggesstion_box {
    /* width: 100%;
    border-bottom: 1px solid darkgrey;
    padding: 0px;
    margin: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 1px; */
    border: none;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding: 0px 0px;
    font-size: 12px;
}

.cart_box {
    padding: 0px 5px;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 96%;
    margin: 10px;
}

.myordercart-mobile-2 {
    height: 45px;
    border-bottom: 1px solid rgb(126, 126, 126);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 127 0);
    color: white;
    font-size: 16px;
    padding: 0px 20px;
    font-weight: 600;
}

.fontSize14 {
    font-size: 14px;
    font-weight: 600;
    color: #696969;
}

/* Add styles for slider animation and background blur */
.slider-container-forPos {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: whitesmoke;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    z-index: 1000;
}

.slider-container-forPos.open {
    height: 245px;
}

.slider-content-forPos {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.blur-background-forPos {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    z-index: 999;
}

.loginBtn {
    display: flex;
    width: 93%;
    background-color: rgb(6, 154, 199);
    /* padding: 0.75rem; */
    text-align: center;
    color: rgb(10, 10, 10);
    height: 35px;
    border: none;
    border-radius: 0.375rem;
    font-weight: 600;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.menu-open {
    display: block;
    width: 70px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    /* background-image: url("https://mothershut.com/RestoFolders/MOTHERSHUT_Supela_Bhilai/btn_menu.png"); */
    background-size: contain;
    transform: translate(-0%, -10%);
    right: 0%;
    z-index: 1;
}

.categoryBar {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #cdcdcd; */
    margin-top: 0px;
    background-color: #e5e5e5;
    color: #5f5f5f;
    padding: 5px 0px;
    border-radius: 0px;
}

.ourmenu-topdiv {
    height: 45px;
    width: 100%;
    outline: 1px solid rgb(218, 218, 218);
    /* border-top: 1px solid rgb(218, 218, 218); */
    /* border-bottom: 1px solid rgb(218, 218, 218); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-weight: 600;
    color: black;
    border-top: 2px solid grey;
    /* border-bottom: 2px solid grey; */
}

.ourmenu-heading-submenu {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #cdcdcd; */
    margin-top: 0px;
    background-color: black;
    color: #5f5f5f;
    padding: 5px 0px;
    border-radius: 0px;
    color: white;
    /* border-top: 2px solid #cf6464;
    border-bottom: 2px solid #cf6464; */
}

.overflow-hidden {
    overflow: hidden;
}

.flicker-element {
    will-change: transform;
}

.bottomFixed_modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

.labelEffectForMobPos {
    position: absolute;
    top: 10px;
    left: 10px;
    transition: top 0.2s, font-size 0.2s;
    pointer-events: none;
    background-color: white;
    color: #6a6161 !important;
    font-weight: 600;
}

.modalForMenuPOS {
    background: rgb(255, 254, 254);
    position: fixed;
    left: 50%;
    bottom: -100%;
    /* Initially hidden below the screen */
    transform: translateX(-50%);
    width: 300px;
    height: auto;
    max-height: 100vh;
    padding: 10px;
    transition: bottom 0.4s ease-out, opacity 0.4s ease-out;
    border: 1px solid rgba(128, 128, 128, 0.342);
    overflow-y: auto;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
}

.modalForMenuPOS.show {
    bottom: 50%;
    opacity: 1;
    transform: translate(-50%, 50%);
}

.rightBottomFixed_modal {
    position: fixed;
    right: 0;
    width: 15%;
    background-color: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 20px 2px 2px 20px;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    transform: translateY(100%);
    bottom: 23%;
}

.crossBtn {
    color: "black";
    background-color: lightGrey;
    border-radius: 50%;
    font-weight: 600;
    font-size: 16px;
    padding: 0px;
    height: 25px;
    width: 25px;
}

.m-10 {
    margin: 10px;
    width: 92%;
}

.ml-15 {
    margin-left: 15px;
}

.countDineIn {
    background-color: white;
    border: 1px solid #b3adad;
    border-radius: 5px;
    width: auto;
    height: auto;
    padding: 5px;
}