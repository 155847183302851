.dropdown-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  margin: 20px 0;
}

.dropdown {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
}

.dropdown:focus + .dropdown-label,
.dropdown.filled1 + .dropdown-label {
  top: -10px;
  font-size: 14px;
  color: #3f51b5;
  z-index: 1000;
  background: white;
}

.dropdown-label {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all 0.3s ease;
  pointer-events: none;
  color: #9e9e9e;
}

.dropdown.filled1 {
  padding-top: 10px; /* Adjust padding for filled state */
}
