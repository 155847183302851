@media (max-width: 550px) {

    .heading {
        display: flex;
        background-color: black;
        padding: 0px 15px;
        position: fixed;
        top: 0;
        width: 100%;
        align-items: center;
        height: 50px;
        z-Index: 1;
    }

    .Min_height_mob {
        height: 38px;
    }

    .head-display {
        display: flex;
        justify-content: space-between;
    }

    .head-title {
        margin-left: 8px;
        font-size: 16px !important;
        font-weight: 600;
        color: white;
    }

    .head-title-ot {
        margin-left: 8px;
        font-size: 16px !important;
        font-weight: 600;
        color: white;
        margin-bottom: 0;
    }

    .head-title2 {
        margin-left: 8px;
        font-size: 16px !important;
        font-weight: 600;
        color: white;
        display: flex;
        padding-top: 5px;
    }

    .space-evenly {
        display: flex;
        justify-content: space-evenly;
    }

    .space-around {
        display: flex;
        justify-content: space-around;
    }

    .space-between {
        display: flex;
        justify-content: space-between;
    }

    .flex-start {
        display: flex;
        justify-content: flex-start;
        /*padding: 5px 2px;*/
    }

    .flex-start-ot {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        padding-top: 3px;
    }

    .flex-end {
        display: flex;
        justify-content: flex-end;
    }

    .icon-white {
        color: white;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-10 {
        padding: 10px;
    }

    .bg-white {
        background-color: black;
        padding: 8px 8px;
        margin-top: 0px;
        border-radius: 0px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid lightgrey;
        width: 100%;
        margin-bottom: 15px;
    }

    .ques {
        font-weight: 700;
        font-size: 13px;
        padding: 2px 0px;
    }

    .ans {
        /*margin-left: 5px;*/
        font-weight: 400;
        font-size: 14px;
        padding: 2px 0px;
    }

    .floating-button-container {
        position: fixed;
        bottom: 100px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 5;
    }

    .floating-button-container-500 {
        /* position: fixed; */
        bottom: 70px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 5;
        display: block;
    }

    .circle {
        background-color: #007bff;
        color: #fff;
        border-radius: 45px;
        width: 37px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*margin-bottom: 10px;*/
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }

    .fa-bars,
    .fa-magnifying-glass,
    .fa-plus,
    .fa-phone,
    .fa-envelope {
        margin-right: 5px;
    }

    .leftContainer {
        border: 1px solid transparent;
        width: 15%;
        height: auto;
        background-color: white;
        display: grid;
        justify-content: space-evenly;
        align-content: flex-start;
        margin-top: 0px;
    }

    .smallCircle {
        width: 42px;
        height: 42px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
        background-color: white;
        padding: 1px;
        margin-top: 25px;
    }

    .leftSideIcon {
        color: white;
        font-size: 12px;
        text-align: center;
    }

    .topBar {
        border: 1px solid transparent;
        background-color: white;
        padding: 1px;
        color: black;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 8px 5px;
    }

    .detail-panel {
        padding: 0px;
        border: 2px solid lightgrey;
        border-radius: 5px;
        margin: 5px 5px;
        background-color: #ffffff;
    }

    .bottomContainer {
        border: 1px solid transparent;
        width: 100%;
        height: 50px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        margin-top: 9px;
        padding-top: 8px;
    }

    .order-panel {
        padding: 5px;
        border: 2px solid lightgrey;
        border-radius: 5px;
        margin: 5px 5px;
        background-color: white;
    }

    .preferenceInput {
        padding: 2px;
        height: 21px;
        width: 40%;
        background-color: white;
        /* border: none !important; */
        border-bottom: 1px solid black;
        outline: none !important;
        /* border-right: none !important; */
    }

    /* .preferenceInput::-webkit-inner-spin-button,
    .preferenceInput::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .preferenceInput[type="number"] {
        -moz-appearance: textfield;
    }
    .preferenceInput {
        cursor: default;
    } */

    .input-containerEffect {
        position: relative;
        margin-bottom: 12px !important;
    }

    .inputEffect {
        width: 100%;
        padding: 7px !important;
        /*border: 1px solid lightgrey !important;*/
        border-radius: 5px;
        border: medium;
        width: 100%;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        color: #6a6161 !important;
        height: 40px !important;
        padding-left: 10px;
    }

    .labelEffect {
        position: absolute;
        top: 7px;
        left: 10px;
        transition: top 0.2s, font-size 0.2s;
        pointer-events: none;
        background-color: white;
        color: #6a6161 !important;
    }

    .inputEffect:focus+.labelEffect,
    .inputEffect:valid+.labelEffect {
        top: -10px !important;
        font-size: 13px;
        font-weight: bold;
        background-color: white;
    }

    .orderTakerSaveBtn {
        padding: 10px;
        width: 90%;
        color: black;
        background-color: lightblue;
    }

    .orderTakerCancelBtn {
        padding: 10px;
        width: 95%;
        color: black;
        background-color: #ff450099;
    }

    .kotStatus-modal {
        width: 88%;
        height: auto;
        border: 1px solid rgb(94, 94, 94);
        color: rgb(0, 0, 0);
        background-color: white;
        border-radius: 15px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: modalslide 1000ms;
        padding: 10px;
    }

    .greenBtn {
        background-color: #2a9f79;
        padding: 10px;
        border-radius: 10px;
        /* width: max-content !important; */
        color: white;
        font-weight: 600;
        border: 3px solid white;
    }

    /*  .blueBtn {
        background-color: #2a9f79;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        color: white;
        font-weight: 600;
        border: 3px solid white;
    }*/
    .greyBtn {
        background-color: lightgrey;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        color: white;
        font-weight: 600;
        border: 3px solid white;
    }

    .redBtn {
        background-color: #ff4163;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        color: white;
        font-weight: 600;
        border: 3px solid white;
    }

    .blueBtn {
        background-color: #2a9f79;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        color: white;
        font-weight: 600;
        border: 3px solid white;
        margin: 0px 10px;
    }

    .relativeDetailsDiv {
        background-color: white;
        border: 1px double lightgrey;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
    }

    .toastDiv {
        position: fixed;
        bottom: 50%;
        left: 50%;
        transform: translateX(-50%);
        background: #333;
        color: #fff;
        padding: 30px 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        z-index: 999;
        width: 80%;
        display: flex;
        justify-content: center;
    }

    .modal-back1 {
        /*position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;*/
        /*bottom:0;*/
        /*background: rgba(170, 170, 170, 0.8);
        z-index: 101;
        animation: modalback 500ms;*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.72);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }

    .iconDiv {
        padding: 20px;
        border: 1px solid rgb(94, 94, 94);
        color: white;
        background-color: dimgray;
        border-radius: 10px;
        /*width: 8%;*/
        padding-top: 15px;
        font-size: 22px;
    }

    .statusDiv {
        width: 80%;
        height: 75px;
        background-color: white;
        border: 1px solid rgb(94, 94, 94);
        border-radius: 10px;
        padding-top: 10px;
        padding: 5px 10px;
    }

    .detail-header {
        padding: 5px;
        background-color: dimgray;
        color: white;
    }

    .detailDiv {
        background-color: white;
        padding: 5px;
        border: 1px solid grey;
    }

    .orangeBtn {
        background-color: orangered;
        color: white;
        border-color: orangered;
        padding: 10px;
        border: 1px solid orangeRed;
        border-radius: 5px;
        width: 100%;
        margin: 5px;
    }

    /*********************/
    .wrapper1 .sidebar1 {
        background: rgb(255, 254, 254);
        position: fixed;
        top: 0;
        left: -300px;
        width: 300px;
        height: 100%;
        /* padding: 20px 0; */
        transition: all 0.5s ease;
        border: 1px solid rgba(128, 128, 128, 0.342);
        overflow: scroll;
        z-index: 6;
    }

    .wrapper1 .sidebar1::-webkit-scrollbar {
        display: none;
    }

    .wrapper1 .sidebar1 {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .sideBarBack {
        position: absolute;
        width: 100%;
        height: 1500vh;
        top: 0;
        left: 0;
        background: rgba(170, 170, 170, 0.8);
        z-index: 101;
        animation: modalback 500ms;
    }

    .userProfileIcon {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        border: 1px solid white;
        margin-left: 5px;
    }

    .side-topbar {
        background-color: #39668c;
        margin-top: 0px;
        padding: 10px;
    }

    .side-bottomBar {
        padding: 15px;
    }

    .linksDiv {
        margin: 5px 5px;
    }

    .linksDiv,
    i,
    label {
        color: black;
    }

    .MainBtn {
        display: block;
    }

    .floating-button-container-500 {
        display: block !important;
    }
}

.floating-button-container-500 {
    display: none;
}

.MainBtn {
    display: block;
}

/*************Login Order****************** */

.Login-container {
    width: 100%;
    height: 630px;
    border-radius: 0.75rem;
    background-color: rgb(255 255 255);
    padding: 2rem;
    color: rgb(0 0 0);
}

.Login-title {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
}

.form-login {
    margin-top: 1.5rem;
}

.input-group-login {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.input-group-login label {
    display: block;
    color: rgb(0 0 0);
    margin-bottom: 4px;
}

.input-group-login input {
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid rgba(55, 65, 81, 1);
    outline: 0;
    background-color: rgb(255 255 255);
    padding: 13px;
    color: rgb(0 0 0);
    box-shadow: none;
}

.input-group input:focus {
    border-color: rgba(167, 139, 250);
}

.forgot-login {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(156, 163, 175, 1);
    margin: 8px 0 14px 0;
}

.forgot-login a,
.signup a {
    color: rgb(0 0 0);
    text-decoration: none;
    font-size: 14px;
}

.forgot-login a:hover,
.signup a:hover {
    text-decoration: underline rgba(167, 139, 250, 1);
}

.sign-login {
    display: block;
    width: 100%;
    background-color: rgba(167, 139, 250, 1);
    padding: 0.75rem;
    text-align: center;
    color: rgba(17, 24, 39, 1);
    border: none;
    border-radius: 0.375rem;
    font-weight: 600;
    margin-top: 25px;
}

.social-message-login {
    display: flex;
    align-items: center;
    padding-top: 1rem;
}

.line-log {
    height: 1px;
    flex: 1 1 0%;
    background-color: rgba(55, 65, 81, 1);
}

.social-message-login .message {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(0 0 0);
}

.social-icons-log {
    display: flex;
    justify-content: center;
}

.social-icons-log .icon-log {
    border-radius: 10px;
    padding: 8px;
    border: none;
    background-color: black;
    margin-left: 8px;
    width: 13%;
}

.social-icons-log .icon-log svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: #fff;
}

.signup-log {
    text-align: center;
    font-size: 14px;
    line-height: 2rem;
    color: rgb(0 0 0);
}

.login-padding-ord {
    padding: 25px;
}

.icon-log-social {
    color: white;
}

.mode_LoginOrder {
    width: 100%;
    padding-left: 11px !important;
    height: 48px;
}


/*************Mother`s Hut BIll****************** */

/* .MHut-Bill-text {
    font-size: 13px;
    text-align: center;
} */

.table-bill-MHut {
    text-align: center;
    color: #000000;
    font-size: 13px;
    /* min-width: 70px; */
}

.item-left-bill {
    text-align: start;
}

.table-bill-hd {
    width: 100%;
    margin: auto;
    position: relative;

}

.flex-center-MHut {
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    background-color: rgb(33, 46, 79);
}

.MHut-text {
    font-size: 25px;
    color: white;

}

.MHut-width-bill {
    /* width: 15%; */
    width: 50%;
}

/* .MHut-width-KOT {
    width: 50%;
} */

.border-line-MHut {
    border-bottom: 1px solid #a1a1a1;
}

.ques-text-col {
    font-size: 13px;
    color: black;
    font-weight: 600;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.MHut-Bg-border {
    border: 1px solid grey;
}

.MHut-GST-Border {
    background-color: white;
    padding: 5px;
    border: 1px solid grey;
}

.MHut-color-KOT {
    background-color: white;
    padding: 5px;
    border: 1px solid grey;
}

.MHut-underline {
    text-decoration: none;
}

/* *********************************** */
.containerTop-ot {
    padding: 5px 0px;
    margin-top: 45px;
    height: max-content;
    overflow-x: scroll;
}

.printLabel {
    /* font-family: "courier" !important; */
    font-family: "Sans-serif" !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}

.printLabel-dark {
    /* font-family: "courier" !important; */
    font-family: "Sans-serif" !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}

/* *********** Psyment Response Page ***************** */
.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #001f3f;
    /* Dark blue background */
    color: #ffffff;
    /* White text */
    font-family: 'Arial', sans-serif;
}

.success-message {
    text-align: center;
    margin-bottom: 20px;
}

.success-message h1 {
    color: #1dc81d;
}

.order-details {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-details p {
    margin: 10px 0;
    color: #004080;
}

.scrollable_div {
    overflow-y: scroll;
    height: auto;
    max-height: 680px;
}

.scrollable_div::-webkit-scrollbar {
    display: none;
}

.scrollable_div {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.notification-container {
    position: relative;
    display: inline-block;
}

.notification-icon {
    width: 40px;
    height: 40px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
}

.notification-badge {
    position: absolute;
    top: -9px;
    right: 4px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}