@media (max-width: 550px) {
    .linksDiv {
        margin: 5px 5px;
    }

    .linksDiv,
    i,
    label {
        color: black;
    }

    .sideBarDiv {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
    }

    .listDiv {
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: 10px;
        margin: 5px;
        padding: 10px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.37);
    }

    .detailTopDiv {
        padding: 5px;
        margin: 0px;
        background-color: #5b5757;
        color: white;
    }

    /* .numCircle{
        height:20px;
        width:20px;
        border-radius:20px;
        background-color:darkred;
        display:flex;
        justify-content:center;
        align-items:center;
    } */
    .numCircle {
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: darkred;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-red {
        width: 20px;
        height: 20px;
        background-color: darkred;
        /*#007bff; */
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .circle-text-red {
        color: white;
        font-size: 14px;
    }

}

@keyframes blink {
    0% {
        background-color: rgb(253, 235, 235);
        /* Initial background color */
    }

    /* 50% {
        background-color: rgba(243, 199, 199, 0.541); /* Blinking color */
    /* } */

    100% {
        background-color: rgba(231, 142, 142, 0.6);
        /* Back to initial color */
    }
}

/* .blinking {
    animation: blink 1500ms infinite;
} */