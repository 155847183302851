body {
    background: #fff;
    font-family: 'Calibri', sans-serif !important;
    color: #4e4e4e;
    line-height: 22px;
}

.tabs {
    /* background-color: #ffb225; */
    background-color: white;
}

.tab {
    font-weight: 600;
}

    .tab.active {
        font-weight: 600;
        background-color: #97660a;
        border: 1px solid #97660a;
        border-radius: 5px;
    }

.EWayDiv {
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: #f3f7ff;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
}

.icon {
    font-size: 12px;
    color: black;
    padding: 7px;
    background-color: #f3f7ff;
    border-radius: 3px;
    border: 1px solid lightgrey;
}
.save_btn {
    padding: 5px;
    background-color: #2d1d9f;
    color: white;
}
.marginTop-1px{
    margin-top:1px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li.parent {
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333;
} */

button {
    background-color: #dddd;
    border: none;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
}

li.child {
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
}
.HeadTopMaim{
    width: max-content;
    border: 1px solid gray;
    position: relative;
    font-size: 12px;
    top: -34px;
    left: 106px;
    border-radius: 5px;
    background: aqua;
    padding: 5px;
}
.HeadTopText:hover{
    font-size: 14px;
    transition: 0.5ms;
}
.SubCategoryBtn:hover{
    font-size: 13px;
}
.SubHeadTopMaim {
  width: 135px;
  position: relative;
  font-size: 12px;
  top: -32px;
  left: 124px;
  border-radius: 5px;
  background: #959595;
  color: white;
  padding: 5px;

}
.HeadTopText{
    font-size: 13px;
    font-weight: 600;
    padding-left: 5px;

}
.AddBillHeadText{
    display: flex;
    font-size: 15px;
    padding-bottom: 15px;
    justify-items: baseline;
    width: 100%;
    justify-content: center;

}
.sub-category-txt {
    display: flex;
    height: 30px;
}
.sub-category-box3 {
    /* display: none; */
    margin: 5px;
    margin-left: 20px;
}
.SubCategoryBtn{
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    width: max-content;
}
.InputtxtLabel{
    font-size: 19px;
    font-weight: 400;
    cursor: pointer;
}
.CategoryBoxPaddingLeft {
    padding-left: 20px;
    font-size: 18px;
}
.MasterSettingSaveBtn{
    border-radius: 6px;
    width: 70px;
    background: #ff9900;
    color: wheat;
    box-shadow: 1px 2px 7px #b9b9b9;
    font-size: 17px;
    transition: all;
    transition-duration: 300ms;
}
.MasterSettingSaveBtn:hover{
   scale: 1.05;
    /* font-size: 18px; */

}
.disabledInput {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
    /* Add any other styles for disabled state */
  }

  .InputTextBoxLabel5{
    position: absolute;
    background-color: #ffffff !important;
    color: #8d8a8a;
    pointer-events: none;
    transform: translateY(0rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 20%;
    top: 1px;
    font-size: 16px;
    padding-top: 10px;
  }
  .InputH_6{
    height: 30px;
  }
  .InputL4{
    left: 4%;
  }

/* ********************{Dropdown css}********************** */


/* ********************{input css}********************** */

/*************Attribute Field****************** */

.mb_3_attributename {
  width: 550px;
  height: 25px;
  text-align: center;
}

.mb_4_attributevalue {
  width: 65%;
  text-align: center;
}

.Table-Attribute-Field {
  text-align: center;
  font-size: 12px;
  position: relative !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Attribute-Checkbox {
  width: 9% !important;
}

.tabrow-head-padding {
  padding: 4px;
}

.Field-attmargintop {
  margin-top: 15px;
}

.srno-flex-attribute {
  display: flex;
  justify-content: center;
}

.Note-Multi-Attribute {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.note-padding-left {
  padding-left: 20px;
}

.Note-text-font {
  font-weight: 400;
  margin-bottom: 15px;
  color: red;
}

.atrbt-val-outline {
  outline: none;
}

.Attribute_1_txtcenter {
  padding-left: 4px;
  font-size: 14px;
}

.priority-width {
  width: 5% !important;
}

.mb_5_showattribute {
  width: 200px;
  text-align: center;
}


.table-container-attribute {
  overflow: auto;
  width: 100% !important;
  height: 30%;
}

.table-container-attribute::-webkit-scrollbar {
  display: none;
}

.table-container-attribute {
  scrollbar-width: none;
}

.suggestion_list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
  position: absolute;

}

.suggestion_list li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestion_list li:hover {
  background-color: #f0f0f0;
}

.suggestion_list::-webkit-scrollbar {
  display: none;
}

.sugg-margin-top {
  margin-top: 5px;
  width: 22%;
}

.sugg-Attrib-val {
  width: 44%;
}

.tableCardSide {
  width: 15px;
  height: 15px;
  text-align: center;
}

.thead_scroll_col12 {
  height: 35px;
}
  

  /* ***************{Card}****************** */
  .MScard {
    width: 140px;
    height: 125px;
    top: 5px;
    border-radius: 13px;
    background: #f5f5f5;
    padding: 0.6rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    position: absolute;
    left: 30px;
    z-index: 1;
   }
   .MSCard2{
    width: 87px;
    height: 42px;
    border-radius: 13px;
    background: #f5f5f5;
    position: absolute;
    padding: 0.6rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    left: 42px;
    top: -3px;
    z-index: 1;

   }
   
   .card-details {
    color: black;
    height: 100%;
    /* display: grid;
    place-content: center; */
   }
   
   .card-button {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
   }
   
   .text-body {
    color: rgb(134, 134, 134);
   }
   
   /*Text*/
   .text-title {
    font-size: 14px;
    font-weight: bold;
    padding: 0px !important;
    margin: -1px;
    color: #5e5e5e;
   }

   .MScard:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
   }
   .MSCard2:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
   }
   
   .MScard:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
   }
   .MSCard2:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
   }
   .Text_H7:hover{
    border-color: #008bf8;
    font-size: 15px;
    color: black;
   }




