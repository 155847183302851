/* .homecheckup-container {
  padding: 20px;
} */

.navigation-on-top {
  display: flex;
  justify-content: space-evenly;
  background-color: thistle;
  height: 60px;
  align-items: center;
  flex-wrap: wrap;
}

.tab-sett {
  width: 11%;
  color: #4f4c4c;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* min-width: 100px; */
  /* border: 1px solid rgb(209, 209, 209); */
  margin-left: 8px;
  padding: 0 10px;
  background-color: #f3f3ff;
  box-shadow: 1px 1px 5px #cecece;
}

.tab-sett.active {
  background-color: #653a79;
  border-radius: 0 !important;
  color: #fff;
  border: 1px solid #653a79;
}

.tab-nav {
  display: flex;
}
