/* src/Modal.css */
.modal {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff !important;
    margin: auto;
    padding: 20px;
    top: 140px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.upload-message {
    display: block;
    margin: 10px 0;
    padding: 5px;
    color: green;

    /* Green background */
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
}


.input-field {
    padding: 8px;
    outline: none;
    width: 100%;
    margin: 5px 0px;
}