.heading-setting {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #e1effd;
  justify-content: center;
  margin: auto;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
}

.grid-item-container {
  padding: 4px;
  font-size: 14px;
}
