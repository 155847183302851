@media (max-width: 550px) {
    .linksDiv {
        margin: 5px 5px;
    }

    .linksDiv,
    i,
    label {
        color: black;
    }

    .sideBarDiv {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
    }

    .listDiv {
        background-color: white;
        border-radius: 10px;
        border: 1px solid lightgrey;
    }

    .statusDiv {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* ********************** */
    /*************MH CHEF List****************** */

    .MH-Box {
        display: flex;
        justify-content: space-between;
        width: 30%;
        /* background-color: #f0f1fa; */
        padding: 20px;
        margin-top: 0px;


    }

    .flex-MH {
        display: flex;
        background-color: white;
        justify-content: space-between;
    }

    .fs-121-MH {
        font-size: 14px;
        color: #1e1d1d;
        font-weight: 600;
    }

    .MH-Head-Top {
        margin-top: 10px;
    }

    .Bg-Completed {
        /* background-color: rgb(239, 250, 239); */
        background-color: rgba(49, 215, 49, 0.23);

    }

    .Bg-Process {
        /* background-color: rgb(240, 241, 250); */
        /* background-color: rgb(241, 239, 224); */
        background-color: rgba(232, 213, 72, 0.23);
    }

    .Bg-Queue {
        /* background-color: rgb(241, 239, 224); */
        /* background-color: rgba(255, 238, 220, 0.39); */
        background-color: rgba(249, 154, 154, 0.39)
    }

    .Bg-0-elipse {
        background-color: #212e4f;
        width: 20px;
        height: 20px;
        display: flex;
        text-align: center;
        color: #ffffff;
        border-radius: 15%;
        cursor: pointer;
        transition: background-color 0.3s ease;
        align-items: center;
        justify-content: center;
    }

    .Bg-0-elipse:hover {
        background-color: #1a73e8;
    }

    .Flex-Center-MH {
        display: flex;
        justify-content: center;
    }

    .MHBoxDiv {
        border: 1px solid lightgrey;
        border-radius: 10px;
        padding: 15px;
        margin: 10px;
        box-shadow: 2px 2px 16px #e5e5e5;
    }

}