* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

/* MAIN DIV */

.main-div {
  display: flex;
  align-items: center;
  /* flex-direction: row; */
  background-color: white;
  gap: 20px;
  /* flex-wrap: wrap; */
  /* padding: 0px; */
  /* width: 100%; */
  min-height: 100%;
  max-height: max-content;
}

.left-side {
  width: 45%;
  min-height: 40%;
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 1px solid black; */
  position: relative;
  left: 40px;

  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.left-side-section {
  width: 75%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  /* padding-left: 10px;
    padding-right: 10px; */
  flex-direction: column;
  font-size: 15px;
  background-color: white;
  height: 50px;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);
  margin-bottom: 10px;
}

.left-side-section-option {
  width: 100%;
  height: 100%;
  outline: none;

  font-size: 1rem;
  border: 0px transparent;
  -webkit-appearance: none;
  appearance: none;
}

.left-side-img-section {
  width: 80%;
  min-height: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.left-side-img-section-name {
  width: 100%;
  font-size: 15px;
}

.left-side-img-section-img {
  width: 100%;
  height: 180px;
  border: 1.5px solid rgb(204, 204, 204);
  margin-bottom: 15px;
}

.left-side-img-upload {
  display: none;
}

.left-side-section-btn {
  width: 90%;
  height: 40px;
  padding: 5px;
  display: flex;
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
}

.section-btn {
  min-width: 180px;
  max-width: max-content;
  padding: 0px 10px;
  height: 100%;
  margin-right: 10px;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}

/* Right side */

.right-side {
  width: 45%;
  height: auto;
  padding: 10px;
  display: flex;
  position: absolute;
  right: 40px;
  top: 80px;
  /* border: 2px solid red; */
  align-items: center;
  flex-direction: column;
}

.right-side-box1 {
  width: 100%;
  height: auto;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* right side box */

.right-side-box1-name {
  width: 100%;
  height: auto;
  font-size: 1rem;
  padding-left: 10px;
  font-weight: 700;
  margin-bottom: 20px;
}

.right-side-section {
  width: 100%;
  min-height: 60px;
  max-height: max-content;
  display: flex;
  align-items: flex-start;
  background-color: white;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.5rem;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.right-side-label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.right-side-section-input {
  width: 100%;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
  height: 100%;
  padding-top: 4px;
  outline: none;
  font-size: 1rem;
  border: 0px transparent;
  /* border-bottom:1px solid black; */
}

.right-side-btn {
  min-width: 100px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 40px;
}

/* RIGHT SIDE BOX 2 */

.right-side-select-section {
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  gap: 20px;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  /* flex-direction: column; */
  border: 1px solid rgb(204, 204, 204);
  flex-wrap: wrap;
  justify-content: center;
}

.right-side-inside-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  /* border: 1px solid red; */
}

/* label name */
.right-side-box2-label {
  font-size: 1.2rem;
  width: 100%;

  padding-left: 5px;
  position: relative;
  top: 20px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  top: 8px;
}

.right-side-select {
  width: 100%;
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  border: none;
}

/* rightside-box2 */

.right-side-box2-btn {
  width: 25%;
  height: 40px;
  font-size: 1.1rem;
}

.double-btn {
  width: auto;
  min-width: 130px;
  padding: 0 10px;
}

.double {
  width: 60%;
}

.right-side-upload {
  width: 100%; /* Adjust width as needed */
  margin: 20px; /* Adjust margin as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.right-side-p {
  min-width: 190px;
  width: auto;
  font-size: 1.2rem;
}

.right-side-file-container {
  display: flex;
  align-items: center;
}
.right-side-file-input {
  flex: 1;
  margin-right: 10px;
}

.upload-btn {
  padding: 8px 16px;
  background-color: lavender; /* Adjust button styles */
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.span {
  color: green; /* Adjust color as needed */
  font-style: italic;
  margin-top: 10px;
}

.download {
  padding: 8px 16px;
  background-color: lavender; /* Example button background color */
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.right-side-section-download {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-side-download-info {
  font-weight: bold;
  margin-bottom: 10px;
}

.right-side-third-box {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  /* border: 1px solid green; */
}

.right-side-third-box-first {
  width: 100%;
  height: auto;
}

.mobile-name {
  justify-content: start;
}

.rs-header-design-section {
  width: 100%;
  min-height: 60px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  margin-bottom: 20px;
  background-color: #fff;
}

.rs-label {
  width: 100%;
  position: relative;
  top: 15px;
  background-color: white;
  height: auto;
  pointer-events: none;
  display: flex;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
}

.rs-input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 20px;
}

.rs-save-btn {
  width: 160px;
  height: 30px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.rs-save-div {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.rs-third-box-second {
  /* border: 1px solid yellow; */
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.h2-shopbanner {
  width: 98%;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #e1effd;
  justify-content: flex-start;
  margin: auto;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 15px;
}

.changebordercolor {
  border: 1px solid blue;
  transition: all 200ms;
  top: 0px;
}

.left-side-top {
  top: -14px;
  left: 10px;
  z-index: 20;
  background-color: white;
  width: fit-content;
  padding: auto;
  border-radius: 4px;
  padding-right: 8px;
  /* border: 1px solid black; */
  transition: all 200ms ease-in-out;
}

.floating-label-input {
  position: relative;
  margin-top: 20px;
  width: 100%;
  margin-left: 110px;
}

.floating-label-input input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.floating-label-input label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #aaa;
  transition: all 0.2s ease;
  pointer-events: none;
}

.floating-label-input.focused label,
.floating-label-input input:not(:placeholder-shown) + label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #000;
  background: white;
  padding: 0 5px;
}

.floating-label-input.focused input {
  border-color: #0d6efd;
}

.floating-dropdown {
  position: relative;
  margin-top: 20px;
  width: 80%;
}

.floating-dropdown select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  /* appearance: none;  */
  background: transparent;
}

.floating-dropdown label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #aaa;
  transition: all 0.2s ease;
  pointer-events: none;
}

.floating-dropdown.focused label,
.floating-dropdown select:not(:placeholder-shown) + label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #000;
  background: white;
  padding: 0 5px;
}

.floating-dropdown select.focused {
  border-color: #0d6efd;
}

.table-container-tbn {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  margin: 0 auto;
}

.left-content-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.main-dropdown-container-shop {
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 15px;
  /* justify-content: space-evenly; */
}

.dropdown-outer-class {
  width: 100%;
}

.Mob-site {
  display: contents;
  width: 100%;
}

.add-new-image-banner {
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 28px;
}
