.cl-toggle-switch {
  position: relative;
  display: grid;
  padding-top: 10px;
  justify-content: flex-end;
  padding-right: 25px;
}

.ToggleSwitchActive {
  position: relative;
  display: grid;
  padding-top: 10px;
  justify-content: flex-end;
  padding-right: 10px;
}

.CheckBoxType {
  cursor: pointer;
}

.cl-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Input */
.cl-switch>input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  right: 6px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(0, 0, 0, 0.38);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Track */
.cl-switch>span::before {
  content: "";
  /* float: right; */
  display: inline-block;
  margin: 5px 0 5px 10px;
  border-radius: 7px;
  width: 36px;
  height: 14px;
  background-color: rgb(0, 0, 0, 0.38);
  vertical-align: top;
  transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.cl-switch>span::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.cl-switch>input:checked {
  right: -10px;
  background-color: #85b8b7;
}

.cl-switch>input:checked+span::before {
  background-color: #85b8b7;
}

.cl-switch>input:checked+span::after {
  background-color: #018786;
  transform: translateX(16px);
}

/* Hover, Focus */
.cl-switch:hover>input {
  opacity: 0.04;
}

.cl-switch>input:focus {
  opacity: 0.12;
}

.cl-switch:hover>input:focus {
  opacity: 0.16;
}

/* Active */
.cl-switch>input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.cl-switch>input:active+span::before {
  background-color: #8f8f8f;
}

.cl-switch>input:checked:active+span::before {
  background-color: #85b8b7;
}

/* Disabled */
.cl-switch>input:disabled {
  opacity: 0;
}

.cl-switch>input:disabled+span::before {
  background-color: #ddd;
}

.cl-switch>input:checked:disabled+span::before {
  background-color: #bfdbda;
}

.cl-switch>input:checked:disabled+span::after {
  background-color: #61b5b4;
}



/* Modal.css */
.modal {
  display: block;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #2b2725c9;
  margin: 6% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 450px;
}

.modal-content2 {
  background-color: #2b2725c9;
  /* margin: 0% auto; */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* top: -50%; */
  max-width: 750px;
  border-radius: 10px;
  position: absolute;
}

.modal-content-bbm {
  background-color: #fefefe;
  margin: 6% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 550px;
  border-radius: 5px;
  box-shadow: 2px 1px 12px gray;
}

.modal-content1 {
  background-color: #fefefe;
  margin: 4% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 65%;
}

.ModalBoxProductSetting {
  background-color: #2b2725c9;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  border-radius: 10px;
}

.ModalContentWrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.close {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  top: -17px;
  font-weight: bold;
  cursor: pointer;
  right: 2px;
}

.closeTableModal {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  top: -36px;
  font-weight: bold;
  cursor: pointer;
  right: -13px;
}

.close:hover {

  color: red;
}

.close-attribute:hover {

  color: red;
}

.close-category:hover {

  color: red;
}


.modal-1 {
  width: 100%;
}

.modal-2 {
  width: 100%;
}

.modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(56, 185, 224);
  color: white;
  border: none;
  height: 25px;
  font-size: 15px;
  border-radius: 5px;
  width: 90px;
  padding: 3px;
  box-shadow: 1px 2px 2px gray;
}

.modal-h {
  color: #fff;
  font-size: 19px;
  padding-left: 25px;
  font-weight: 600;
}

.modal-title {
  position: relative;
}

/* ImageUpload.css */
.image-upload-container {
  position: relative;
}

.image-preview-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.image-preview {
  max-width: 150px;
  max-height: 150px;
}

.close-button {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}

.upload-button-container {
  display: inline-block;
}

.upload-button-label {
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
}

/************category********************/

.modal-input-box {
  display: grid;
}

.modal-input-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.modal-b1 {
  margin-right: 10px;
}

.modal-input-1 {
  margin-left: 10px;
  margin-left: 10px;
  height: 34px;
  border-radius: 5px;
}

.modal-box-m1 {
  color: #494545;
  font-size: 19px;
  padding-left: 10px;
}

.modal-input-1 {
  padding-left: 10px;
}

.modal-box-a1 {
  padding-left: 12px;
  padding-bottom: 3px;
}

.modal-content-im {
  padding-top: 15px;
  padding-right: 15px;
}


.ModalContentModalSettingP {
  /* border-top: 1px solid #e0dada; */
  padding: 5px;
  padding-top: 12px;
  width: 100%;
}

/***************tabs*************/

.tab-btn-box {
  display: flex;
  background-color: #e6e6fa;
  align-items: center;
  border-bottom: 1px solid #c8c8c8;
  padding: 5px 3px;
}

.tab-btn-box1 {
  display: flex;
  background-color: #d5d5d5;
  height: 40px;
  /* border: 1px solid #c3bcbc; */
  /* background-color: #ffb225; */
  background-color: #5e709e !important;
  padding: 0px;
}

.tab-content-box {

  /* border: 1px solid #c3bcbc; */
  border-top: none;
}

.tab-content-box::-webkit-scrollbar {
  display: none;
}

.tab-content-box {
  scrollbar-width: none;
}

.tab-content-box1 {
  /* border: 1px solid #c3bcbc; */
  border-top: none;
}

.tab-btn-1 {
  width: 10%;
  color: #4f4c4c;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-btn-2 {
  width: 10%;
  color: #4f4c4c;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-btn-3 {
  width: 10%;
  color: #4f4c4c;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-btn-4 {
  width: 10%;
  color: #4f4c4c;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-btn {
  width: 10%;
  color: #4f4c4c;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab-btn-1:hover {
  background-color: #dcd4d4;
  transition: all 0.2s ease-out;
}

.tab-btn-2:hover {
  background-color: #dcd4d4;
  transition: all 0.2s ease-out;
}

.tab-btn-3:hover {
  background-color: #dcd4d4;
  transition: all 0.2s ease-out;
}

.tab-btn-4:hover {
  background-color: #dcd4d4;
  transition: all 0.2s ease-out;
}

.tab-btn-5:hover {
  background-color: #dcd4d4;
  transition: all 0.2s ease-out;
}

.tab-btn-1.active {
  background-color: #ccc;
}

.tab-btn-1:focus {
  background-color: rgb(148, 148, 147);

}

.tab-btn-1.active {
  background-color: #dcd4d4;
  transition: all 0.2s ease-out;
}

.tab-button.active {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}

.tab-btn:focus {
  background-color: #007bff;
}


.tab-content-1 {
  width: 100%;
  margin-top: 0;
  display: flex;
}

.tab-content-2 {

  width: 100%;
}

.tab-content-3 {

  margin-top: 0;
  width: 100%;
}

.tab-head {
  width: 100%;
}

.text-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.text-editor {
  width: 400px;
  height: 200px;
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.input-box-editer {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 2px;
  margin-bottom: 10px;
  /* height: 99px; */
  width: 100%;
  position: relative;
}

.ql-editor {
  height: 200px !important;
}

.active {
  /* background-color: ghostwhite; */
  background-color: white;
  color: black;
  border-radius: 0px;
}

.tabs {
  display: flex;
}

.tab {
  width: 11%;
  color: #4f4c4c;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 120px;
  /* border: 1px solid rgb(209, 209, 209); */
  margin-left: 8px;
  padding: 0 10px;
  background-color: #f3f3ff;
  box-shadow: 1px 1px 5px #cecece;
}

.tab1 {
  width: 16%;
  color: #4f4c4c;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab.active {
  background-color: #653a79;
  border-radius: 0 !important;
  color: #fff;
  border: 1px solid #653a79;
}

.activeBlue {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.tab1.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.modal-content-attribute {
  background-color: #fefefe;
  margin: 35px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  height: 530px;

}

.close-attribute {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  top: -22px;
  font-weight: bold;
  cursor: pointer;
  right: -3px;
}

.close-category {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  top: -16px;
  font-weight: bold;
  cursor: pointer;
  right: 1px;
}

.modal-qnt-1 {
  width: 75px;
  height: 25px;
  border: none !important;
  box-shadow: none !important;

}

.modal-qnt-2 {
  width: 100px;
  height: 25px;
  border: none !important;
  box-shadow: none !important;
}

.modal-qnt-3 {
  width: 100px;
  height: 25px;
  border: none !important;
  box-shadow: none !important;
}

.modal-qnt-4 {
  width: 100px;
  height: 25px;
  border: none !important;
  box-shadow: none !important;
}

.modal-qnt-5 {
  width: 100px;
  height: 25px;
  border: none !important;
  box-shadow: none !important;
}

.modal-qnt-6 {
  width: 100px;
  height: 25px;
  border: none !important;
  box-shadow: none !important;
}


.table-data-1 {
  border: 1px solid #dddddd;
}

.table-attribative {
  display: flex;
}

.table-data-iv {
  width: 100px;
  height: 25px;
  padding-left: 6px;
}

.table-attribute-h1 {
  display: flex;
  padding-left: 20px;
}

.table-attribute-h3 {
  font-size: 20px;
}

.table-attribute-h4 {
  padding-bottom: 5px;
  padding-left: 10px;
}

.scroller {
  height: 370px !important;
  overflow-x: hidden;
  border: 1px solid #dddddd;
}

.scroller::-webkit-scrollbar {
  width: 0px;
}

.scroller {
  scrollbar-width: none;
}

.table-modal {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;

}

/* ********************checkbox********************** */
.checkbox-variant {
  height: 14px !important;
}

.checkbox-btn-delete {
  border: none;
  font-size: 16px;
  color: #c72626;
  padding-left: 10px;
  background-color: none;
}

.checkbox-input-1 {
  border: 1.5px solid #ccc;

  color: #555;
  font-size: 14px;
  height: 35px;
  word-wrap: normal;
  text-transform: none;
  outline: none;
  border-radius: 4px;
  width: 265px;
  height: 30px;
}

.checkbox-add-value {
  margin-top: 5px;
  color: #080808;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px;
  width: 50px;
  font-weight: 600;
  height: 32px;
  border: 1px solid gray;
}

.checkbox-btn-submit {
  margin-top: 25px;
  color: #080808;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px;
  width: 100px;
  font-weight: 600;
  height: 32px;
  border: 1px solid gray;

}

.checkbox-btn-add {
  color: #080808;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px;
  width: 85px;
  font-weight: 600;
  height: 32px;
  border: 1px solid gray;
}

.checkbox-btn-addField {
  color: #080808;
  border-radius: 4px;
  font-size: 13px;
  padding: 2px;
  width: 85px;
  font-weight: 600;
  height: 28px;
  border: 1px solid gray;
}

.checkbox-btn-done {
  color: #080808;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px;
  width: 120px;
  font-weight: 600;
  height: 32px;
  border: 1px solid gray;
}

.checkbox-table-1 {
  width: 50%;
}

.checkbox-table-b2 {

  color: #555;
  font-size: 14px;

  word-wrap: normal;
  text-transform: none;
  outline: none;
  border-radius: 4px;
}

.checkbox-input-2 {
  border: 1.5px solid #ccc;
  color: #555;
  font-size: 14px;
  height: 30px;
  word-wrap: normal;
  text-transform: none;
  outline: none;
  border-radius: 4px;

}

.checkbox-table-b2 {
  width: 150px;
}

.checkbox-table-t1 {
  padding: 4px;
  font-size: 13px;
}

.cakcbox-label-1 {
  font-size: 14px;
  color: #fffffe;
}


/* ********************checkbox********************** */


/* ********************checkbox sale Invoice********************** */
/* Hide the default checkbox */
.container input {
  display: none;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #2196F300;
  border-radius: 0.25em;
  transition: all 0.25s;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  transform: rotate(0deg);
  border: 0.1em solid black;
  left: 0;
  top: 0;
  width: 1.05em;
  height: 1.05em;
  border-radius: 0.25em;
  transition: all 0.25s, border-width 0.1s;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border-color: #fff0 white white #fff0;
  border-width: 0 0.15em 0.15em 0;
  border-radius: 0em;
  transform: rotate(45deg);
}

/* ********************checkbox Sale Invoice********************** */
/* ********************Table Scrollable start********************** */
.table-scrol1 {
  table-layout: fixed !important;
  border-collapse: collapse !important;
  margin: 0 auto !important;
  /* border: 1px solid #eee !important; */
}

.table-scrol2 {
  table-layout: fixed !important;
  border-collapse: collapse !important;
  margin: 0 auto !important;
  border: 1px solid #eee !important;
}

/* .table-scrol2::-webkit-scrollbar {
        display: none;
    } */

.table-tbody {
  box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, .1) !important;
  position: relative !important;
  background-color: #5e709e;
}

.table-tbody2 {
  box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, .1) !important;
  position: relative !important;
  background-color: white;
}

.table-tbody3 {
  box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, .1) !important;
  position: relative !important;
  background-color: #c9c9c9;
}

.table-tbody .table-tr1 {
  font-size: 12px;
  display: flex !important;
  position: relative !important;
}

.table-tr2 {
  align-items: center;
  text-align: center;
  font-size: 12px;
  /* display: block !important; */
  /* position: relative !important; */
  display: flex;
  /* justify-content: space-between; */
  /* max-width: 1220px; */
  width: 100%;
}

.tableDoubleClick {
  height: 32px;
  align-items: baseline;
  text-align: center;
  font-size: 12px;
  /* display: block !important; */
  position: relative !important;
  display: flex;
  justify-content: space-between;
  /* max-width: 1220px; */
  width: 100%;
}

.tab-btn-box2 {
  display: flex;
  background-color: #d5d5d5;
  font-size: 12px;
  width: 100%;
  padding: 0px 5px;
}

.tab-btn-box4 {
  display: flex;
  /* background-color: #d5d5d5; */
  font-size: 12px;
  width: 45%;
}

.table_main_01 {
  padding-bottom: 30px;
  padding-top: 5px;
}

/* .table-td-2:nth-child(2),
.table-th-1:nth-child(2) {
    min-width: 200px !important;
} */
.table-th-1 {
  width: 2%;

}

.table-td-2 {

  text-align: left;
}

.table-th-6 {
  /* padding-left: 16px;
  padding-right: 10px; */
  text-align: center;
  width: 100px;
}

.table-th-5 {
  /* padding-left: 16px;
  padding-right: 10px; */
  text-align: center;
  width: 102px;
}

.table-th-4 {
  /* padding-left: 16px;
  padding-right: 10px; */
  text-align: center;
  width: 60px;
}

.table-td-3 {
  width: 75%;
}

.table-container-tbody {
  display: block;
  /* overflow: auto; */
  width: 100% !important;
  /* height: 257px; */
}

.table-container-tbody::-webkit-scrollbar {
  display: none;
}

.table-container-tbody {
  scrollbar-width: none;
  /* max-height: 140px; */
  max-height: 127px;
  /* max-height: 180px; */
  overflow: scroll;
  margin: 0px;
}

.BatchWise-container-tbody {
  display: block;
  overflow: auto;
  width: 100% !important;
  height: 190px;
}

/* .table-container-tbody .table-tr1:nth-child(2n) {
  background-color: #dddddd;
} */
.OverTable4:hover {
  background-color: #beddff;
}

.table-tr1:hover {
  background-color: #beddff;
}


.itemattribute-select {

  width: 100%;
  height: 30px;
  border: 1px solid #e7dede;
  font-size: 14px;
  /* font-weight: 600; */
}

.itemattribute-select option {
  font-size: 14px;
}

.itemattribute-headre-select {
  background-color: transparent;
  border: none;
  color: white;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 5px;
}

.itemattribute-headre-select option {
  color: black;
}

/* ********************Table Scrollable end********************** */
/* ********************table start********************** */

/* ********************table end********************** */
.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.mmw-1 {
  padding: 12px 0px 0px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}


.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.input-box4 {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  margin-bottom: 20px;
  height: 20px;
  position: relative;
  width: 180px;
}

/* ***********search************* */
.search_panel_m1 {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 190px;
}

.search_panel_input {
  width: 90%;
  height: 35px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  /* background-color: #f3f3f4; */
  color: #0d0c22;
  transition: .3s ease;
}

.search_panel_input::placeholder {
  color: #9e9ea7;
}

.search_panel_input:focus {
  outline: none;
  border-color: rgba(234, 76, 137, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.search_panel_icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 3rem;
  height: 1rem;
}

.search_panel_iconSaleV {
  position: absolute;
  left: 0rem;
  fill: #9e9ea7;
  width: 3rem;
  height: 1rem;
}

.search_panel_icon2 {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

.search_panel_left {
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search_panel_hd1 {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Print_btn {
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  padding: 4px;
  width: 40px;
  font-weight: 600;
  height: 35px;
  border: 1px solid #d0caca;
  background: purple;
}

/* .Print_btn:hover {
  background-color: #b2b2b2;
  transition: all 0.2s ease-out;
} */
.xls_btn {
  color: #f0eaea;
  border-radius: 4px;
  font-size: 13px;
  padding: 4px;
  width: 40px;
  font-weight: 600;
  height: 35px;
  border: 1px solid #d0caca;
  background: #ff9900;
}

/* .xls_btn:hover {
  background-color: #b2b2b2;
  transition: all 0.2s ease-out;
} */
.add_btn {
  border: none;
  color: #f0eaea;
  background-color: #2a9f79;
  border-radius: 5px;
  font-size: 15px;
  padding: 4px;
  width: 40px;
  height: 35px;
  text-decoration: #007bff;
  font-weight: 600;
}

/* .add_btn:hover {
  background-color: #3d3dff;
  transition: all 0.2s ease-out;
  color: white;
} */
.table_reset_btn {
  border: 1px solid #d0caca;
  color: #f0eaea;
  border-radius: 5px;
  font-size: 15px;
  width: 40px;
  height: 35px;
  background: #ff4163;
  font-weight: 600;
}

/* .table_reset_btn:hover {
  background-color: #b2b2b2;
  transition: all 0.2s ease-out;
} */
.table_search_btn {
  border: 1px solid #d0caca;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  padding: 4px;
  width: 40px;
  font-weight: 600;
  height: 35px;
  background: teal;
}

/* .table_search_btn:hover {
  background-color: #b2b2b2;
  transition: all 0.2s ease-out;
} */
.search_panel_right {
  padding-top: 5px;
  padding-right: 25px;
  width: 50%;
}

.search_panel_f1 {
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
}

.table_hd1 {
  display: block;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  /* height: 100%; */
  --scrollbar-thumb-bg: #888;
  --scrollbar-hover-bg: #555;
  --moz-scrollbar-bg: #fff;
}

.td_col5 {
  padding-left: 12px;
  border-left: 1px solid #e4e4e4;
  font-size: 14px;
}

.thead_scroll_col1 {
  height: 45px;
}

.col-sm-n1 {
  display: flex;
  margin-bottom: 0px;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 8px;
  width: 400px;
}

.ModalBoxItem_Left {
  width: 45%;
}

.ModalBoxItem_Right {
  width: 55%;
}

.ModalBoxItem_FullHeight {
  width: 100%;
  display: flex;
}

.im-icon-8 {
  color: black;
}

.ContainerAttributeTable {
  display: block;
  overflow: auto;
  width: 100% !important;
  height: 154px;
}

.ItemMastertableHead {
  font-size: 11px;
  display: block !important;
  position: relative !important;
  background-color: #5e709e;
  color: white;
}

.ItemMastertablebody {
  box-shadow: none !important;
  width: 100%;
  margin: auto;
  height: 26px;
  border-radius: 1px !important;
  font-size: 12px;
  display: flex;
  align-items: baseline;
}

.PriceTableBody {
  box-shadow: none !important;
  width: 100%;
  margin: auto;
  height: 22px;
  border-radius: 1px !important;
  font-size: 13px;
  display: flex;
  align-items: baseline;
}

.ItemMastertablebody2 {
  box-shadow: none !important;
  width: 90%;
  margin: auto;
  height: 22px;
  border-radius: 1px !important;
  font-size: 11px;
  display: flex;
  align-items: baseline;
}

.TableTopChecklabel {
  font-size: 13px;
  display: flex;
  align-items: center;
}

.TableTopCheckHead {
  display: flex;
  justify-content: space-around;
}

.TableTrFile {
  width: 7%;
}

.TableTrFile5 {
  padding-left: 2%;
  text-align: center;
  width: 102px;
}

.TableTrFile7 {
  padding-left: 2%;
  text-align: center;
  width: 120px;
}

.TableInputboxText:valid~label {
  transform: translateY(-8%) scale(0.9);
  background-color: #ffffffee !important;
  padding: 0 0.2em !important;
  color: #7e7c7c !important;
  font-size: 12px;
  line-height: 10px;
}

.ModalFileInput {
  border: 1.5px solid #ccc;
  width: 100%;
  color: #555;
  font-size: 14px;
  height: 35px;
  word-wrap: normal;
  text-transform: none;
  outline: none;
  border-radius: 4px;
  background-color: none !important;
  background: none;
}

.TAbleInputLabel05:valid~label {
  transform: translateY(-8%) scale(0.9);
  background-color: #ffffffee !important;
  padding: 0 0.2em !important;
  color: #7e7c7c !important;
  font-size: 14px;
}

.HTR01 {
  padding-top: 6px;
}

.table-tr22 {
  display: flex;
  justify-content: space-between;
}

.TAbleInputLabel05 {
  position: absolute;
  color: #8d8a8a;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 24px;
  top: -2px;
  font-size: 14px;
}

.TableInputboxText {
  border: 1.5px solid #ccc;
  color: #555;
  font-size: 14px;
  height: 30px;
  word-wrap: normal;
  text-transform: none;
  outline: none;
  border-radius: 4px;
  background: none;
}

.PriceTrFile5 {
  padding-left: 4px;
  padding-right: 16px;
  text-align: center;
  width: 102px;
}

.TableDownDiv {
  display: flex;
  justify-content: space-around;
  background-color: #5e709e;
  padding: 5px;
  font-size: 11px;
  color: white;
}

.TableTbodyScroll {
  height: auto;
  max-height: 155px;
  overflow-y: scroll;
  /* border: 1px solid #c5c1c1; */
}


.custum-file-upload {
  height: 45px;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 0px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #e8e8e8;
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px #e8e8e8;
}

.custum-file-upload .iconLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .iconLogo svg {
  height: 21px;
  fill: #c5c5c5;
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: #e8e8e8;
}

.custum-file-upload input {
  display: none;
}

/* ***************************{ToolTip Css}****************************** */

/* This is an example, feel free to delete this code */
.tooltip-containerform {
  --background: #333333;
  --color: #e8e8e8;
  position: relative;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 18px;
  font-weight: 600;
  color: var(--color);
  /* padding: 0.7em 1.8em; */
  border-radius: 5px;
  text-transform: uppercase;
  /* height: 60px;
  width: 180px; */
  display: grid;
  place-items: center;
  /* border: 2px solid var(--color); */
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transform-origin: -100%;
  transform: scale(1);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}



.tooltipForm {
  position: absolute;
  top: 0;
  left: 60%;
  transform: translateX(-50%);
  /* padding: 0.3em 0.6em; */
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  background: var(--background);
  z-index: 1;
  border-radius: 8px;
  scale: 0;
  transform-origin: 0 0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  text-align: center;
  width: max-content;
  padding: 0px 5px;
}

.tooltipForm::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(--background);
}

.tooltip-containerform:hover .tooltipForm {
  top: -100%;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  scale: 1;
  animation: shake 0.5s ease-in-out both;
}





.tooltip-containerform:hover .text {
  opacity: 0;
  top: 0%;
  left: 100%;
  transform: scale(0);
}

@keyframes shake {
  0% {
    rotate: 0;
  }

  25% {
    rotate: 7deg;
  }

  50% {
    rotate: -7deg;
  }

  75% {
    rotate: 1deg;
  }

  100% {
    rotate: 0;
  }
}

/* ***************************{ToolTip Css End}****************************** */


/* **********************************{LogIn Css}*********************************** */

.Formheader {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.form-sidebar-yesbm-logo {
  height: 45px;
  overflow: hidden;
  text-align: center;
}

.ReferralHead {
  width: 145px;
  border: 1px;
  border-radius: 15px;
  background: teal;
  color: white;
  text-align: center;
}

.IconDelRef {
  position: absolute;
  right: 17px;
  top: 8px;
  font-size: 20px;
  color: #656363;
  cursor: pointer;
}


.form-container {
  width: 350px;
  height: 470px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 20px;
}

.FormTitle {
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin: 10px 0 30px 0;
  font-size: 16px;
  font-weight: 600;
  color: #575555;
  padding-left: 5px;

}

.FormInputBox:valid~label {
  transform: translateY(-50%) scale(0.8);
  /* background-color: #ffffff; */
  padding: 0 0.2em;
  color: #6e6e6e;
  font-size: 16px;
}

.formMainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 15px;
}

.FormInputBox {
  border-radius: 20px;
  border: 1px solid #c0c0c0;
  outline: 0 !important;
  box-sizing: border-box;
  padding: 12px 15px;
  width: 100%;
  color: #626262;
  position: relative;
}

.FormInputBox:valid {
  outline: none !important;
  border: 1.5px solid #e7dede;
  /* box-shadow: 1px 1px 1px #aba69c; */
  /* font-size: 16px; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* input:valid {
  outline: none !important;
  border: 1.5px solid #ccc;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

.InputTextD {
  width: 100%;
  position: relative;
}

.formTextLabelUser:valid~label {
  transform: translateY(-8%) scale(0.9);
  background-color: #ffffffee !important;
  padding: 0 0.2em !important;
  color: #7e7c7c !important;
  font-size: 14px;
}

.formTextLabelUser {
  position: absolute;
  color: #8d8a8a;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 20px;
  top: -2px;
  font-size: 14px;
  background: white;
}

.formTextLabel {
  position: absolute;
  color: #8d8a8a;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 20px;
  top: -28px;
  font-size: 14px;
  background: white;
}

.page-link {
  text-decoration: underline;
  margin: 0;
  text-align: end;
  color: #747474;
  text-decoration-color: #747474;
}

.page-link-label {
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 9px;
  font-weight: 700;
  color: #747474;
  text-decoration: none;
  text-align: end;
}

.ShowHideIcon {
  position: absolute;
  top: 17px;
  right: 16px;
  font-size: 15px;
}

/* .page-link-label:hover {
  color: #000;
} */

.form-btn {
  padding: 10px 15px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 20px;
  border: 0 !important;
  outline: 0 !important;
  background: teal;
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
}

.form-btn:active {
  box-shadow: none;
}

.sign-up-label {
  text-align: center;
  margin: 0;
  font-size: 10px;
  color: #747474;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.sign-up-link {
  margin-left: 1px;
  font-size: 11px;
  text-decoration: none;
  text-decoration-color: teal;
  color: teal;
  cursor: pointer;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.BrHide {
  border-top: hidden;
  border-left: none;
  border-right: none;
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 15px;
}

.apple-login-button,
.google-login-button {
  border-radius: 20px;
  box-sizing: border-box;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 11px;
  gap: 5px;
}

.apple-login-button {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
}

.google-login-button {
  border: 2px solid #747474;
}

.apple-icon,
.google-icon {
  font-size: 18px;
  margin-bottom: 1px;
}

/* ******************************************** */

.Otpform {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
}

.Otptitle {
  font-size: 20px;
  font-weight: bold;
  color: black
}

.otpMessage {
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 4px;
  text-align: center
}

.Otpinputs {
  margin-top: 10px
}

.Otpinputs input {
  width: 30px;
  height: 30px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 5px;
}

.Otpinputs input:focus {
  border-bottom: 1.5px solid royalblue;
  outline: none;
}

.Otpaction {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background-color: royalblue;
  color: white;
  cursor: pointer;
  align-self: end;
}

/* **********************************{LogIn Css End }*********************************** */


.category-main {
  display: flex;
  cursor: pointer;
}

.sub-category-box {
  /* display: none; */
  margin-left: 20px;
  cursor: pointer;
}

.sub-category-txt {
  display: flex;
  align-items: center;
  height: 22px !important;
  /* background-color: #e9e9e9; */
  padding: 0px 10px;
}

.child-category-txt {
  display: flex;
  align-items: center;
  height: 22px !important;
  /* background-color: #e3e3e3; */
  padding: 0px 10px;
}

.child-category-box {
  margin-left: 30px;
  cursor: pointer;
  /* margin-top: 5px;
   margin-bottom: 5px; */
}

.CategoryBoxPaddingL {
  padding-left: 5px;
}

.drop-down-new {
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
  margin-bottom: 13px;
  max-height: 500px;
  overflow-y: auto;
  /* Use "auto" to show scrollbar only when needed */
}

/* Styling for scrollbar */
.drop-down-new::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.drop-down-new::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the track */
}

.drop-down-new::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the thumb */
  border-radius: 4px;
  /* Roundness of the thumb */
}

/* For Firefox */
.drop-down-new {
  scrollbar-width: thin;
  /* "auto" | "thin" | "none" */
}

.drop-down-new {
  scrollbar-color: #6d6d6d #f1f1f1;
  /* Color of the thumb and track */
}

.modal-feedback {
  font-size: 12px;
}



@media(max-width:549px) {
  .ModalBoxProductSetting {
    background-color: #000000;
    margin: 4% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    border-radius: 10px;
  }

  .ModalContentModalSettingP {
    height: 85vh !important;
    padding: 0;
    padding-top: 5px;
  }

  .gridview2 {
    grid-template-columns: repeat(1, auto);
    padding: 0;
    padding-top: 5px;
  }

  .ModalBoxInputM5 {
    width: 100%;
  }

  .ModalBoxWidthFix {
    width: 100px;
  }

  .ModalboxTitleInput {
    width: 30%;
    padding: 0;
    padding-left: 0;
    padding-bottom: 0px;
  }

  .size-a1 {
    width: 60%;
  }



}