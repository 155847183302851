.sidebarmain-container {
  display: flex;
}

main {
  padding: 4px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(0, 7, 61);
  color: #ffffff;
  /* border: 1px solid white; */
  height: 100vh;
  overflow-y: auto;
  border-top: 1px solid white;
}

.sidebartop_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo {
  font-size: 18px;
}

.sidebar-bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.sidebar-routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
}

.link:hover {
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.headlink_text {
  white-space: nowrap;
  font-size: 15px;
}

.childlink_text {
  white-space: nowrap;
  font-size: 15px;
}

.subchildlink_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.leftmaster-company {
  max-width: 20%;
}

.mastercomapny-container {
  /* display: flex; */
  width: 100%;
}

/* .rightmaster-company {
  width: 100%;
} */
.endright-cmpmaster {
  width: 3.5%;
  margin-right: 6px;
  margin-top: 6px;
  flex-direction: column;
}

.smallsbaricon-container {
  overflow: hidden;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  padding-top: 0vh;
  height: calc(100vh - 30px);
  background-color: #edeff4;
  border: 1px solid #cfcfcf;
  /* border-radius: 10px; */
  /* border-top-left-radius: 0px; */
  /* border-top-right-radius: 0px; */
  position: fixed;
  width: 45px;
  transition: all;
  transition-duration: 500ms;
}


.smallsbaricon-container:hover {
  z-index: 10;
  width: 220px;
  position: fixed;
  right: 0;
}

.smallsbaricon-container div {
  display: flex;
  align-items: center;
  width: 200px;
}


.smallsbaricon-container a {
  /* margin-bottom: 10px; */
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}

.sidebar-button-dynamic i {

  width: 43px;
  font-size: 13px;
}



.design-smallsbar {
  color: #000000;
  width: 100%;
  cursor: pointer;
  font-size: 30px;
  padding-top: 10px;
  /* background-color: gray; */
  /* margin-top: 5vh; */
}

.smallsbaricon-maincontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hr-design {
  height: 2px !important;
  background-color: rgb(233, 232, 232);
  width: 80%;
  margin-top: 30px;
  margin-bottom: -1px !important;
}

.inout-smallbar {
  display: flex;
  justify-content: center;
  background-color: rgb(224, 224, 224);
  margin-top: 0vh;
  padding: 6px;
  position: absolute;
  border-radius: 17px;
  bottom: 2px;
  right: 0px;
  width: 45px;
  position: fixed;
  z-index: 100;
}


.small-sidebar {
  width: 45px;
  transition: width 0.4s ease;
  height: 100%;
}

.small-sidebar-hidden {
  width: 0%;
  transition: width 0.4s ease;
}

.rotate-180icon {
  transform: rotate(180deg);
  transition: transform 0.4s ease;
}

.rotate-neg180icon {
  transform: rotate(0deg);
  transition: transform 0.4s ease;
}

.sidebar::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
}

/* Hide scrollbar on Firefox */
.sidebar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.flexdiv-sidebar {
  display: flex;
  flex-direction: column;
}

.subroute-flex {
  display: flex;
  padding-left: 8px;
  gap: 10px
}

.subchild_link {
  display: flex;
  padding-left: 28px;
  padding-top: 12px;
  gap: 10px
}

.subchild_link {
  text-decoration: none;
  color: rgb(233, 233, 233);
}

.subchild_icon {
  color: rgb(233, 233, 233)white;
}

@media(max-width:650px) {
  .inout-smallbar {
    display: none;

  }
}