.input-box-container {
  position: relative;
  margin: 20px 0;
}

.input-box {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-box-label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s;
}

.input-box-label.filled {
  top: 0;
  font-size: 14px;
  color: #333;
  background-color: white;
}

.input-box:focus ~ .input-box-label {
  top: 0;
  font-size: 14px;
  color: #333;
}
.input-box:focus{
  outline: none;
  border-color: #007bff;
}


.input-box-container.focused .input-box-label {
  top: 0;
  font-size: 14px;
  color: #333;
}
